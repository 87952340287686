import {defineStore} from "pinia";
import {
    ATTRIBUTE_FILTER, attributesRaces, CHECKBOX_FILTER,
    DROPDOWN_FILTER, FilterHide,
    FiltersType, GROUP_FILTER, HIDE_FILTER,
    IS_SHOW, itemsRaces, PERK_FILTER,
    RACE_FILTER,
    RARITY_FILTER, SEARCH_FILTER, SEARCH_PARTS_FILTER, SELECTED_PART_FILTER,
    ITEM_SLOT_FILTER, VIEW_FILTER, ATTRIBUTE_SLOT_FILTER, SEARCH_NFT_FILTER
} from "@/entities/Filters";
import {useLocalStorage} from "@vueuse/core";
import {computed, ref, toRaw} from "vue";
import {concatUrl} from "@/helper";

export const useFilterStore = (type: string) => defineStore('filter-' + type, () => {
    if (typeof type === "undefined") {
        return
    }

    const hasSelectedFilters = ref(false)
    const getPreparedUrl = ref('')
    const getPage = ref(1)
    const getPerPage = ref(20)
    const getShow = useLocalStorage(type + '_' + IS_SHOW, false)
    const getDropdown = useLocalStorage(type + '_' + DROPDOWN_FILTER, {})
    const getRaceFilters = useLocalStorage(type + '_' + RACE_FILTER, [])
    const getAttributeFilters = useLocalStorage(type + '_' + ATTRIBUTE_FILTER, [])
    const getRarityFilters = useLocalStorage(type + '_' + RARITY_FILTER, [])
    const getItemSlotFilters = useLocalStorage(type + '_' + ITEM_SLOT_FILTER, [])
    const getAttributeSlotFilters = useLocalStorage(type + '_' + ATTRIBUTE_SLOT_FILTER, [])
    const getSearchFilters = useLocalStorage(type + '_' + SEARCH_FILTER, '')
    const getSearchNftFilters = useLocalStorage(type + '_' + SEARCH_NFT_FILTER, '')
    const getGroupFilters = useLocalStorage(type + '_' + GROUP_FILTER, 'HUMAN')
    const selectedParts = useLocalStorage(type + '_' + SELECTED_PART_FILTER, [])
    const getPerkFilters = useLocalStorage(type + '_' + PERK_FILTER, [])
    const getView = useLocalStorage(type + '_' + VIEW_FILTER, 'card')
    const getHideFilters = useLocalStorage(type + '_' + HIDE_FILTER, [])
    const getCheckboxFilters = useLocalStorage(type + '_' + CHECKBOX_FILTER, {
        type: [],
        gender: [],
        skin: [],
    })

    const getSelectedParts = computed(() => selectedParts)

    const getSearchPartsFilter = useLocalStorage(type + '_' + SEARCH_PARTS_FILTER, {
        attributes: attributesRaces,
        items: itemsRaces,
    })

    function setSelectedPart(part) {
        selectedParts.value.push(part)

        getSearchPartsFilter.value[part.type + 's'][part.filter_group][part.slot][part.rarity][part.gender_specific] = [
            ...getSearchPartsFilter.value[part.type + 's'][part.filter_group][part.slot][part.rarity][part.gender_specific].filter(element => element.id !== part.id)
        ]

        setSelectedFilter()
        setPreparedUrl()
    }

    function removeFilterParts(part) {
        selectedParts.value = selectedParts.value.filter(element => element.id !== part.id)
        getSearchPartsFilter.value[part.type + 's'][part.filter_group][part.slot][part.rarity][part.gender_specific].push(part)

        setSelectedFilter()
        setPreparedUrl()
    }

    function setSearchPartsFilter(value: any): void {
        if (!Array.isArray(value.items)) {
            getSearchPartsFilter.value.items = {...itemsRaces, ...value.items}
        } else {
            getSearchPartsFilter.value.items = itemsRaces
        }

        if (!Array.isArray(value.attributes)) {
            getSearchPartsFilter.value.attributes = {...attributesRaces, ...value.attributes}
        } else {
            getSearchPartsFilter.value.attributes = attributesRaces
        }
    }

    function setGroup(value: any): void {
        getGroupFilters.value = value
    }

    function setHide(value: any): void {
        getHideFilters.value = value
        setPreparedUrl()
    }

    function setView(value: any): void {
        getView.value = value
    }

    function setCheckbox(value: any): void {
        getCheckboxFilters.value = value
        setPage(1)
        setSelectedFilter()
        setPreparedUrl()
    }

    function setShow(value: boolean): void {
        getShow.value = value
        setPreparedUrl()
    }

    function setDropdown(value: any, id: string) {
        getDropdown.value[id] = value
        setPage(1)
        setPreparedUrl()
    }

    function setPage(value: any) {
        getPage.value = value
        setPreparedUrl()
    }

    function setPerPage(value: any) {
        getPerPage.value = value
        setPage(1)
        setPreparedUrl()
    }

    function setSearchFilter(value: any) {
        getSearchFilters.value = value
        setPreparedUrl()
    }

    function setSearchNftFilters(value: any) {
        getSearchNftFilters.value = value
        setPage(1)
        setPreparedUrl()
    }

    function setRaceFilter(value: FiltersType) {
        let index = getRaceFilters.value.find((item: FiltersType) => item.filter === value.filter)
        if (!index) {
            getRaceFilters.value.push(value)
        } else {
            getRaceFilters.value = getRaceFilters.value.filter((item: FiltersType) => item.filter !== value.filter)
        }

        setPage(1)
        setSelectedFilter()
        setPreparedUrl()
    }

    function setPerkFilter(value: FiltersType) {
        let index = getPerkFilters.value.find((item: FiltersType) => item.filter === value.filter)
        if (!index) {
            getPerkFilters.value.push(value)
        } else {
            getPerkFilters.value = getPerkFilters.value.filter((item: FiltersType) => item.filter !== value.filter)
        }

        setPage(1)
        setSelectedFilter()
        setPreparedUrl()
    }

    function setRarityFilter(value: FiltersType) {
        let index = getRarityFilters.value.find((item: FiltersType) => item.filter === value.filter)
        if (!index) {
            getRarityFilters.value.push(value)
        } else {
            getRarityFilters.value = getRarityFilters.value.filter((item: FiltersType) => item.filter !== value.filter)
        }

        setPage(1)
        setSelectedFilter()
        setPreparedUrl()
    }
    function setItemSlotFilter(value: FiltersType) {
        let index = getItemSlotFilters.value.find((item: FiltersType) => item.filter === value.filter)
        if (!index) {
            getItemSlotFilters.value.push(value)
        } else {
            getItemSlotFilters.value = getItemSlotFilters.value.filter((item: FiltersType) => item.filter !== value.filter)
        }

        setPage(1)
        setSelectedFilter()
        setPreparedUrl()
    }

    function setAttributeSlotFilter(value: FiltersType) {
        let index = getAttributeSlotFilters.value.find((item: FiltersType) => item.filter === value.filter)
        if (!index) {
            getAttributeSlotFilters.value.push(value)
        } else {
            getAttributeSlotFilters.value = getAttributeSlotFilters.value.filter((item: FiltersType) => item.filter !== value.filter)
        }

        setPage(1)
        setSelectedFilter()
        setPreparedUrl()
    }
    function setAttributeFilter(value: FiltersType) {
        let index = getAttributeFilters.value.find((item: FiltersType) => item.filter === value.filter)
        if (!index) {
            getAttributeFilters.value.push(value)
        } else {
            getAttributeFilters.value = getAttributeFilters.value.filter((item: FiltersType) => item.filter !== value.filter)
        }

        setPage(1)
        setSelectedFilter()
        setPreparedUrl()
    }

    function setSelectedFilter() {
        let isSelectCheckBox = false
        Object.keys(getCheckboxFilters.value).forEach(checkbox => {
            if (!isSelectCheckBox && getCheckboxFilters.value[checkbox].length > 0) {
                isSelectCheckBox = true
            }
        })

        hasSelectedFilters.value = getRaceFilters.value.length > 0
            || getAttributeFilters.value.length > 0
            || getAttributeSlotFilters.value.length > 0
            || getRarityFilters.value.length > 0
            || getItemSlotFilters.value.length > 0
            || getPerkFilters.value.length > 0
            || isSelectCheckBox
            || selectedParts.value.length > 0
    }

    function clear() {
        getRaceFilters.value = []
        getAttributeFilters.value = []
        getRarityFilters.value = []
        getItemSlotFilters.value = []
        getPerkFilters.value = []
        getSearchFilters.value = ''
        getSearchNftFilters.value = ''
        selectedParts.value = []
        getAttributeSlotFilters.value = []
        getCheckboxFilters.value = {
            type: [],
            gender: [],
            skin: [],
        }

        setPage(1)
        setPreparedUrl()
        setSelectedFilter()
    }

    function setPreparedUrl() {
        let sendParts = {
            attribute: [],
            item: [],
        }

        let filters = {
            race: [],
            rarity: [],
            itemSlot: [],
            perk: [],
            attributeSlot: [],
        }

        getItemSlotFilters.value.map((item: FiltersType) => {
            filters.itemSlot.push(item.filter)
        })

        getRarityFilters.value.map((item: FiltersType) => {
            filters.rarity.push(item.filter)
        })

        getRaceFilters.value.map((item: FiltersType) => {
            filters.race.push(item.filter)
        })

        getAttributeSlotFilters.value.map((item: FiltersType) => {
            filters.attributeSlot.push(item.filter)
        })

        getPerkFilters.value.map((item: FiltersType) => {
            filters.perk.push(item.filter)
        })

        selectedParts.value.map((item: any) => {
            sendParts[item.type].push(item.id)
        })

        let url = ''

        url = concatUrl(url, 'gender', getCheckboxFilters.value.gender)
        url = concatUrl(url, 'skin', getCheckboxFilters.value.skin)
        url = concatUrl(url, 'type', getCheckboxFilters.value.type)

        url = concatUrl(url, 'itemSlot', filters.itemSlot)
        url = concatUrl(url, 'attributeSlot', filters.attributeSlot)
        url = concatUrl(url, 'rarity', filters.rarity)
        url = concatUrl(url, 'race', filters.race)
        url = concatUrl(url, 'perk', filters.perk)
        url = concatUrl(url, 'page', getPage.value)
        url = concatUrl(url, 'limit', getPerPage.value)

        if (getSearchNftFilters.value.length > 0) {
            url = concatUrl(url, 'search', getSearchNftFilters.value)
        }

        url = concatUrl(url, 'item', sendParts.item)
        url = concatUrl(url, 'attribute', sendParts.attribute)

        if (getHideFilters.value.length > 0) {
            getHideFilters.value.forEach((hideFilters: FilterHide) => {
                url = concatUrl(url, hideFilters.field, hideFilters.value)
            })
        }

        if (getDropdown.value.sort && Object.values(getDropdown.value.sort).length > 0) {
            url = concatUrl(url, 'sortType', getDropdown.value.sort)
        }

        if (getDropdown.value.order && Object.values(getDropdown.value.order).length > 0) {
            url = concatUrl(url, 'order', getDropdown.value.order)
        }

        if (url === '?') {
            url = ''
        }

        getPreparedUrl.value = url
    }

    return {
        getPage,
        getPerPage,
        getShow,
        getDropdown,
        getRaceFilters,
        getAttributeFilters,
        getRarityFilters,
        getItemSlotFilters,
        hasSelectedFilters,
        getPreparedUrl,
        getCheckboxFilters,
        getPerkFilters,
        getSearchFilters,
        getSearchNftFilters,
        getGroupFilters,
        getSearchPartsFilter,
        getSelectedParts,
        selectedParts,
        getView,
        getHideFilters,
        getAttributeSlotFilters,
        setSearchNftFilters,
        setAttributeSlotFilter,
        setHide,
        setView,
        removeFilterParts,
        setSelectedPart,
        setSearchPartsFilter,
        setGroup,
        setSearchFilter,
        setPerkFilter,
        setCheckbox,
        setShow,
        setPage,
        setPerPage,
        setDropdown,
        setSelectedFilter,
        setRaceFilter,
        setRarityFilter,
        setItemSlotFilter,
        setAttributeFilter,
        setPreparedUrl,
        clear,
    }
})()
