import {createToast} from "mosha-vue-toastify";
import {groups} from "@/entities/Filters";

export const concatUrl = (url: string, key: string, value: any) => {
    if (url.indexOf('?') === -1) {
        url += '?'
    }

    if (typeof value === 'object' && value !== null) {
        Object.keys(value).map((item) => {
            url += `${key}[${item}]=${value[item]}&`
        })
    } else if (Array.isArray(value)) {
        value.map((item, index) => {
            url += `${key}[${index}]=${item}&`
        })
    } else if (value == '' || value == null) {
        url += `${key}&`
    } else {
        url += `${key}=${value}&`
    }

    return url;
}

export const isEmptyGroups = (data: {items: [], attributes: []}) => {
    const isEmpty = {
        HUMAN: false,
        NIGHT_ELVES: false,
        MYTHIC: false,
        ORCS: false,
    }

    for (let i = 0; i < groups.length; i++) {
        const group: string = groups[i].group
        let empty = false;

        if (typeof data.attributes !== "undefined" && typeof data.attributes[group] !== "undefined") {
            const attributes:any[] = Object.values(data.attributes[group])
            for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].length !== 0) {
                    empty = false
                    break;
                } else {
                    empty = true
                }
            }
        } else {
            empty = true
        }

        if (empty) {
            if (typeof data.items !== "undefined" && typeof data.items[group] !== "undefined") {
                const items:any[] = Object.values(data.items[group])
                for (let i = 0; i < items.length; i++) {
                    if (items[i].length !== 0) {
                        empty = false
                        break;
                    } else {
                        empty = true
                    }
                }
            } else {
                empty = true
            }
        }

        isEmpty[group] = empty
    }

    return isEmpty
}

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const limitStr = (string: string, limit: number) => {
    if (string.length > limit) {
        const split = string.length - limit;
        const end = string.slice(split, string.length)
        string = string.slice(0, limit) + '...' + end;
    }

    return string;
}

export const errorToast = (title, description) => {
    createToast({
        title,
        description
    },{
        type: 'danger', // 'info', 'danger', 'warning', 'success', 'default'
        timeout: 8000,
        showCloseButton: true,
        position: 'top-right', // 'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        transition: 'bounce',
        hideProgressBar: false,
        swipeClose: true,
        toastBackgroundColor: '#cb0000',
        onClose: undefined
    })
}

export const successToast = (title, description) => {
    createToast({
        title,
        description
    },{
        type: 'success', // 'info', 'danger', 'warning', 'success', 'default'
        timeout: 8000,
        showCloseButton: true,
        position: 'top-right', // 'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        transition: 'bounce',
        hideProgressBar: false,
        swipeClose: true,
        toastBackgroundColor: '#1b632a',
        onClose: undefined
    })
}

export const newDate = new Date(Date.UTC(2022, 2, 6, 20, 0, 0))


export const getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}


export const formatWalletAddress = (string, limit) => {
    let str = string

    if (typeof str === 'string' && str.length > limit) {
        let split = str.length - limit
        let end = str.slice(split, str.length)
        str = str.slice(0, limit) + '...' + end
    }

    return str
}

