import LocalStorageService from "../../service/LocalStorageService";
import {CHANGE_WALLET, WALLET, WALLET_TYPE} from "../../constants/walletConstants";
import {errorToast, successToast} from "../../helper";
import * as S from "@emurgo/cardano-serialization-lib-browser"
import router from "../../router";
import ApiService from "../../service/api/ApiService";
import {
    getTxUnspentOutputs, getUtxos,
    getUtxosAssets,
    initParams,
    initTransactionBuilder
} from "../../service/Serialization";

const getDefaultState = () => ({
    walletType: LocalStorageService.get(WALLET_TYPE, null),
    isWalletEnabled: false,
    wallet: LocalStorageService.get(WALLET, ''),
    changeWallet: LocalStorageService.get(CHANGE_WALLET, ''),
    balance: '0',
    isLoad: true,
    networkId: 1,
    rawUtxo: [],
    tokensNameHex: [],
    dgems: 0,
})

const getters = {
    getWalletType: state => state.walletType,
    getBalance: state => state.balance,
    getWallet: state => state.wallet,
    getChangeWallet: state => state.changeWallet,
    isEnabled: state => state.isWalletEnabled,
    isLoad: state => state.isLoad,
    getNetwork: state => state.networkId,
    getRawUtxo: state => state.rawUtxo,
    getDgems: state => state.dgems,
    getTokensNameHex: state => state.tokensNameHex,
}

const mutations = {
    updateWalletType(state, type) {
        state.walletType = type
    },
    updateEnableWallet(state, isEnable) {
        state.isWalletEnabled = isEnable
    },
    updateWallet(state, wallet) {
        state.wallet = wallet
    },
    updateChangeWallet(state, wallet) {
        state.changeWallet = wallet
    },
    updateBalance(state, balance) {
        state.balance = balance
    },
    updateLoad(state, isLoad) {
        state.isLoad = isLoad
    },
    updateNetworkId(state, networkId) {
        state.networkId = networkId
    },
    updateRawUtxo(state, utxo) {
        state.rawUtxo = utxo
    },
    updateDgems(state, dgems) {
        state.dgems = dgems
    },
    updateTokensNameHex(state, names) {
        state.tokensNameHex = names
    },
}

const actions = {
    async switchWallet({commit, dispatch}, {type, emitter}) {
        try {
            commit('updateLoad', true)
            let isEnable = await window.cardano[type].isEnabled()
            commit('updateEnableWallet', isEnable)

            let wallet = await window.cardano[type].enable()

            if (wallet !== null) {
                commit('updateEnableWallet', true)
                commit('updateWalletType', type)

                LocalStorageService.set(WALLET_TYPE, type)

                let address = await wallet.getUsedAddresses()
                if (address.length === 0) {
                    address = await wallet.getUnusedAddresses()
                }

                let value = S.Value.from_bytes(Buffer.from(await wallet.getBalance(), 'hex'))
                let balance = Math.round(value.coin().to_str() / 1000000)
                let hexWallet = S.Address.from_bytes(Buffer.from(address[0], 'hex')).to_bech32()
                let changeWallet = await wallet.getChangeAddress()

                LocalStorageService.set(WALLET, hexWallet)
                LocalStorageService.set(CHANGE_WALLET, changeWallet)

                commit('updateBalance', balance)
                commit('updateWallet', hexWallet)
                commit('updateChangeWallet', changeWallet)

                let route = router.currentRoute.value.name
                if (route === 'connect') {
                    await router.push({name: 'profile'});
                }

                let networkId = await wallet.getNetworkId()
                let rawUtxo = await wallet.getUtxos()

                commit('updateRawUtxo', rawUtxo)
                commit('updateNetworkId', networkId)
                dispatch('collectDgems')

                console.log(route)

                if (route === 'chibis') {
                    dispatch('syncInventory', emitter)
                }
            }

            commit('updateLoad', false)
        } catch (e) {
            let message = e.message === '' ? 'User not confirm action' : e.message
            errorToast(`Wallet error: ${e.code}`, `Message: ${message}`)
            console.log(e)
            commit('updateLoad', false)
        }
    },
    async checkWalletEnabled({commit, state, dispatch}, emitter) {
        // commit('updateLoad', true)
        if (typeof window.cardano === "undefined" || typeof window.cardano[state.walletType] === "undefined") {
            let route = router.currentRoute.value.name
            if (route === 'chibis') {
                await router.push({name: 'connect'});
            }

            commit('updateEnableWallet', false)
            commit('updateLoad', false)
            commit('updateWallet', '')
            commit('updateChangeWallet', '')
            commit('updateWalletType', '')
            commit('updateBalance', '0')
            commit('updateRawUtxo', [])
            commit('updateDgems', 0)

            LocalStorageService.remove(WALLET)
            LocalStorageService.remove(CHANGE_WALLET)
            LocalStorageService.remove(CHANGE_WALLET)
            return
        }

        let isEnable = await window.cardano[state.walletType].isEnabled()

        commit('updateEnableWallet', isEnable)
        let route = router.currentRoute.value.name
        if (isEnable) {
            let wallet = await window.cardano[state.walletType].enable()
            if (wallet !== null) {

                let address = await wallet.getUsedAddresses()
                if (address.length === 0) {
                    address = await wallet.getUnusedAddresses()
                }

                let value = S.Value.from_bytes(Buffer.from(await wallet.getBalance(), 'hex'))
                let balance = Math.round(value.coin().to_str() / 1000000)
                let hexWallet = S.Address.from_bytes(Buffer.from(address[0], 'hex')).to_bech32()
                let changeWallet = await wallet.getChangeAddress()

                LocalStorageService.set(WALLET, hexWallet)
                LocalStorageService.set(CHANGE_WALLET, changeWallet)

                commit('updateBalance', balance)
                commit('updateWallet', hexWallet)
                commit('updateChangeWallet', changeWallet)

                let networkId = await wallet.getNetworkId()
                let rawUtxo = await wallet.getUtxos()

                commit('updateRawUtxo', rawUtxo)
                commit('updateNetworkId', networkId)
                dispatch('collectDgems')
            }
        } else {
            commit('updateWallet', '')
            commit('updateChangeWallet', '')
            commit('updateWalletType', '')
            commit('updateBalance', '0')
            commit('updateRawUtxo', [])
            commit('updateDgems', 0)

            LocalStorageService.remove(WALLET)
            LocalStorageService.remove(CHANGE_WALLET)
            LocalStorageService.remove(CHANGE_WALLET)


            if (route === 'chibis') {
                await router.push({name: 'connect'});
            }
        }

        commit('updateLoad', false)
    },
    async collectDgems({commit, state}) {
        let rawUtxo = state.rawUtxo
        let utxos = await getUtxos(rawUtxo)

        let dgems = 0;

        utxos.forEach(utxo => {
            utxo.assets.forEach(asset => {
                if (asset.policyId === '539aeefaf917fe42b65018bf76fa7933c1737f1af92d8ca192ed38c5') {
                    dgems = dgems + parseInt(asset.amount)
                }
            })
        })

        commit("updateDgems", dgems)
    },
    async updateWalletChange({state, dispatch}, emitter) {
        if (typeof window.cardano !== "undefined" && typeof window.cardano[state.walletType] !== "undefined") {

            let isEnable = await window.cardano[state.walletType].isEnabled()

            if (state.walletType === 'nami' && isEnable) {
                let wallet = await window.cardano[state.walletType].enable()
                wallet.experimental.on('accountChange', () => {
                    dispatch('setWalletBalance', emitter)
                })
            }

            //TODO: eternl not support change wallets need check
            // if (state.walletType === 'eternl') {
            //     console.log('werwer', await wallet.experimental.syncAccount())
            // }
        }
    },
    async syncInventory({commit, state, dispatch}, emitter) {
        emitter.emit('isLoadInventory', true)
        if (typeof window.cardano === "undefined" || typeof window.cardano[state.walletType] === "undefined") {
            emitter.emit('isLoadInventory', false)
            commit('updateEnableWallet', false)
            return
        }

        let wallet = await window.cardano[state.walletType].enable()

        const typeWallet = state.walletType
        let address_bench32
        if (typeWallet === 'typhon') {
            let d = await wallet.getAddress()
            address_bench32 = d.data
        } else {
            let address = await wallet.getUsedAddresses()
            if (address.length === 0) {
                address = await wallet.getUnusedAddresses()
            }

            address_bench32 = S.Address.from_bytes(Buffer.from(address[0], 'hex')).to_bech32()
        }

        if (address_bench32 !== state.wallet) {
            errorToast("User action:", 'Detect change wallet please re-login')
            return
        }


        let rawUtxo = await wallet.getUtxos()

        ApiService.post('inventory/sync', {utxo: await getUtxosAssets(rawUtxo)}).then(response => {
            let route = router.currentRoute.value.name

            if (route === 'chibis') {
                dispatch('inventory/fetchInventory', emitter, {root: true})
            }
        })
    },
    async setBaseWalletData({commit, state}) {
        if (typeof window.cardano === "undefined" || typeof window.cardano[state.walletType] === "undefined") {
            commit('updateEnableWallet', false)
            return
        }

        let wallet = await window.cardano[state.walletType].enable()
        const typeWallet = state.walletType
        let address_bench32
        if (typeWallet === 'typhon') {
            let d = await wallet.getAddress()
            address_bench32 = d.data
        } else {
            let address = await wallet.getUsedAddresses()
            if (address.length === 0) {
                address = await wallet.getUnusedAddresses()
            }

            address_bench32 = S.Address.from_bytes(Buffer.from(address[0], 'hex')).to_bech32()
        }

        if (address_bench32 !== state.wallet) {
            errorToast("User action:", 'Detect change wallet please re-login')
            return
        }
        let networkId = await wallet.getNetworkId()
        let rawUtxo = await wallet.getUtxos()

        commit('updateRawUtxo', rawUtxo)
        commit('updateNetworkId', networkId)
    },
    async sendAdaTransaction({commit, state}, {nft = null, operation, price = null}) {
        if (typeof window.cardano === "undefined" || typeof window.cardano[state.walletType] === "undefined") {
            commit('updateEnableWallet', false)
            return
        }

        let nftParam = ''
        if (nft !== null) {
            nftParam = `/${nft.id}`
        }
        let response = {};
        if (operation !== 'claim') {
            response = await ApiService.get(`transaction/check/${operation}${nftParam}`)
        }

        if (response.status === 'error') {
            errorToast('Transaction Error: ', 'Can`t send transaction, this chibi start canceled or some one start buy')
            return
        }

        if (operation !== 'sell' && nft !== null && (nft.transaction === null || nft.transaction.price <= 0)) {
            errorToast('Transaction Error: ', 'Can`t send transaction, this chibi price not valid get price: ' + transaction)
            return
        }

        let walletGetter = operation === 'claim' ? 'addr1vx677x46w5um3cjqvyaduf0jghkcm88e7zxpu8xj3fvz87cgr5znf' : 'addr1vya2f4xjww6aww8y4ey4ln9umae6nw9zpqkzgfa0yssy7uq7fujcn';
        let wallet = await window.cardano[state.walletType].enable()

        const typeWallet = state.walletType
        let address_bench32
        if (typeWallet === 'typhon') {
            let d = await wallet.getAddress()
            address_bench32 = d.data
        } else {
            let address = await wallet.getUsedAddresses()
            if (address.length === 0) {
                address = await wallet.getUnusedAddresses()
            }

            address_bench32 = S.Address.from_bytes(Buffer.from(address[0], 'hex')).to_bech32()
        }

        if (address_bench32 !== state.wallet) {
            errorToast("User action:", 'Detect change wallet please re-login')
            return
        }

        let utxos = await wallet.getUtxos()
        let params = await initParams()
        let txBuilder = await initTransactionBuilder(params)
        let userWallet = S.Address.from_bech32(state.wallet);
        let getterAddress = S.Address.from_bech32(walletGetter);
        let sAda = ((price !== null ? price : nft.transaction.price) * 1000000).toString()

        let out = S.TransactionOutputBuilder.new()
            .with_address(getterAddress)
            .next()
            .with_value(S.Value.new(S.BigNum.from_str(sAda)))
            .build()

        txBuilder.add_output(out);

        if (operation === 'buy' && typeof window.cardano[state.walletType].isBridge !== "undefined" && window.cardano[state.walletType].isBridge && nft.transaction.price >= 100) {
            if (typeof window.cardano[state.walletType].experimental.feeAddress !== "undefined") {
                let feeAddr = S.Address.from_bech32(window.cardano[state.walletType].experimental.feeAddress)
                let fee = Math.floor((nft.transaction.price * 0.001) * 10000) / 10000

                if (fee < 1) {
                    fee = 1
                }

                fee = S.BigNum.from_str((fee * 1000000).toString())
                let out = S.TransactionOutputBuilder.new()
                    .with_address(feeAddr)
                    .next()
                    .with_value(S.Value.new(fee))
                    .build()

                txBuilder.add_output(out);
            }
        }

        let txUnspentOutputs = await getTxUnspentOutputs(utxos);

        // 0 for LargestFirst, 1 RandomImprove 2,3 Mutli asset
        txBuilder.add_inputs_from(txUnspentOutputs, 0)
        txBuilder.add_change_if_needed(userWallet)

        let txBody = txBuilder.build();
        let transactionWitnessSet = S.TransactionWitnessSet.new();
        let rawTrx = S.Transaction.new(
            txBody,
            S.TransactionWitnessSet.from_bytes(transactionWitnessSet.to_bytes())
        )

        let txVKeyWitnesses = await wallet.signTx(Buffer.from(rawTrx.to_bytes(), "utf8").toString("hex"), true);

        txVKeyWitnesses = S.TransactionWitnessSet.from_bytes(Buffer.from(txVKeyWitnesses, "hex"));
        transactionWitnessSet.set_vkeys(txVKeyWitnesses.vkeys());

        let signedTx = S.Transaction.new(
            rawTrx.body(),
            transactionWitnessSet
        );

        let trxHash = await wallet.submitTx(Buffer.from(signedTx.to_bytes(), "utf8").toString("hex"));

        let body = {
            asset: operation === 'claim' ? await getUtxosAssets(state.rawUtxo) : nft,
            trxHash,
            price: price !== null ? price : nft.transaction.price
        }

        if (nft !== null && nft.transaction !== null && typeof nft.transaction.hash !== "undefined") {
            body.hash = nft.transaction.hash
        }

        ApiService.post('transaction/' + operation, body).then(response => {
            if (typeof response.errorTransaction !== "undefined") {
                errorToast('User action: ', 'get error from server ' + response.errorTransaction)
            }

            if (typeof response.error !== "undefined") {
                errorToast('Server error: ', 'get error from server ' + response.error)
            }
        }).catch(e => {
            errorToast('Server error: ', 'get error from server ' + e.message)
        })
    },
    async sendSummonTransaction({commit, state}, {dgemsCount, adaCount, hashSummon, usd}) {
        if (typeof window.cardano === "undefined" || typeof window.cardano[state.walletType] === "undefined") {
            commit('updateEnableWallet', false)
            return
        }
        try {
            let userWallet;
            if (S.ByronAddress.is_valid(state.wallet)) {
                userWallet = S.ByronAddress.from_base58(state.wallet).to_address();
            } else {
                userWallet = S.Address.from_bech32(state.wallet);
            }

            const totalAda = parseFloat(adaCount) * 1000000
            const summonAddress = S.Address.from_bech32('addr1vxpn5cgckm24ezkpzppty9cjrlcksw7n9d0fxlwfl89fyjga8lr0v');
            const api = await window.cardano[state.walletType].enable()
            const rawUtxos = await api.getUtxos()

            const obj = {
                msg: [
                    "Chibidango Summon id:",
                    hashSummon,
                    `Exchange ADA/USDT rate ${usd}, total ADA send ${adaCount}`
                ]
            };

            const metadata = S.encode_json_str_to_metadatum(JSON.stringify(obj), S.MetadataJsonSchema.NoConversions);
            const metadataString = S.decode_metadatum_to_json_str(metadata, S.MetadataJsonSchema.NoConversions);

            let collateral = []
            if (state.walletType !== 'nami') {
                collateral = await api.getCollateral()
            }

            let address_bench32
            let address = await api.getUsedAddresses()
            if (address.length === 0) {
                address = await api.getUnusedAddresses()
            }

            address_bench32 = S.Address.from_bytes(Buffer.from(address[0], 'hex')).to_bech32()
            if (address_bench32 !== state.wallet) {
                errorToast("User action:", 'Detect change wallet please re-login')
                return
            }

            const params = await initParams()
            const txBuilder = await initTransactionBuilder(params);

            let adaUtxos = []
            let multiTokenUtxos = []
            for (const rawUtxo of rawUtxos) {
                if (collateral.indexOf(rawUtxo) !== -1) {
                    continue
                }
                const utxo = S.TransactionUnspentOutput.from_bytes(Buffer.from(rawUtxo, "hex"));
                const output = utxo.output();
                const multiasset = output.amount().multiasset();
                if (!multiasset) {
                    adaUtxos.push(utxo)
                } else {
                    multiTokenUtxos.push(utxo)
                }
            }

            let totalSendAda = 0
            let totalSetDgems = 0

            loop:
            for (const utxo of multiTokenUtxos) {
                const output = utxo.output();
                const input = utxo.input()
                const multiasset = output.amount().multiasset();
                const coins = parseInt(output.amount().coin().to_str())
                if (!multiasset) {
                    continue
                }

                const keys = multiasset.keys()
                let isSetInput = false
                for (let i = 0; i < keys.len(); i++) {
                    const policyId = keys.get(i);
                    const policyIdHex = Buffer.from(policyId.to_bytes(), "utf8").toString("hex");
                    const assets = multiasset.get(policyId)
                    const assetNames = assets.keys();

                    for (let j = 0; j < assetNames.len(); j++) {
                        const assetName = assetNames.get(j);
                        const assetNameHex = Buffer.from(assetName.name(), "utf8").toString("hex")
                        const multiassetAmt = multiasset.get_asset(policyId, assetName)
                        const assetCount = parseInt(multiassetAmt.to_str())

                        if (totalSetDgems >= dgemsCount) break loop
                        if (isSetInput) continue

                        if ('539aeefaf917fe42b65018bf76fa7933c1737f1af92d8ca192ed38c54447454d53' === policyIdHex + assetNameHex) {
                            let add = JSON.parse(output.to_json())

                            txBuilder.add_input(
                                S.Address.from_bech32(add.address),
                                input,
                                S.Value.new_with_assets(output.amount().coin(), multiasset)
                            )

                            totalSendAda = totalSendAda + coins
                            totalSetDgems = totalSetDgems + assetCount
                            isSetInput = true
                        }
                    }
                }
            }

            for (const utxo of adaUtxos) {
                const input = utxo.input()
                const output = utxo.output()
                const coins = parseInt(output.amount().coin().to_str())
                let add = JSON.parse(output.to_json())

                txBuilder.add_input(
                    S.Address.from_bech32(add.address),
                    input,
                    S.Value.new(output.amount().coin())
                )

                totalSendAda = totalSendAda + coins

                if (totalSendAda > (totalAda + 10000000)) break;
            }

            if (totalSendAda < totalAda || totalSetDgems < dgemsCount) {
                errorToast("USER ACTION", 'Not enough ada or dgems')
                return
            }

            const multiAsset = S.MultiAsset.new();

            multiAsset.set_asset(
                S.ScriptHash.from_bytes(Buffer.from('539aeefaf917fe42b65018bf76fa7933c1737f1af92d8ca192ed38c5', "hex")),
                S.AssetName.new(Buffer.from("4447454d53", "hex")),
                S.BigNum.from_str(dgemsCount.toString()),
            );

            const txOutputBuild = S.TransactionOutputBuilder.new().with_address(summonAddress).next()
            const output = txOutputBuild.with_coin_and_asset(S.BigNum.from_str(totalAda.toString()), multiAsset).build()

            txBuilder.add_output(output)
            txBuilder.add_json_metadatum(S.BigNum.from_str("674"), metadataString)
            txBuilder.add_change_if_needed(userWallet)

            const txBody = txBuilder.build();
            const transactionWitnessSet = S.TransactionWitnessSet.new();
            const tx = S.Transaction.new(txBody, S.TransactionWitnessSet.from_bytes(transactionWitnessSet.to_bytes()), txBuilder.get_auxiliary_data())

            const txSign = await api.signTx(Buffer.from(tx.to_bytes(), "utf8").toString("hex"), true);
            const txVkeyWitnesses = S.TransactionWitnessSet.from_bytes(Buffer.from(txSign, "hex"));

            transactionWitnessSet.set_vkeys(txVkeyWitnesses.vkeys());

            const signedTx = S.Transaction.new(tx.body(), transactionWitnessSet, txBuilder.get_auxiliary_data());

            let submittedTxHash

            submittedTxHash = await api.submitTx(Buffer.from(signedTx.to_bytes(), "utf8").toString("hex"));

            ApiService.post('summoning/confirm', {hash: submittedTxHash, summon_hash: hashSummon}).then(response => {
                if (!response.error) {
                    router.push({name: 'playWrap.SummonCheck', params: {hash: hashSummon}});
                }
            })
        } catch (e) {
            console.log(e)
            if (e.code && e.code === 400 || e.message.search('submit tx failed: Error: 400') > -1) {
                errorToast("User Action", 'You have a pending transaction. Please wait for that transaction to be processed by the blockchain')
                return
            }

            errorToast("Wallet error", 'Error not defined ' + e.message)
        }
    },
    async shopBuy({commit, state}, {transactionId, dgemsCount, itemName}) {
        if (typeof window.cardano === "undefined" || typeof window.cardano[state.walletType] === "undefined") {
            commit('updateEnableWallet', false)
            return
        }

        try {
            let userWallet;
            if (S.ByronAddress.is_valid(state.wallet)) {
                userWallet = S.ByronAddress.from_base58(state.wallet).to_address();
            } else {
                userWallet = S.Address.from_bech32(state.wallet);
            }

            const totalAda = 3000000
            const shopAddress = S.Address.from_bech32('addr1v8305432q234kf2g5840uv0l8hqd2krkhrcpkzzjlt8x4lcknnkw5');
            const api = await window.cardano[state.walletType].enable()
            const rawUtxos = await api.getUtxos()

            const obj = {
                msg: [
                    "Chibidango shop id:",
                    transactionId,
                    "Item name: " + itemName
                ]
            };

            const metadata = S.encode_json_str_to_metadatum(JSON.stringify(obj), S.MetadataJsonSchema.NoConversions);
            const metadataString = S.decode_metadatum_to_json_str(metadata, S.MetadataJsonSchema.NoConversions);

            let collateral = []
            if (state.walletType !== 'nami') {
                collateral = await api.getCollateral()
            }

            let address_bench32
            let address = await api.getUsedAddresses()
            if (address.length === 0) {
                address = await api.getUnusedAddresses()
            }

            address_bench32 = S.Address.from_bytes(Buffer.from(address[0], 'hex')).to_bech32()
            if (address_bench32 !== state.wallet) {
                errorToast("User action:", 'Detect change wallet please re-login')
                return
            }

            const params = await initParams()
            const txBuilder = await initTransactionBuilder(params);

            let adaUtxos = []
            let multiTokenUtxos = []
            for (const rawUtxo of rawUtxos) {
                if (collateral.indexOf(rawUtxo) !== -1) {
                    continue
                }
                const utxo = S.TransactionUnspentOutput.from_bytes(Buffer.from(rawUtxo, "hex"));
                const output = utxo.output();
                const multiasset = output.amount().multiasset();
                if (!multiasset) {
                    adaUtxos.push(utxo)
                } else {
                    multiTokenUtxos.push(utxo)
                }
            }

            let totalSendAda = 0
            let totalSetDgems = 0

            loop:
                for (const utxo of multiTokenUtxos) {
                    const output = utxo.output();
                    const input = utxo.input()
                    const multiasset = output.amount().multiasset();
                    const coins = parseInt(output.amount().coin().to_str())
                    if (!multiasset) {
                        continue
                    }

                    const keys = multiasset.keys()
                    let isSetInput = false
                    for (let i = 0; i < keys.len(); i++) {
                        const policyId = keys.get(i);
                        const policyIdHex = Buffer.from(policyId.to_bytes(), "utf8").toString("hex");
                        const assets = multiasset.get(policyId)
                        const assetNames = assets.keys();

                        for (let j = 0; j < assetNames.len(); j++) {
                            const assetName = assetNames.get(j);
                            const assetNameHex = Buffer.from(assetName.name(), "utf8").toString("hex")
                            const multiassetAmt = multiasset.get_asset(policyId, assetName)
                            const assetCount = parseInt(multiassetAmt.to_str())

                            if (totalSetDgems >= dgemsCount) break loop
                            if (isSetInput) continue

                            if ('539aeefaf917fe42b65018bf76fa7933c1737f1af92d8ca192ed38c54447454d53' === policyIdHex + assetNameHex) {

                                let add = JSON.parse(output.to_json())

                                txBuilder.add_input(
                                    S.Address.from_bech32(add.address),
                                    input,
                                    S.Value.new_with_assets(output.amount().coin(), multiasset)
                                )

                                totalSendAda = totalSendAda + coins
                                totalSetDgems = totalSetDgems + assetCount
                                isSetInput = true
                            }
                        }
                    }
                }

            for (const utxo of adaUtxos) {
                const input = utxo.input()
                const output = utxo.output()
                const coins = parseInt(output.amount().coin().to_str())
                let add = JSON.parse(output.to_json())

                txBuilder.add_input(
                    S.Address.from_bech32(add.address),
                    input,
                    S.Value.new(output.amount().coin())
                )

                totalSendAda = totalSendAda + coins

                if (totalSendAda > (totalAda + 10000000)) break;
            }

            if (totalSendAda < totalAda || totalSetDgems < dgemsCount) {
                errorToast("USER ACTION", 'Not enough ada or dgems')
                return
            }

            const multiAsset = S.MultiAsset.new();

            multiAsset.set_asset(
                S.ScriptHash.from_bytes(Buffer.from('539aeefaf917fe42b65018bf76fa7933c1737f1af92d8ca192ed38c5', "hex")),
                S.AssetName.new(Buffer.from("4447454d53", "hex")),
                S.BigNum.from_str(dgemsCount.toString()),
            );

            const txOutputBuild = S.TransactionOutputBuilder.new().with_address(shopAddress).next()
            const output = txOutputBuild.with_coin_and_asset(S.BigNum.from_str(totalAda.toString()), multiAsset).build()

            txBuilder.add_output(output)
            txBuilder.add_json_metadatum(S.BigNum.from_str("674"), metadataString)
            txBuilder.add_change_if_needed(userWallet)

            const txBody = txBuilder.build();
            const transactionWitnessSet = S.TransactionWitnessSet.new();
            const tx = S.Transaction.new(txBody, S.TransactionWitnessSet.from_bytes(transactionWitnessSet.to_bytes()), txBuilder.get_auxiliary_data())

            const txSign = await api.signTx(Buffer.from(tx.to_bytes(), "utf8").toString("hex"), true);
            const txVkeyWitnesses = S.TransactionWitnessSet.from_bytes(Buffer.from(txSign, "hex"));

            transactionWitnessSet.set_vkeys(txVkeyWitnesses.vkeys());

            const signedTx = S.Transaction.new(tx.body(), transactionWitnessSet, txBuilder.get_auxiliary_data());

            let submittedTxHash

            submittedTxHash = await api.submitTx(Buffer.from(signedTx.to_bytes(), "utf8").toString("hex"));

            await ApiService.post('store/confirm', {hash: submittedTxHash, shop_hash: transactionId})

            successToast('Transaction', "Transaction successfully sent")
        } catch (e) {
            if (e.code && e.code === 400 || e.message.search('submit tx failed: Error: 400') > -1) {
                errorToast("User Action", 'You have a pending transaction. Please wait for that transaction to be processed by the blockchain')
                return
            }

            await ApiService.post('store/cancel', {shop_hash: transactionId})

            errorToast("Wallet error", 'Error not defined ' + e.message)
        }
    },
    async sendTokenTransaction({commit, state}, {nft, operation, price}) {
        if (typeof window.cardano === "undefined" || typeof window.cardano[state.walletType] === "undefined") {
            commit('updateEnableWallet', false)
            return
        }

        let response = await ApiService.get(`transaction/check/${operation}/${nft.id}`)

        if (response.status === 'error') {
            errorToast('Transaction Error: ', 'Can`t send transaction, this chibi start canceled or some one start buy')
            return
        }

        if (operation !== 'sell' && (nft.transaction === null || nft.transaction.price <= 0)) {
            errorToast('Transaction Error: ', 'Can`t send transaction, this chibi price not valid get price: ' + transaction)
            return
        }

        let wallet = await window.cardano[state.walletType].enable()


        const typeWallet = state.walletType
        let address_bench32
        if (typeWallet === 'typhon') {
            let d = await wallet.getAddress()
            address_bench32 = d.data
        } else {
            let address = await wallet.getUsedAddresses()
            if (address.length === 0) {
                address = await wallet.getUnusedAddresses()
            }

            address_bench32 = S.Address.from_bytes(Buffer.from(address[0], 'hex')).to_bech32()
        }

        if (address_bench32 !== state.wallet) {
            errorToast("User action:", 'Detect change wallet please re-login')
            return
        }

        let utxos = await wallet.getUtxos()
        let params = await initParams()
        let txBuilder = await initTransactionBuilder(params)
        let userWallet = S.Address.from_bech32(state.wallet);
        let marketAddress = S.Address.from_bech32("addr1vya2f4xjww6aww8y4ey4ln9umae6nw9zpqkzgfa0yssy7uq7fujcn");
        let txOutputBuilder = S.TransactionOutputBuilder.new();

        txOutputBuilder = txOutputBuilder.with_address(marketAddress);
        txOutputBuilder = txOutputBuilder.next();

        let multiAsset = S.MultiAsset.new();
        let assets = S.Assets.new()
        let hexAssetName = nft.unit.slice(56)

        assets.insert(
            S.AssetName.new(Buffer.from(hexAssetName, "hex")), // Asset Name
            S.BigNum.from_str(('1')) // How much to send
        );

        multiAsset.insert(
            S.ScriptHash.from_bytes(Buffer.from(nft.policy_id, "hex")), // PolicyID
            assets
        );

        txOutputBuilder = txOutputBuilder.with_coin_and_asset(
            S.BigNum.from_str('2100000'),
            multiAsset
        )

        let txOutput = txOutputBuilder.build();

        // txBuilder.add_output(
        //     S.TransactionOutput.new(
        //         marketAddress,
        //         S.Value.new(S.BigNum.from_str('2100000'))
        //     ),
        // );

        txBuilder.add_output(txOutput)

        let txUnspentOutputs = await getTxUnspentOutputs(utxos);

        // 0 for LargestFirst, 1 RandomImprove 2,3 Multi asset
        txBuilder.add_inputs_from(txUnspentOutputs, 3)
        txBuilder.add_change_if_needed(userWallet)

        let txBody = txBuilder.build();
        let transactionWitnessSet = S.TransactionWitnessSet.new();
        let tx = S.Transaction.new(
            txBody,
            S.TransactionWitnessSet.from_bytes(transactionWitnessSet.to_bytes())
        )

        const vtxBytes = tx.to_bytes()

        let txVkeyWitnesses = await wallet.signTx(Buffer.from(vtxBytes).toString("hex"), true);

        txVkeyWitnesses = S.TransactionWitnessSet.from_bytes(Buffer.from(txVkeyWitnesses, "hex"));
        transactionWitnessSet.set_vkeys(txVkeyWitnesses.vkeys());

        const signedTx = S.Transaction.new(
            tx.body(),
            transactionWitnessSet
        );

        const txBytes = signedTx.to_bytes()

        signedTx.free()

        let trxHash = await wallet.submitTx(Buffer.from(txBytes).toString("hex"));

        ApiService.post('transaction/' + operation, {
            asset: nft,
            trxHash,
            price
        }).then(response => {
            if (typeof response.error !== "undefined") {
                errorToast('Server error: ', 'get error from server ' + response.error)
            }
        }).catch(e => {
            errorToast('Server error: ', 'get error from server ' + e.message)
        })
    },
    async logout({commit}) {
        LocalStorageService.set(WALLET_TYPE, null)
        LocalStorageService.set(WALLET, '')
        LocalStorageService.set(CHANGE_WALLET, '')

        commit('updateLoad', true)
        commit('updateWalletType', null)
        commit('updateEnableWallet', false)
        commit('updateChangeWallet', '')
        commit('updateWallet', '')
        commit('updateBalance', '0')
        commit('updateRawUtxo', [])
        commit('updateDgems', 0)

        let route = router.currentRoute.value.name
        if (route === 'profile') {
            await router.push({name: 'connect'});
        }

        commit('updateLoad', false)
    },
    async getTokens({commit, state}) {
        if (typeof window.cardano === "undefined" || typeof window.cardano[state.walletType] === "undefined") {
            commit('updateEnableWallet', false)
            return
        }

        let api = await window.cardano[state.walletType].enable()

        const rawUtxos = await api.getUtxos();

        let totalChibies = [];
        let totalHexName = [];
        for (const rawUtxo of rawUtxos) {
            const utxo = S.TransactionUnspentOutput.from_bytes(Buffer.from(rawUtxo, "hex"));
            const output = utxo.output();
            const multiasset = output.amount().multiasset();

            if (multiasset) {
                const keys = multiasset.keys() // policy Ids of thee multiasset
                const N = keys.len();
                for (let i = 0; i < N; i++) {
                    const policyId = keys.get(i);
                    const policyIdHex = Buffer.from(policyId.to_bytes(), "utf8").toString("hex");
                    const assets = multiasset.get(policyId)
                    const assetNames = assets.keys();
                    const K = assetNames.len()

                    for (let j = 0; j < K; j++) {
                        const assetName = assetNames.get(j);
                        const assetNameString = Buffer.from(assetName.name(), "utf8").toString();

                        if ('7a3b4cef5cee62f67626b0db635af0c727001841198435ba64dc627c' === policyIdHex
                            || '4430451b6b2cbbe493326d1220a02718c8a2839095175b2d6b7af2cd' === policyIdHex
                            || '22df99934dd10a92c847763d1fadbadbeffedd247af074ef0ea67731' === policyIdHex) {
                            totalChibies.push(assetNameString)
                            totalHexName.push(Buffer.from(assetName.name(), "utf8").toString("hex"))
                        }
                    }
                }
            }
        }

        commit('updateTokensNameHex', totalHexName);

        // return {totalChibies, totalHexName}
    },
    async openChest({commit, state}, {units, transactionId}) {
        if (typeof window.cardano === "undefined" || typeof window.cardano[state.walletType] === "undefined") {
            commit('updateEnableWallet', false)
            return
        }

        try {
            let userWallet;
            if (S.ByronAddress.is_valid(state.wallet)) {
                userWallet = S.ByronAddress.from_base58(state.wallet).to_address();
            } else {
                userWallet = S.Address.from_bech32(state.wallet);
            }

            const totalAda = 3000000
            const api = await window.cardano[state.walletType].enable()
            const rawUtxos = await api.getUtxos()
            const openChestAddress = S.Address.from_bech32('addr1v93me8c6hec0hwzkrv40mfjfu2nd3ya6xjdx3zz3pupa2fgynlxvz');

            const obj = {
                msg: [
                    "Chibidango open chest:",
                    transactionId,
                ]
            };

            const metadata = S.encode_json_str_to_metadatum(JSON.stringify(obj), S.MetadataJsonSchema.NoConversions);
            const metadataString = S.decode_metadatum_to_json_str(metadata, S.MetadataJsonSchema.NoConversions);

            let collateral = []
            if (state.walletType !== 'nami') {
                collateral = await api.getCollateral()
            }

            let address_bench32
            let address = await api.getUsedAddresses()
            if (address.length === 0) {
                address = await api.getUnusedAddresses()
            }

            address_bench32 = S.Address.from_bytes(Buffer.from(address[0], 'hex')).to_bech32()
            if (address_bench32 !== state.wallet) {
                errorToast("User action:", 'Detect change wallet please re-login')
                return
            }

            const params = await initParams()
            const txBuilder = await initTransactionBuilder(params);

            let adaUtxos = []
            let multiTokenUtxos = []
            for (const rawUtxo of rawUtxos) {
                if (collateral.indexOf(rawUtxo) !== -1) {
                    continue
                }
                const utxo = S.TransactionUnspentOutput.from_bytes(Buffer.from(rawUtxo, "hex"));
                const output = utxo.output();
                const multiasset = output.amount().multiasset();
                if (!multiasset) {
                    adaUtxos.push(utxo)
                } else {
                    multiTokenUtxos.push(utxo)
                }
            }

            let totalSendAda = 0
            let foudUnits = [];

            loop:
                for (const utxo of multiTokenUtxos) {
                    const output = utxo.output();
                    const input = utxo.input()
                    const multiasset = output.amount().multiasset();
                    const coins = parseInt(output.amount().coin().to_str())
                    if (!multiasset) {
                        continue
                    }

                    const keys = multiasset.keys()
                    for (let i = 0; i < keys.len(); i++) {
                        const policyId = keys.get(i);
                        const policyIdHex = Buffer.from(policyId.to_bytes(), "utf8").toString("hex");
                        const assets = multiasset.get(policyId)
                        const assetNames = assets.keys();

                        for (let j = 0; j < assetNames.len(); j++) {
                            const assetName = assetNames.get(j);
                            const assetNameHex = Buffer.from(assetName.name(), "utf8").toString("hex");
                            const multiassetAmt = multiasset.get_asset(policyId, assetName)
                            const assetCount = parseInt(multiassetAmt.to_str())

                            if (foudUnits.length === units.units.length) break loop

                            if (units.units.includes(policyIdHex + assetNameHex)) {
                                foudUnits.push(policyIdHex + assetNameHex)
                                let add = JSON.parse(output.to_json())

                                txBuilder.add_input(
                                    S.Address.from_bech32(add.address),
                                    input,
                                    S.Value.new_with_assets(output.amount().coin(), multiasset)
                                )

                                totalSendAda = totalSendAda + coins
                            }
                        }
                    }
                }

            for (const utxo of adaUtxos) {
                const input = utxo.input()
                const output = utxo.output()
                const coins = parseInt(output.amount().coin().to_str())
                let add = JSON.parse(output.to_json())

                txBuilder.add_input(
                    S.Address.from_bech32(add.address),
                    input,
                    S.Value.new(output.amount().coin())
                )

                totalSendAda = totalSendAda + coins

                if (totalSendAda > (totalAda + 10000000)) break;
            }

            if (foudUnits.length !== units.units.length) {
                errorToast('Item', 'Not have chests in wallet to open')
                return []
            }

            let multiAsset = S.MultiAsset.new();

            let assets = S.Assets.new()
            units.units.forEach(item => {
                let hexAssetName = item.slice(56)
                assets.insert(
                    S.AssetName.new(Buffer.from(hexAssetName, "hex")), // Asset Name
                    S.BigNum.from_str(('1')) // How much to send
                );

                multiAsset.insert(
                    S.ScriptHash.from_bytes(Buffer.from(item.slice(0, 56), "hex")), // PolicyID
                    assets
                );
            })

            const txOutputBuild = S.TransactionOutputBuilder.new().with_address(openChestAddress).next()
            const output = txOutputBuild.with_coin_and_asset(S.BigNum.from_str(totalAda.toString()), multiAsset).build()

            txBuilder.add_output(output)
            txBuilder.add_json_metadatum(S.BigNum.from_str("674"), metadataString)
            txBuilder.add_change_if_needed(userWallet)

            const txBody = txBuilder.build();
            const transactionWitnessSet = S.TransactionWitnessSet.new();
            const tx = S.Transaction.new(txBody, S.TransactionWitnessSet.from_bytes(transactionWitnessSet.to_bytes()), txBuilder.get_auxiliary_data())

            const txSign = await api.signTx(Buffer.from(tx.to_bytes(), "utf8").toString("hex"), true);
            const txVkeyWitnesses = S.TransactionWitnessSet.from_bytes(Buffer.from(txSign, "hex"));

            transactionWitnessSet.set_vkeys(txVkeyWitnesses.vkeys());

            const signedTx = S.Transaction.new(tx.body(), transactionWitnessSet, txBuilder.get_auxiliary_data());

            let submittedTxHash

            submittedTxHash = await api.submitTx(Buffer.from(signedTx.to_bytes(), "utf8").toString("hex"));


        } catch (e) {
            console.log(e)
        }
    }
}

export default {
    namespaced: true,
    state: () => getDefaultState(),
    getters,
    mutations,
    actions
}
