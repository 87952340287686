import LocalStorageService from "../../../service/LocalStorageService";
import {
    GAME_INVENTORY_ATTRIBUTE_FILTER,
    GAME_INVENTORY_ITEMS_FILTER,
    GAME_INVENTORY_PAGE,
    GAME_INVENTORY_PER_PAGE,
    GAME_INVENTORY_PERK_FILTER,
    GAME_INVENTORY_RACE_FILTER,
    GAME_INVENTORY_RARITY_FILTER,
    GAME_INVENTORY_SHOW_FILTERS,
} from "../../../constants/localstorageConstants";
import ApiService from "../../../service/api/ApiService";
import {concatUrl} from "../../../helper";
import {toRaw} from "vue";

const state = {
    data: [],
    total: 0,
    showFilter: LocalStorageService.get(GAME_INVENTORY_SHOW_FILTERS, false),
    page: LocalStorageService.get(GAME_INVENTORY_PAGE, 1),
    perPageSelected: LocalStorageService.get(GAME_INVENTORY_PER_PAGE, 36),
    raceFilter: LocalStorageService.get(GAME_INVENTORY_RACE_FILTER, []),
    rarityFilter: LocalStorageService.get(GAME_INVENTORY_RARITY_FILTER, []),
    itemsFilter: LocalStorageService.get(GAME_INVENTORY_ITEMS_FILTER, []),
    perkFilter: LocalStorageService.get(GAME_INVENTORY_PERK_FILTER, []),
    attributeFilter: LocalStorageService.get(GAME_INVENTORY_ATTRIBUTE_FILTER, []),
    isLoad: true
}

const getters = {
    getInventory: state => ({data: state.data, total: state.total}),
    getShowFilters: state => state.showFilter,
    getPage: state => state.page,
    getLoad: state => state.isLoad,
    getPerPage: state => state.perPageSelected,
    getSelectedItemsFilter: state => state.itemsFilter,
    getSelectedPerkFilter: state => state.perkFilter,
    getSelectedAttributeFilter: state => state.attributeFilter,
    getSelectedRaceFilter: state => state.raceFilter,
    getSelectedRarityFilter: state => state.rarityFilter,
}

const mutations = {
    setPage(state, page) {
        state.page = page
    },
    setRaceFilter(state, filter) {
        let index = state.raceFilter.indexOf(filter)
        if (index === -1) {
            state.raceFilter.push(filter)
        } else {
            state.raceFilter = state.raceFilter.filter(item => item.filter !== filter.filter)
        }
    },
    setRarityFilter(state, filter) {
        let index = state.rarityFilter.indexOf(filter)
        if (index === -1) {
            state.rarityFilter.push(filter)
        } else {
            state.rarityFilter = state.rarityFilter.filter(item => item.filter !== filter.filter)
        }
    },
    setItemsFilter(state, filter) {
        let index = state.itemsFilter.indexOf(filter)
        if (index === -1) {
            state.itemsFilter.push(filter)
        } else {
            state.itemsFilter = state.itemsFilter.filter(item => item.filter !== filter.filter)
        }
    },
    setPerkFilter(state, filter) {
        let index = state.perkFilter.indexOf(filter)
        if (index === -1) {
            state.perkFilter.push(filter)
        } else {
            state.perkFilter = state.perkFilter.filter(item => item.filter !== filter.filter)
        }
    },
    setAttributeFilter(state, filter) {
        let index = state.attributeFilter.indexOf(filter)
        if (index === -1) {
            state.attributeFilter.push(filter)
        } else {
            state.attributeFilter = state.attributeFilter.filter(item => item.filter !== filter.filter)
        }
    },
    setEmptyFilter(state) {
        state.attributeFilter = []
        state.perkFilter = []
        state.itemsFilter = []
        state.rarityFilter = []
        state.raceFilter = []
    },
    setInventory(state, inventory) {
        state.data = inventory.tokens
        state.total = inventory.total
    },
    setShowFilters(state) {
        state.showFilter = !state.showFilter
    },
    updateLoadInventory(state, value) {
        state.isLoad = value
    },
    updateClearInventory(state) {
        state.data = []
        state.total = 0
    },
}
const actions = {
    updatePage({commit, dispatch}, page) {
        LocalStorageService.set(GAME_INVENTORY_PAGE, page)
        commit('setPage', page)
    },
    updateItemsFilter({commit, getters}, filter) {
        commit('setItemsFilter', filter)
        LocalStorageService.set(GAME_INVENTORY_ITEMS_FILTER, getters['getSelectedItemsFilter'])
    },
    clearFilters({commit}) {
        commit('setEmptyFilter')
        LocalStorageService.set(GAME_INVENTORY_ITEMS_FILTER, [])
        LocalStorageService.set(GAME_INVENTORY_PERK_FILTER, [])
        LocalStorageService.set(GAME_INVENTORY_RACE_FILTER, [])
        LocalStorageService.set(GAME_INVENTORY_RARITY_FILTER, [])
        LocalStorageService.set(GAME_INVENTORY_ATTRIBUTE_FILTER, [])
    },
    clearInventory({commit}) {
        commit('updateClearInventory')
    },
    updateAttributeFilter({commit, getters}, filter) {
        commit('setAttributeFilter', filter)
        LocalStorageService.set(GAME_INVENTORY_ATTRIBUTE_FILTER, getters['getSelectedAttributeFilter'])
    },
    updateRarityFilter({commit, getters}, filter) {
        commit('setRarityFilter', filter)
        LocalStorageService.set(GAME_INVENTORY_RARITY_FILTER, getters['getSelectedRarityFilter'])
    },
    updateRaceFilter({commit, getters}, filter) {
        commit('setRaceFilter', filter)
        LocalStorageService.set(GAME_INVENTORY_RACE_FILTER, getters['getSelectedRaceFilter'])
    },
    updatePerkFilter({commit, getters}, filter) {
        commit('setPerkFilter', filter)
        LocalStorageService.set(GAME_INVENTORY_PERK_FILTER, getters['getSelectedPerkFilter'])
    },
    updatePerPage({commit, getters}, perPage) {
        commit('setPerPage', perPage)
        LocalStorageService.set(GAME_INVENTORY_PER_PAGE, getters.getPerPage)
    },
    setLoadInventory({commit}, load) {
        commit('updateLoadInventory', load)
    },
    updateShowFilters({commit, getters}) {
        commit('setShowFilters')
        LocalStorageService.set(GAME_INVENTORY_SHOW_FILTERS, getters.getShowFilters)
    },
    fetchInventory({ commit, rootGetters, getters }, {isSummon = false, isRecharge = false}) {
        commit('updateLoadInventory', true)
        commit('setInventory', {tokens: [], total: 0})
        let checkboxes = rootGetters['filters/getGameInventorySelectedCheckboxes']
        let checkboxesPerk = rootGetters['filters/getGameInventorySelectedPerkCheckboxes']
        let parts = rootGetters['filters/getGameInventorySelectedParts']

        let limit = getters['getPerPage']
        let raceFilters = getters['getSelectedRaceFilter']
        let rarityFilters = getters['getSelectedRarityFilter']
        let itemsFilters = getters['getSelectedItemsFilter']
        let perkFilters = getters['getSelectedPerkFilter']
        let attributeFilters = getters['getSelectedAttributeFilter']

        let sendParts = {
            attribute: [],
            item: [],
        }

        let filters = {
            race: [],
            rarity: [],
            slot: [],
            perk: [],
            attribute: [],
        }

        raceFilters.map(item => {
            filters.race.push(item.filter)
        })

        rarityFilters.map(item => {
            filters.rarity.push(item.filter)
        })

        itemsFilters.map(item => {
            filters.slot.push(item.filter)
        })

        attributeFilters.map(item => {
            filters.attribute.push(item.filter)
        })

        perkFilters.map(item => {
            filters.perk.push(item.filter)
        })

        parts.map(item => {
            sendParts[item.type].push(item.id)
        })

        let url = 'inventory/tokens'

        url = concatUrl(url, 'rarity', filters.rarity)
        url = concatUrl(url, 'race', filters.race)
        url = concatUrl(url, 'slot', filters.slot)
        url = concatUrl(url, 'attribute_slot', filters.attribute)
        url = concatUrl(url, 'perk', filters.perk)

        url = concatUrl(url, 'gender', toRaw(checkboxes.gender))
        url = concatUrl(url, 'skin', toRaw(checkboxes.skin))
        url = concatUrl(url, 'type', toRaw(checkboxes.type))
        url = concatUrl(url, 'attribute', sendParts.attribute)
        url = concatUrl(url, 'checkboxes_perk', checkboxesPerk)
        url = concatUrl(url, 'item', sendParts.item)
        url = concatUrl(url, 'page', getters.getPage)
        url = concatUrl(url, 'limit', limit)

        if (isSummon) {
            url = concatUrl(url, 'is_summon', true)
        }

        if (isRecharge) {
            url = concatUrl(url, 'is_recharge', true)
        }

        ApiService.get(url).then(gameInventory => {
            commit('setInventory', gameInventory)
            commit('updateLoadInventory', false)
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
