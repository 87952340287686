<template>
    <div class="-item-holder" v-for="(item, i) in items" :key="i" v-on:click="setItem(item)">
        <Item :item="item"/>
    </div>
</template>

<script setup lang="ts">
import Item from "./Item.vue";
import {useFilterStore} from "../../../../../store/useFilterStore";

const props = defineProps({
    type: {
        type: String,
        required: true,
    },
    items: {
        type: Array,
        default: () => ([])
    }
})

const filterStore = useFilterStore(props.type)

const setItem = (item) => {
    filterStore.setSelectedPart(item)
}
</script>

<style scoped>

</style>
