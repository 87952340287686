import LocalStorageService from "../service/LocalStorageService";
import {ADDRESS, WALLET_API_NAME, WALLET_TYPE} from "../entities/Wallet";
import {defineStore} from "pinia";
import {useLocalStorage} from "@vueuse/core";

const CARDANO_DECIMALS = 1000000

interface Wallet {
    walletType: string,
    wallet: string,
    walletApiName: string,
    isLoad: boolean,
    adaBalance: string,
    usdPrice: string,
    dgemBalance: string,
}

const defaultState: Wallet = {
    wallet: useLocalStorage(ADDRESS, ''),
    walletType: useLocalStorage(WALLET_TYPE, ''),
    walletApiName: useLocalStorage(WALLET_API_NAME, ''),
    isLoad: false,
    adaBalance: '0',
    usdPrice: '0',
    dgemBalance: '0',
}

export const useWalletStore = defineStore('wallet', {
    state: (): Wallet => defaultState,
    getters: {
        getWallet: (state: Wallet) => state.wallet,
        getWalletType: (state: Wallet) => state.walletType,
        getWalletApiName: (state: Wallet) => state.walletApiName,
        getLoadWallet: (state: Wallet) => state.isLoad,
        getAdaBalance: (state: Wallet) => state.adaBalance,
        getUsdPrice: (state: Wallet) => parseFloat(state.usdPrice).toFixed(2),
        getDgemBalance: (state: Wallet) => state.dgemBalance,
    },
    actions: {
        setWallet(wallet: string): void {
            LocalStorageService.set('wallet', wallet) //TODO: remove
            this.wallet = wallet
        },
        setWalletType(walletType: string): void {
            this.walletType = walletType
        },
        setWalletApiName(walletApiName: string): void {
            this.walletApiName = walletApiName
        },
        setLoadWallet(isLoad: boolean): void {
            this.isLoad = isLoad
        },
        setAdaBalance(adaBalance: string): void {
            this.adaBalance = (parseInt(adaBalance) / CARDANO_DECIMALS).toFixed(2)
        },
        setUsdPrice(usd: string): void {
            this.usdPrice = usd
        },
        setDgemBalance(dgemBalance: string): void {
            this.dgemBalance = dgemBalance
        },
        clear(): void {
            LocalStorageService.set(ADDRESS, '')
            LocalStorageService.set(WALLET_TYPE, '')
            LocalStorageService.set(WALLET_API_NAME, '')
            this.dgemBalance = '0'
            this.adaBalance = '0'
            this.walletApiName = ''
            this.walletType = ''
            this.wallet = ''
        }
    }
})