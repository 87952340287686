<template>
    <div class="nft-box" :class="nft.transaction !== null ? (nft.transaction.wallet === wallet ? 'myChibi' : '') : ''">
        <div class="top">
            <div class="-name">{{ nft.name }}</div>
            <USummonShards :style="resizeSettings.summonShards" :summon-count="nft.summon_count"/>
        </div>
        <div class="middle ui-loading">
            <img :src="`https://storage.chibidangoheroes.com/asset/${nft.token_id_hex}`" crossorigin="anonymous" :alt="nft.name"
                 onload="this.parentElement.classList.remove('ui-loading')"/>
        </div>
        <div class="bottom" v-if="haveTransaction()">
            <div class="price -ada">
                <NumberFormat :number="getTransactionPrice()"/>
            </div>
            <div class="price -dollar">$&nbsp;{{ getPrice() }}</div>
        </div>

        <div class="perk-box" v-for="(perk, i) in nft.perks" :key="i">
            <img class="objectImg" :src="`/images/perks/icons/${perk.image}.png`"/>
        </div>
        <div class="dgems-box" v-if="nft.available_dgems > 0">
            <span>{{ nft.available_dgems }}</span>
        </div>
        <div class="type-box">
            <Types :data-types="nft.types" :size="20" :marginLeft="5"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import USummonShards from "@/components/chibidango/summon/uSummonShards.vue";
import Types from "@/components/nft/type/Types.vue";
import {Nft} from "@/entities/Nft";
import {computed, PropType, ref} from "vue";
import {useWalletStore} from "@/store/useWalletStore";
import NumberFormat from "@/components/NumberFormat.vue";

const walletStore = useWalletStore()

const props = defineProps({
    nft: {
        type: Object as PropType<Nft>,
        required: true
    },
    type: {
        type: String,
        default: () => ('')
    },
    resize: {
        type: String,
        default: () => (""),
    }
})

const resizeSettings = ref({
    summonShards: {
        size: 16,
        margin: 2
    }
})

const haveTransaction = () => {
    let have = props.nft.transaction !== null || props.nft.external_market !== null;

    if (props.type === 'dashboard_sold') {
        have = props.nft.soldTransaction !== null
    }

    return have
}

const getTransactionPrice = () => {
    if (props.type === 'dashboard_sold' && props.nft.soldTransaction !== null) {
        return props.nft.soldTransaction.price
    }

    if (props.nft.external_market === null && props.nft.transaction !== null) {
        return props.nft.transaction.price
    }

    if (props.nft.external_market !== null ) {
        return props.nft.external_market.listing_price
    }

    return 0
}

const getUsdPrice = computed(() => walletStore.getUsdPrice)

const getPrice = () => {
    let price = 0

    if (props.type === 'dashboard_sold' && props.nft.soldTransaction !== null) price = props.nft.soldTransaction.price
    if (props.nft.transaction !== null) price = props.nft.transaction.price
    if (props.nft.external_market !== null) price = props.nft.external_market.listing_price

    return (price * parseFloat(getUsdPrice.value)).toFixed(2)
}

const wallet = computed(() => walletStore.getWallet)
</script>

<style lang="scss" scoped>
.nft-box {
    position: relative;

    width: 100%;
    max-width: 220px;
    min-height: 270px;

    padding: 42px 13px 32px 13px;

    background-color: #292D3F;
    background-image: radial-gradient(50% 50% at 50% 50%, #525A76 0%, #2F3246 100%);
    border: 1px solid #3D4152;
    border-radius: 6px;

    transition: all .3s ease;

    &:hover {
        cursor: pointer;
        border-color: #8A70ED;
        box-shadow: 0px 0px 4px rgba(138, 112, 237, 0.25);
    }

    .top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;

        padding: 11px 14px 0px 14px;

        .-name {
            font-family: "PoetsenOne";
            color: #ffffff;
            flex: 1 1;

            text-align: left;
            font-size: 14px;
            line-height: 17px;
        }


    }

    .middle {
        //position: relative;
        z-index: 4;

        img {
            object-fit: contain;
            width: 100%;
            opacity: 1;

            &:after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
        }

        &.ui-loading {
            img {
                opacity: 0;
            }
        }
    }

    .bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 5;

        font-weight: 400;
        text-align: center;
        padding: 0px 15px 12px 15px;

        .price.-ada {
            font-family: "PoetsenOne";
            font-size: 18px;
            color: #ffffff;
            line-height: 22px;

            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: "";
                height: 16px;
                width: 16px;
                display: block;
                margin-right: 5px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-image: url("/images/market/icons/cardano-currency-logo.svg");
            }
        }

        .price.-dollar {
            font-family: "PoetsenOne";
            font-size: 12px;
            color: #AAAAAA;
            line-height: 14px;
        }
    }

    .type-box {
        position: absolute;
        top: 10px;
        right: 15px;
        z-index: 3;

        display: flex;
    }

    .perk-box {
        position: absolute;
        width: 50px;
        height: 50px;
        bottom: 0px;
        right: 0px;
    }

    .dgems-box {
        position: absolute;
        bottom: 10px;
        left: 10px;

        min-width: 30px;

        font-family: "PoetsenOne";
        color: #FFFFFF;
        -webkit-text-stroke: 0.5px #292D3F;
        font-size: 13px;
        text-align: center;

        &:before {
            content: "";
            position: absolute;
            bottom: 0px;

            height: 30px;
            width: 30px;
            display: block;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url("/images/market/icons/ic-dgem.svg");
        }

        span {
            position: relative;
            z-index: 2;
        }
    }

    :deep(.summon-shards-list) {
        padding-top: 4px;
    }
}
</style>
