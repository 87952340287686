export const ERROR_NETWORK_TEST:string = 'ERROR_NETWORK_TEST'
export const ERROR_WALLET_SELECT_API:string = 'ERROR_WALLET_SELECT_API'
export const ERROR_CANCEL_CONNECTION:string = 'ERROR_CANCEL_CONNECTION'
export const ERROR_WALLET_API:string = 'ERROR_WALLET_API'
export const ERROR_NOT_SELECT_WALLET:string = 'ERROR_NOT_SELECT_WALLET'
export const ERROR_ACCOUNT_BALANCE:string = 'ERROR_ACCOUNT_BALANCE'
export const NOT_ENOUGH_BALANCE:string = 'NOT_ENOUGH_BALANCE'
export const UNHANDLED_ERROR:string = 'UNHANDLED_ERROR'
export const UNHANDLED_WALLET_ERROR:string = 'UNHANDLED_WALLET_ERROR'
export const USER_DECLINE_TRANSACTION:string = 'USER_DECLINE_TRANSACTION'
export const ERROR_CHANGE_WALLET:string = 'ERROR_CHANGE_WALLET'
export const ADDRESS_BENCH32:string = 'address_bench32'
export const ADDRESS:string = 'address'
export const WALLET_TYPE = 'wallet_type'
export const WALLET_API_NAME = 'WALLET_API_NAME'
export const MIN_ADA_AMOUNT_TO_COVER_MINT_BASE: number = 3
export const MIN_ADA_AMOUNT_TO_COVER_MINT = MIN_ADA_AMOUNT_TO_COVER_MINT_BASE * 1000000
export const COIN_PER_BYTE = '4310'
export const SEASON_POLICY = '7a3b4cef5cee62f67626b0db635af0c727001841198435ba64dc627c'
export const MYTHIC_POLICY = '4430451b6b2cbbe493326d1220a02718c8a2839095175b2d6b7af2cd'

export type Wallet = {
    type: string;
    label: string;
    allowed: boolean;
    installed: boolean;
    downloadLink: string;
    apiName: string;
    source: string;
    isHD: boolean;
};

export type WalletBalances = {
    adaBalance: string,
    dgemBalance: string,
}

export declare type SendUnit = {
    unit: string;
    quantity: string;
};

export type Utxo = {
    input: {
        txHash: string
        outputIndex: number
    }
    output: {
        address: string
        amount: OutputAmount[]
    }
}

export type OutputAmount = {
    quantity: string
    unit: string
}

export type SelectedUTxO = {
    total: number,
    utxos: Utxo[],
    unit: string
}
