import LocalStorageService from "../service/LocalStorageService";
import {IS_AUTH} from "../entities/User";
import {defineStore} from "pinia";

interface User {
    isAuth: boolean,
    totalItems: number,
    totalNft: number,
}

const defaultState: User = {
    isAuth: !!LocalStorageService.get(IS_AUTH, false), //we can also use !! for a string to boolean conversion.
    totalItems: 0,
    totalNft: 0,
}

export const useUserStore = defineStore('user', {
    state: (): User => defaultState,
    getters: {
        getAuth: (state: User) => state.isAuth,
        getTotalItems: (state: User) => state.totalItems,
        getTotalNft: (state: User) => state.totalNft,
    },
    actions: {
        setAuth(isAuth: boolean): void {
            LocalStorageService.set(IS_AUTH, isAuth.toString())
            this.isAuth = isAuth
        },
        setTotalNft(total: number) {
            this.totalNft = total
        },
        setTotalItems(total: number) {
            this.totalItems = total
        },
        clear() {
            LocalStorageService.set(IS_AUTH, 'false')
            this.isAuth = false
        }
    },
})