<template>
  <template v-for="(type, i) in types">
    <TypeIcon v-if="typeof dataTypes[type] !== 'undefined'"
              :type="dataTypes[type].group"
              :key="i"
              :label="label"
              :size="size"
              :margin-left="marginLeft"
              :margin-right="marginRight"/>
  </template>
</template>

<script>
import TypeIcon from "./TypeIcon.vue";
export default {
  name: "Types",
  components: {TypeIcon},
  props: {
    dataTypes: {
      type: Object,
      required: true,
    },
    label: {
      label: Boolean,
      default: () => false,
    },
    size: {
      size: String,
      required: true,
      default: () => (""),
    },
    marginLeft: {
      marginLeft: String,
      default: () => ("0"),
    },
    marginRight: {
      marginRight: String,
      default: () => (""),
    },
  },
  data: () => ({
    types: ['mythic', 'tribute', 'season1', 'season2', 'season3', 'summon'],
  }),
}
</script>
