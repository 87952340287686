<template>
    <div class="flex flex-wrap gap-2 pt-3 pb-1">
        <div :class="`filter-item-tag ${item.tag} ${isSelectedFilter(item) ? '-selected' : ''}`" v-for="(item, i) in filterAttributes()" :key="i" v-on:click="selectFilter(item)">
            <span>{{item.name}}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {useFilterStore} from "../../../../store/useFilterStore";
import {filterAttribute, FiltersType} from "../../../../entities/Filters";
import {computed, watch} from "vue";

const props = defineProps({
    name: {
        type: String,
        required: true
    }
})

const emmit = defineEmits<{
    (e: 'is-selected', value: boolean )
}>();

const filterStore = useFilterStore(props.name)
const slotFilters = computed(() => filterStore.getAttributeSlotFilters)
const isSelectedFilter = (filter: FiltersType) => filterStore.getAttributeSlotFilters.find(item => item.filter === filter.filter) !== undefined
const selectFilter = (item: FiltersType) => filterStore.setAttributeSlotFilter(item)
const type = computed(() => filterStore.getFilterType)

const filterAttributes = () => {
    return filterAttribute.filter(item => {
        if (item.exclude) {
            return item.exclude.indexOf(type.value) === -1
        }

        return true
    })
}

watch(slotFilters,(check) => {
    emmit('is-selected', check.length > 0)
}, {deep: true})

</script>

<style lang="scss" scoped>
.filter-item-tag {
    height: 24px;
    padding: 0 12px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--mm-color-dark3);
    font-size: 12px;

    cursor: pointer;

    &:hover { background: var(--mm-color-secondary); }
    &.-selected { background: var(--mm-color-primary);}

    &:before {
        content: "";
        width: 14px;
        height: 14px;
        display: block;
        margin-right: 8px;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &.--mouth {
        &:before {background-image: url("/images/icons/filters/ic-mouth.png");}
    }
    &.--eyes {
        &:before {background-image: url("/images/icons/filters/ic-eyes.png");}
    }
    &.--hairstyle {
        &:before {background-image: url("/images/icons/filters/ic-hairstyle.png");}
    }
}
</style>
