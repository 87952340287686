<template>
<span>{{getNumber()}}</span>
</template>

<script lang="ts" setup>
import {numberWithCommas} from "@/helper";

const props = defineProps({
    number: {
        type: [Number, String],
        required: true,
    }
})

const getNumber = () => (numberWithCommas(props.number))
</script>

<style scoped>

</style>