import {toast, ToastType} from "vue3-toastify";
import 'vue3-toastify/dist/index.css';

export const ERROR:ToastType = 'error'
export const INFO:ToastType = 'info'
export const SUCCESS:ToastType = 'success'

export function errorToast(message: string) {
    toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        theme: 'dark'
    });
}

export function infoToast(message: string) {
    toast.info(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        theme: 'dark'
    });
}

export function successToast(message: string) {
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        theme: 'dark'
    });
}

export function defaultToast(type:ToastType, message:string) {
    toast(message, {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        theme: 'dark'
    });
}