
export const filterTypes = {
    MARKET_CHIBI: 'market',
    MARKET_ITEM: 'marketItem',
    MARKET_INVENTORY_CHIBI: 'inventory',
    MARKET_INVENTORY_ITEM: 'itemInventory',
    GAME_INVENTORY_CHIBI: 'game',
    GAME_INVENTORY_ITEM: 'gameItemInventory'
}
export const groups = [
    {
        // type: 'season1',
        group: 'HUMAN'
    },
    {
        // type: 'season2',
        group: 'NIGHT_ELVES'
    },
    {
        // type: 'summon',
        group: 'SUN_ELVES'
    },
    {
        // type: 'season3',
        group: 'ORCS'
    },
    {
        // type: 'mythic',
        group: 'MYTHIC'
    },
]

export const checkboxes = {
    type: {
        label: 'TYPE',
        key: 'type',
        items: [
            {key: 'season1', label: 'Season 1'},
            {key: 'mythic', label: 'Mythic'},
            {key: 'season2', label: 'Season 2'},
            {key: 'tribute', label: 'Tribute'},
            {key: 'season3', label: 'Season 3'},
            {key: 'summon', label: 'Summon'}
        ]
    },
    gender: {
        label: 'GENDER',
        key: 'gender',
        items: [
            {key: 'male', label: 'Male'},
            {key: 'female', label: 'Female'},
        ]
    },
    skin: {
        label: 'SKIN COLOR',
        key: 'skin',
        items: [
            {key: 'light', label: 'Light'},
            {key: 'dark', label: 'Dark'},
        ]
    },
}

export const perkCheckboxes = {
    key: 'perks',
    items: [
        {key: 'adventurer', label: 'adventurer'},
        {key: 'alchemist', label: 'alchemist'},
        {key: 'armorsmith', label: 'armorsmith'},
        {key: 'caretaker', label: 'caretaker'},
        {key: 'gladiator', label: 'gladiator'},
        {key: 'monsterslayer', label: 'monsterslayer'},
        {key: 'navigator', label: 'navigator'},
        {key: 'tanner', label: 'tanner'},
        {key: 'weaponsmith', label: 'weaponsmith'},
    ]
}

export const attributesParts = {
    MOUTH: [],
    EYES: [],
    HAIRSTYLE: [],
}

export const itemsParts = {
    BODYSUIT: [],
    FACEMASK: [],
    BACKGROUND: [],
    LEFTHAND: [],
    HAT: [],
    RIGHTHAND: [],
    PET: [],
}

export const attributesRaces = {
    HUMAN: attributesParts,
    MYTHIC: attributesParts,
    NIGHT_ELVES: attributesParts,
    SUN_ELVES: attributesParts,
    ORCS: itemsParts,
}

export const itemsRaces = {
    HUMAN: itemsParts,
    MYTHIC: itemsParts,
    NIGHT_ELVES: itemsParts,
    SUN_ELVES: itemsParts,
    ORCS: itemsParts,
}

export const itemInventoryDropdown = [
    {name: 'All Items', filter: 'all'},
    {name: 'Equipped', filter: 'equipped'},
    {name: 'Unequipped', filter: 'unequipped'},
]

export const filterRace = [
    {name: 'Human', filter: 'HUMAN', tag: '--human'},
    {name: 'Elf', filter: 'NIGHT_ELVES', tag: '--elf'},
    {name: 'Sun Elf', filter: 'SUN_ELVES', tag: '--sunelf'},
    {name: 'Orc', filter: 'ORCS', tag: '--orc'},
]

export const filterRarity = [
    {name: 'Common', filter: 'COMMON', tag: '--common'},
    {name: 'Rare', filter: 'RARE', tag: '--rare'},
    {name: 'Epic', filter: 'EPIC', tag: '--epic'},
    {name: 'Legendary', filter: 'LEGENDARY', tag: '--legendary'},
    {name: 'Immortal', filter: 'IMMORTAL', tag: '--immortal'},
]

export const filterSlot = [
    {name: 'Hat', filter: 'HAT', tag: '--hat'},
    {name: 'Suit', filter: 'BODYSUIT', tag: '--bodysuit', exclude: []},
    {name: 'Trinket', filter: 'LEFTHAND', tag: '--lefthand', exclude: []},
    {name: 'Mask', filter: 'FACEMASK', tag: '--facemask', exclude: []},
    {name: 'Weapon', filter: 'RIGHTHAND', tag: '--righthand', exclude: []},
    {name: 'Pet', filter: 'PET', tag: '--pet', exclude: []},
    {name: 'Background', filter: 'BACKGROUND', tag: '--background', exclude: []},
    {name: 'Resource', filter: 'RESOURCE', tag: '--resource', exclude: [filterTypes.MARKET_CHIBI, filterTypes.GAME_INVENTORY_CHIBI, filterTypes.MARKET_INVENTORY_ITEM]},
    {name: 'Chest', filter: 'CHEST', tag: '--chest', exclude: ['market', 'game']},
]

export const filterPerk = [
    {name: 'Adventurer', filter: 'adventurer', tag: '--adventurer'},
    {name: 'Armorsmith', filter: 'armorsmith', tag: '--armorsmith'},
    {name: 'Gladiator', filter: 'gladiator', tag: '--gladiator'},
    {name: 'Navigator', filter: 'navigator', tag: '--navigator'},
    {name: 'Weaponsmith', filter: 'weaponsmith', tag: '--weaponsmith'},
    {name: 'Alchemist', filter: 'alchemist', tag: '--alchemist'},
    {name: 'Caretaker', filter: 'caretaker', tag: '--caretaker'},
    {name: 'Monster Slayer', filter: 'monsterslayer', tag: '--monsterslayer'},
    {name: 'Tanner', filter: 'tanner', tag: '--tanner'},
    {name: 'All Perks', filter: 'all', tag: '--all'},
]

export const filterAttribute = [
    {name: 'Mouth', filter: 'MOUTH', tag: '--mouth'},
    {name: 'Eyes', filter: 'EYES', tag: '--eyes'},
    {name: 'Hairstyle', filter: 'HAIRSTYLE', tag: '--hairstyle'}
]

