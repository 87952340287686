export const LEFT_WIDGET = 'leftWidget';
export const RIGHT_WIDGET = 'rightWidget';
export const BASE_SINGLE_NFT = {
    items: {
        hat: {rarity: '', image_name: '', slot: ''},
        bodysuit: {rarity: '', image_name: '', slot: ''},
        facemask: {rarity: '', image_name: '', slot: ''},
        lefthand: {rarity: '', image_name: '', slot: ''},
        righthand: {rarity: '', image_name: '', slot: ''},
        pet: {rarity: '', image_name: '', slot: ''},
    },
    attributes: {
        gender: {rarity: '', image_name: '', slot: ''},
        skin: {rarity: '', image_name: '', slot: ''},
        hairstyle: {rarity: '', image_name: '', slot: ''},
        eyes: {rarity: '', image_name: '', slot: ''},
        mouth: {rarity: '', image_name: '', slot: ''},
        background: {rarity: '', image_name: '', slot: ''},
    },
    nft_id: '',
    transaction: null,
    external_market: null,
    can_cancel: false,
    is_pending: false,
    can_sell: false,
}
