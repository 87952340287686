<template>
    <component :is="componentMap[component]" v-bind="{...props, ...$attrs}"/>
</template>

<script lang="ts" setup>
import PNGResizeIcon from "@/components/v2/icons/views/PNGResizeIcon.vue";
import WalletTypeIcon from "@/components/v2/icons/views/WalletTypeIcon.vue";

const componentMap = {
    'png-resize-icon': PNGResizeIcon,
    'wallet-type-icon': WalletTypeIcon,
}

const props = defineProps({
    component: {
        type: String,
        required: true,
    }
})
</script>