<template>
    <div class="-label" v-if="!isEmpty">{{ name }}</div>
    <div class="-items" v-if="!isEmpty">
        <ItemFilter v-for="(item, i) in items.COMMON" :type="type" :key="i" :items="item"/>
        <ItemFilter v-for="(item, i) in items.RARE" :type="type" :key="i" :items="item"/>
        <ItemFilter v-for="(item, i) in items.EPIC" :type="type" :key="i" :items="item"/>
        <ItemFilter v-for="(item, i) in items.LEGENDARY" :type="type" :key="i" :items="item"/>
        <ItemFilter v-for="(item, i) in items.IMMORTAL" :type="type" :key="i" :items="item"/>
    </div>
</template>

<script setup lang="ts">
import {computed} from "vue";
import ItemFilter from "./ItemFilter.vue";

const props = defineProps({
    type: {
        type: String,
        default: () => ('market')
    },
    name: {
        type: String,
        default: () => ("")
    },
    items: {
        type: Object,
        default: () => ({
            COMMON: [],
            RARE: [],
            EPIC: [],
            LEGENDARY: [],
        })
    }
})

const isEmpty = computed(() => {
    return (typeof props.items.COMMON === 'undefined' || props.items.COMMON.length === 0)
        && (typeof props.items.RARE === 'undefined' || props.items.RARE.length === 0)
        && (typeof props.items.EPIC === 'undefined' || props.items.EPIC.length === 0)
        && (typeof props.items.LEGENDARY === 'undefined' || props.items.LEGENDARY.length === 0)
})
</script>

<style scoped>

</style>
