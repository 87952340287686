<template>
    <ModalHeader>Edit Price</ModalHeader>
    <ModalBody class="flex items-center">
        <UpdatePriceTimer/>
        <Card :nft="data"/>
        <div class="sell-box">
            <p class="-info -warning">This feature in Alpha test mode. If you have any issue please open a ticket in our discord</p>
            <p class="-info">Your chibi will have the price updated on the Marketplace without canceling your sale.</p>
            <div class="-label">edit price</div>
            <div class="-data">
                <div class="-input-holder">
                    <input class="ui-input" :class="error ? '-input-error' : ''" type="number" v-model="inputPrice" v-on:keyup="changeInput()"/>
                </div>
                <button class="ui-btn btn-purple btn-ic ic-edit" :class="error ? 'disabled' : ''" :disabled="error" v-on:click="updatePrice(data)"><span>Save</span></button>
            </div>
            <div :class="`${error ? '-show': ''} -error`">{{ errorMsg }}</div>
            <div class="-fee">Fee: <span>2.5%</span> or <span>1 ADA</span> minimum</div>
            <div class="-fee">You will receive &asymp; <span>{{ reciveAda.toFixed(2) }} ADA</span>${{ getPrice().toFixed(2) }}</div>
        </div>
    </ModalBody>
</template>

<script lang="ts" setup>
import Card from "@/components/nft/view/Card.vue";
import UpdatePriceTimer from "@/components/ui/UpdatePriceTimer.vue";
import {useWalletStore} from "@/store/useWalletStore";
import {Nft} from "@/entities/Nft";
import {PropType, computed, ref, onBeforeMount} from "vue";
import ApiService from "@/service/ApiService";
import {useEmitter} from "@/useEmitter";
import {defaultToast, ERROR, SUCCESS} from "@/service/ToastService";
import ModalHeader from "@/components/v2/modals/components/ModalHeader.vue";
import ModalBody from "@/components/v2/modals/components/ModalBody.vue";

const props = defineProps({
    data: {
        type: Object as PropType<Nft>,
        default: () => ({})
    }
})

const inputPrice = ref(0)
const errorMsg = ref('Minimum sell amount: 8 ADA')
const reciveAda = ref(0)
const error = ref(false)

const emitter = useEmitter()
const walletStore = useWalletStore()

const price = computed(() => (walletStore.getUsdPrice))

const close = async () => {
    await ApiService.get('transaction/unlock/' + props.data.transaction.hash)
    emitter.emit('getChibiStatus')
    emitter.emit('openModal', {isOpen: false, componentModal: '', data: {}})
}

const changeInput = () => {
    error.value = false
    if (inputPrice.value === '') {
        reciveAda.value = 0
        inputPrice.value = 0
        error.value = true
        return
    }
    let price = parseFloat(inputPrice.value)
    let fee = price * 2.5 / 100
    if (price < 8) {
        error.value = true
    }
    reciveAda.value = price - (fee < 1 ? 1 : fee)
    if (typeof inputPrice.value === 'string') {
        inputPrice.value = inputPrice.value.replace(/,/g, "")
    }
    inputPrice.value = inputPrice.value.toString().replace(/^0+/, '')
}

const getPrice = () => {
    return parseFloat(reciveAda.value) * price.value
}

const updatePrice = (asset) => {
    try {
        if (props.data.transaction === null) {
            defaultToast(ERROR, 'Don`t have transaction')
            return
        }

        ApiService.post('transaction/update/' + props.data.transaction.hash, {
            price: inputPrice.value,
            nft: asset
        }).then(async response => {
            if (typeof response.error !== "undefined") {
                defaultToast(ERROR,  response.error)
            } else {
                await ApiService.get('transaction/unlock/' + props.data.transaction.hash)
                defaultToast(SUCCESS,  'Price successfully updated')
            }
        })

        emitter.emit('getChibiStatus')
        emitter.emit('openModal', {isOpen: false, componentModal: ''})
    } catch (e) {
      console.log(e)
        let msg;
        if (typeof e === 'string' || e instanceof String) {
            msg = e
        } else {
            msg = e.message
        }
        if (typeof e.info !== "undefined") {
            msg = e.info
        }
        defaultToast(ERROR, msg)
    }
}

const emmit = defineEmits<{
    (e: 'modalWidth', value: string )
}>();

onBeforeMount(() => {
    emmit("modalWidth", '600px')
})
</script>

<style lang="scss" scoped>
.sell-box {
    padding-left: 30px;

    .-input-holder {
        position: relative;

        &:before {
            content: "₳";
            width: 24px;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ui-input {
            font-size: 15px;
            line-height: 18px;
            font-weight: 300;
            padding-left: 24px;
        }
    }


    .-label {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #7E8AC0;
        padding-bottom: 8px;
    }

    .-info {
        font-weight: 300;
        font-size: 12px;
        margin-bottom: 24px;
    }

    .-warning {
        padding: 5px;
        border-radius: 5px;
        background: rgba(228, 159, 77, 0.75);
    }

    .-data {
        display: flex;
        align-items: center;
        padding-bottom: 12px;
    }

    .-error {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #EC8484;
        padding-bottom: 10px;
        opacity: 0;

        &.-show {
            transition: all 0.1s ease;
            opacity: 1;
        }
    }

    .-input-holder {
        flex: 1 1;
        padding-right: 12px;
    }

    .-input-error {
        border-color: #ED7070;
    }

    .-fee {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #AAAAAA;
        padding: 1px 0;

        span {
            font-weight: 500;
            color: #ffffff;
        }
    }
}

.nft-box {
    margin: 0;

    &:hover {
        border-color: #3D4152;
        box-shadow: none;
    }
}
</style>
