import {createLogger, createStore} from "vuex";
import base from "./modules/base";
import inventory from "./modules/inventories/inventory";
import market from "./modules/inventories/market";
import filters from "./modules/filters";
import wallet from "./modules/wallet";
import itemInventory from "./modules/inventories/itemInventory";
import game from "./modules/inventories/game";
import gameItemInventory from "./modules/inventories/gameItemInventory";
import dressing from "./modules/dressing";
import summon from "./modules/summon";

const debug = false//process.env.NODE_ENV !== 'production'

const store = createStore({
    modules: {base, inventory, market, filters, wallet, itemInventory, game, gameItemInventory, dressing, summon},
    strict: debug,
    plugins: debug ? [createLogger()] : []
})

export default store
