import ApiService from "../../service/api/ApiService";
import {LEFT_WIDGET, RIGHT_WIDGET} from "../../constants/baseConstants";
import LocalStorageService from "../../service/LocalStorageService";

export default {
    namespaced: true,
    state: () => ({
        view: 'card',
        wallet: '',
        hexWallet: localStorage.getItem('hexWallet') || '',
        walletBalance: '',
        walletAssets: '',
        cardanoPrice: 0,
        isOpenRightWidget: false,
        isOpenLeftWidget: false,
        leftWidgetComponent: '',
        rightWidgetComponent: '',
        dashboardPage: LocalStorageService.get('dashboardPage', 1),
        totalChibies: 0,
        totalItems: 0,
        stopMusic: LocalStorageService.get('stopMusic', false)
    }),
    getters: {
        getStopMusic: state => state.stopMusic,
        getView: state => state.view,
        getWallet: state => state.wallet,
        getHexWallet: state => state.hexWallet,
        getWalletBalance: state => state.walletBalance,
        getWalletAssets: state => state.walletAssets,
        getCardanoPrice: state => state.cardanoPrice,
        getDashboardPage: state => state.dashboardPage,
        getTotalChibies: state => state.totalChibies,
        getTotalItems: state => state.totalItems,
    },
    actions: {
        offMusic({commit}) {
            commit('offMusic')
        },
        fetchTotalChibies({ commit }) {
            ApiService.get('profile/total_chibis').then(response => {
                if (typeof response.total !== "undefined") {
                    commit('setTotalChibies', response.total)
                }
            })
        },
        fetchTotalItems({ commit }) {
            ApiService.get('profile/total_items').then(response => {
                if (typeof response.total !== "undefined") {
                    commit('setTotalItems', response.total)
                }
            })
        },
        setDashboardPage({ commit }, page) {
            commit('updateDashboardPage', page)
            LocalStorageService.set('dashboardPage', page)
        },
        openWidget({ commit }, {type, component, isOpen}) {
            commit('setOpenWidget', {type, isOpen})
            commit('setWidgetComponent', {type, component})
        },
        changeMusic({ commit }) {
            commit('updateMusic')
        },
        updateWidgetComponent({ commit }, {type, component}) {
            commit('setWidgetComponent', {type, component})
        },
        fetchCardanoPrice({ commit }) {
            ApiService.getPrice().then(response => {
                commit('setCardanoPrice', response)
            }).catch(() => {
              console.log('Just get api timeout')
            })

            setInterval(() => {
                ApiService.getPrice().then(response => {
                    commit('setCardanoPrice', response)
                }).catch(() => {
                  console.log('Just get api timeout')
                })
            }, 60000)
        },
        // async fetchWalletAssets({dispatch, commit}, emitter) {
        //     let walletBalance = await getBalance()
        //     let networkId = await getNetworkId()
        //     let network = {id: networkId, node: networkId == 1 ? 'mainnet' : 'testnet'}
        //     let data = JSON.stringify({balance: walletBalance, network})
        //
        //     commit('setWalletBalance', walletBalance)
        //     ApiService.postSerialization('assets', data).then(response => {
        //         commit('setWalletAssets', response)
        //         dispatch('inventory/sync', emitter, {root: true})
        //     })
        // },
        setHexWallet({commit}, wallet) {
            localStorage.setItem('hexWallet', wallet)
            commit('setPureHexWallet', wallet)
        },
        // async fetchWalletData({ dispatch, commit }, emitter) {
        //     emitter.emit('isLoadInventory', true)
        //     let wallet = await WalletService.getAddress()
        //
        //     commit('setWallet', wallet)
        //     commit('setHexWallet', wallet)
        //     // dispatch('fetchWalletAssets', emitter)
        // },
        updateNftView({ commit }, view) {
            commit('setNftView', view)
        }
    },
    mutations: {
        updateMusic(state) {
            LocalStorageService.set('stopMusic', !state.stopMusic)
            state.stopMusic = !state.stopMusic
        },
        setTotalChibies(state, total) {
            state.totalChibies = total
        },
        setTotalItems(state, total) {
            state.totalItems = total
        },
        updateDashboardPage(state, page) {
            state.dashboardPage = page
        },
        setWidgetComponent(state, {type, component}) {
            if (type === LEFT_WIDGET) {
                state.leftWidgetComponent = component
            }

            if (type === RIGHT_WIDGET) {
                state.rightWidgetComponent = component
            }
        },
        setOpenWidget(state, {type, isOpen}) {
            if (type === LEFT_WIDGET) {
                state.isOpenLeftWidget = isOpen
            }

            if (type === RIGHT_WIDGET) {
                state.isOpenRightWidget = isOpen
            }
        },
        setCardanoPrice(state, price) {
            state.cardanoPrice = price
        },
        setPureHexWallet(state, wallet) {
            state.hexWallet = wallet
        },
        // setHexWallet(state, wallet) {
        //     let address = S.Address.from_bytes(_Buffer.from(wallet, 'hex')).to_bech32()
        //
        //     localStorage.setItem('hexWallet', address)
        //     state.hexWallet = address
        // },
        setWalletBalance(state, walletBalance) {
            state.walletBalance = walletBalance
        },
        setWalletAssets(state, walletAssets) {
            state.walletAssets = walletAssets
        },
        setNftView(state, view) {
            state.view = view
        },
        offMusic(state) {
            state.stopMusic = true
            LocalStorageService.set('stopMusic', true)
        }
    },
}
