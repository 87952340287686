<template>
    <div class="flex flex-col items-center justify-center">
        <div class="w-full max-w-[300px] md:max-w-[220px]">
            <img class="objectImg no-event" alt="not found" src="/images/search_empty.png"/>
        </div>
        <div class="text-font-20 font-light text-mm-color-secondary">
            <slot name="title"></slot>
        </div>
        <div class="text-font-16 font-light text-mm-color-secondary">
            <slot name="sub"></slot>
        </div>
    </div>
</template>