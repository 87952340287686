import {createRouter, createWebHistory} from "vue-router";
// import Maintenance from "../views/Maintenance.vue";
import auth from "@/middleware/authMiddleware";

const routes = [
    {
        path: '/',
        name: 'landing',
        component: () => import('@/views/landing/main/Main.vue'),
    },
    {
        path: '/buy',
        name: 'mint',
        component: () => import('@/views/landing/mint/Mint.vue'),
    },
    {
        path: '/dgems',
        name: 'dgems',
        component: () => import('@/views/landing/DGems.vue'),
    },
    {
        path: '/summon',
        name: 'summon',
        component: () => import('@/views/landing/Summon.vue'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
    },
    {
        path: '/nft/:id',
        name: 'nft',
        component: () => import('@/views/market/SingleNFT.vue'),
    },
    {
        path: '/library',
        name: 'library',
        redirect: '/library/rarities',
        component: () => import('@/views/rarities/LibraryView.vue'),
        children: [
            {
                path: 'rarities',
                name: 'library.rarities',
                component: () => import('@/views/rarities/content/RarityView.vue'),

            },
            {
                path: 'summon',
                name: 'library.summon',
                component: () => import('@/views/rarities/content/SummonView.vue'),
            }
        ]
    },
    {
        path: '/play/:type?',
        name: 'playWrap',
        component: () => import('@/views/game/playWrap.vue'),
        children: [
            {
                path: '',
                name: 'playWrap.entryScene',
                component: () => import('@/views/game/lobby/EntryScene.vue'),
            },
            {
                path: 'store',
                name: 'playWrap.storeScene',
                component: () => import('@/views/game/store/StoreScene.vue'),
            },
            {
                path: 'summon',
                name: 'playWrap.summonScene',
                component: () => import('@/views/game/summon/SummonScene.vue'),
            },
            {
                path: 'items',
                name: 'playWrap.itemsListScene',
                component: () => import('@/views/game/ItemsListScene.vue'),
            },
            {
                path: 'heroes',
                name: 'playWrap.HeroesListScene',
                component: () => import('@/views/game/heroes/HeroesListScene.vue'),
            },
            {
                path: 'hero/:nft_id',
                name: 'playWrap.HeroSingleScene',
                component: () => import('@/views/game/HeroSingle/HeroSingleWrap.vue'),
            },
            {
                path: 'summon/:hash',
                name: 'playWrap.SummonCheck',
                component: () => import('@/views/game/summon/SummonCheck.vue'),
            },
        ]
    },
    {
        path: '/market',
        name: 'marketplace',
        redirect: '/market/chibis',
        component: () => import('@/views/market/Market.vue'),
        children: [
            {
                path: 'chibis',
                component: () => import('@/views/market/components/MarketChibidango.vue'),

            },
            {
                path: 'items',
                component: () => import('@/views/market/components/MarketItems.vue'),
            }
        ]
    },
    {
        path: '/inventory',
        name: 'inventory',
        redirect: '/inventory/chibis',
        component: () => import('@/views/inventory/Inventory.vue'),
        children: [
            {
                path: 'chibis',
                component: () => import('@/views/inventory/components/InventoryChibidango.vue'),
                meta: {
                    middleware: auth
                }
            },
            {
                path: 'items',
                component: () => import('@/views/inventory/components/InventoryItems.vue'),
                meta: {
                    middleware: auth
                }
            }
        ]
    },
    {
        path: '/connect',
        name: 'connect',
        component: () => import('@/views/connect/ConnectWallet.vue'),
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/profile/Profile.vue'),
        meta: {
            middleware: auth
        }
    },
    {
        path: '/leaderboard',
        name: 'leaderboard',
        component: () => import('@/views/leaderboard/Leaderboard.vue'),
        meta: {
            middleware: auth
        }
    },
    {
        path: '/rewards',
        name: 'rewards',
        component: () => import('@/views/rewards/Rewards.vue'),
        meta: {
            middleware: auth
        }
    }
    // { path: "/:catchAll(.*)", component: NotFound },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return {top: 0}
    },
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware});
    }

    return next();
});


export default router
