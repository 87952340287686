<template>
    <div class="pt-4">
        <div class="search-filter">
            <div class="-input-holder" v-on-click-outside='onClickOutsideHandler'>
                <input class="ui-input" type="text" v-on:focusin="openSearch" v-model="search"
                        placeholder="Search body parts and items">
                <div :class="`list-holder ${isOpen ? 'show' : ''}`">
                    <div class="-list">

                        <div class="-list-group">
                            <template v-for="(item, i) in groups" :key="i">
                                <div class="--group" :class="group === item.group ? '-active' : ''" v-on:click="setFilterGroup(item)">
                                    <Icon class="w-10" component="png-resize-icon" :icon="item.icon" folder="nft-race/bordered"/>
                                </div>
                            </template>
                        </div>

                        <div class="-list-content">
                            <FilterType v-for="(filterType, i) in attributeFilterTypes" :key="i" :type="name"
                                        :name="filterType" :items="filters.attributes[group][filterType]"/>
                            <FilterType v-for="(filterType, i) in itemFilterTypes" :key="i" :type="name"
                                        :name="filterType" :items="filters.items[group][filterType]"/>

                            <NotFoundResult v-if="isEmpty[group]">
                                <template v-slot:title>
                                    Nothing found
                                </template>
                            </NotFoundResult>
                        </div>
                    </div>
                </div>
            </div>
            <div class="-selected-list">
                <Item v-for="(item, i) in selectedParts" :type="name" :key="i" :item="item" :isDelete="true"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { vOnClickOutside } from '@vueuse/components';
import TypeIcon from "../../../../nft/type/TypeIcon.vue";
import {useStore} from "vuex";
import FilterType from "./FilterType.vue";
import Item from "./Item.vue";
import NotFoundResult from "../../../market/NotFoundResult.vue";
import {computed, onBeforeMount, ref, watch, watchEffect} from "vue";
import {useFilterStore} from "../../../../../store/useFilterStore";
import {groups} from "../../../../../entities/Filters";
import {isEmptyGroups} from "../../../../../helper";
import ApiService from "../../../../../service/ApiService";
import Icon from "@/components/v2/icons/Icon.vue";

const props = defineProps({
    name: {
        type: String,
        required: true
    }
})

const attributeFilterTypes = ref(['HAIRSTYLE', 'EYES', 'MOUTH'])
const itemFilterTypes = ref(['HAT', 'BODYSUIT', 'FACEMASK', 'RIGHTHAND', 'LEFTHAND', 'PET', 'BACKGROUND'])

const filterStore = useFilterStore(props.name)
const filters = computed(() => filterStore.getSearchPartsFilter)
const selectedParts = computed(() => filterStore.selectedParts)

const emmit = defineEmits<{
    (e: 'is-selected', value: boolean )
}>();

const search =  computed({
    get() {
        return filterStore.getSearchFilters
    },
    set(val) {
        filterStore.setSearchFilter(val)
    },
})

const isEmpty = ref({
    HUMAN: false,
    NIGHT_ELVES: false,
    MYTHIC: false,
    ORCS: false,
})

const isOpen = ref(false)

watch(() => filterStore.selectedParts, (test, test2) =>{
    if (isOpen.value) {
        isOpen.value = false
    }

    emmit('is-selected', test.length > 0)
}, {deep: true})

const onClickOutsideHandler = () => isOpen.value = false
const openSearch = () => isOpen.value = !isOpen.value

const group = computed(() => filterStore.getGroupFilters)

const setFilterGroup = (group) => {
    filterStore.setGroup(group.group)
}

watch(search, () => {
    fetchFilters()
})

const fetchFilters = () => {
    let query = ''
    if (search.value !== '') {
        query = '?search=' + search.value
    }

    ApiService.get('filters' + query).then(response => {
        isEmpty.value = isEmptyGroups(response)
        filterStore.setSearchPartsFilter(response)
    })
}

onBeforeMount(() => fetchFilters())



// export default {
//     props: {
//         isRecharge: {
//             type: Boolean,
//             default: false
//         },
//         isSummon: {
//             type: Boolean,
//             default: false
//         },
//         type: {
//             type: String,
//             default: () => ('market')
//         }
//     },
//     components: {NotFoundResult, TypeIcon, FilterType, Item},
//     directives: {
//         clickOutside: vClickOutside.directive
//     },
//   methods: {
//       openSearch() {
//         this.listShow = true
//         this.$emit('openSearch', true)
//       }
//   },
//     setup(props, { emit }) {
//         const store = useStore()
//         const search = ref('')
//         let listShow = ref(false)
//         let timer = null;
//
//         watchEffect(() => search.value = store.state.filters[props.type].search)
//
//         const onClickOutside = () => {
//           listShow.value = false
//           emit('openSearch', false)
//         }
//         const changeFilter = () => {
//             if (timer) {
//                 clearTimeout(timer);
//                 timer = null;
//             }
//             timer = setTimeout(() => {
//                 store.dispatch(props.type + '/updatePage', 1)
//                 store.dispatch('filters/searchGroupFilter', {type: props.type, search: search.value})
//             }, 200);
//         }
//
//         const setFilterGroup = (selectedGroup) => store.dispatch('filters/updateGroupFilter', {
//             type: props.type,
//             group: selectedGroup
//         })
//
//         return {
//             listShow,
//             search,
//             groups,
//             isEmpty: computed(() => store.state.filters[props.type].isEmpty),
//             group: computed(() => store.state.filters[props.type].group),
//             filters: computed(() => store.state.filters.filterParts),
//             selectedParts: computed(() => store.state.filters[props.type].selectedParts),
//             attributeFilterTypes: ['HAIRSTYLE', 'EYES', 'MOUTH'],
//             itemFilterTypes: ['HAT', 'BODYSUIT', 'FACEMASK', 'RIGHTHAND', 'LEFTHAND', 'PET', 'BACKGROUND'],
//             onClickOutside,
//             changeFilter,
//             setFilterGroup,
//         }
//     },
//     mounted() {
//         this.emitter.on("hideList", () => {
//             this.listShow = false;
//         });
//
//         this.emitter.on("clearFilters", () => {
//             this.search = ''
//         });
//     },
// }
</script>

<style lang="scss" scoped>

</style>
