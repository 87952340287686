<template>
    <ModalHeader>Halloween Chest</ModalHeader>
    <ModalBody>
        <div class="pb-6 w-full max-w-[200px] m-auto">
            <img class="objectImg no-event" alt="Halloween Chest" src="/images/illustrations/halloween-modal-min.png"/>
        </div>

        <div class="flex flex-col w-full gap-2">
            <p>Players can Claim up to 10 Halloween Chests with 1 transaction.</p>
            <p>To Claim Halloween Chests, a user needs to send 4 ADA.</p>
            <p>Halloween Chests are returned to the user with ADA minus the Fee amount of 1.5 ADA.</p>
        </div>
    </ModalBody>
</template>

<script lang="ts" setup>
import {onBeforeMount} from "vue";
import ModalHeader from "@/components/v2/modals/components/ModalHeader.vue";
import ModalBody from "@/components/v2/modals/components/ModalBody.vue";

const emmit = defineEmits<{
    (e: 'modalWidth', value: string )
}>();

onBeforeMount(() => {
    emmit("modalWidth", '580px')
})
</script>

<style lang="scss" scoped>

</style>
