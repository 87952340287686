<template>
    <router-view></router-view>
    <Modal/>
    <LeftWidget/>
    <RightWidget/>
</template>

<script lang="ts" setup>
import Modal from "@/components/v2/modals/Modal.vue";
import LeftWidget from "./components/v2/layout/LeftWidget.vue";
import RightWidget from "./components/v2/layout/RightWidget.vue";
import {onBeforeMount, onBeforeUnmount, onMounted} from "vue";
import {useWalletStore} from "@/store/useWalletStore";
import {useUserStore} from "@/store/useUserStore";
import {type WalletBalances} from "@/entities/Wallet";
import {connectWallet, getWalletBalance} from "@/service/WalletService";
import {defaultToast, ERROR, INFO} from "@/service/ToastService";
import {useI18n} from "vue-i18n";
import apiService from "@/service/ApiService";
import {useEmitter} from "@/useEmitter";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

const store = useStore()
const router = useRouter()

const emitter = useEmitter()
const walletStore = useWalletStore()
const userStore = useUserStore()
const { t } = useI18n()
let interval: any = null

async function setPriceCaller() {
    const usdPrice = await apiService.getPrice()
    walletStore.setUsdPrice(usdPrice.toString())
}

onMounted(async () => {
	let isClear = localStorage.getItem('isClear')
	if (isClear !== 'true') {
		localStorage.clear()
		localStorage.setItem('isClear', 'true')

		await store.dispatch('base/openWidget', {type: 'leftWidget', component: '', isOpen: false})
		await store.dispatch('wallet/logout')

		userStore.clear()
		walletStore.clear()
		await router.push({name: 'connect'})
	}
})

onBeforeMount(async () => {
	await setPriceCaller()

    if (interval === null) interval = setInterval(() => setPriceCaller(), 60000)

    if (userStore.getAuth) {
        walletStore.setLoadWallet(true)

        const addresses: string | string[] = await connectWallet(walletStore.getWalletApiName)
        const walletBalances: string | WalletBalances = await getWalletBalance(walletStore.getWalletApiName)

        if (typeof walletBalances === "string" || typeof addresses === "string") {
            const error = typeof addresses === "string" ? addresses : walletBalances

            defaultToast(ERROR, t(`toastify.${error}`))

            walletStore.setLoadWallet(false)
            return
        }

        walletStore.setWallet(addresses[0])
        walletStore.setAdaBalance(walletBalances.adaBalance)
        walletStore.setDgemBalance(walletBalances.dgemBalance)
        walletStore.setLoadWallet(false)
    }
})

onBeforeUnmount(() => {
    if (interval !== null) clearInterval(interval)
})
</script>

<style scoped>

</style>
