import {attributesRaces, checkboxes, groups, itemsRaces, perkCheckboxes} from "../../constants/filters";
import LocalStorageService from "../../service/LocalStorageService";
import {toRaw} from 'vue';
import {
    GAME_INVENTORY_CHECKBOXES,
    GAME_INVENTORY_GROUP, GAME_INVENTORY_PAGE,
    GAME_INVENTORY_PARTS,
    GAME_INVENTORY_PERK_CHECKBOXES, GAME_INVENTORY_SALE,
    GAME_INVENTORY_SEARCH, GAME_INVENTORY_SOLD,
    INVENTORY_CHECKBOXES,
    INVENTORY_GROUP,
    INVENTORY_PAGE,
    INVENTORY_PARTS,
    INVENTORY_PERK_CHECKBOXES,
    INVENTORY_SALE,
    INVENTORY_SEARCH,
    INVENTORY_SOLD,
    MARKET_CHECKBOXES,
    MARKET_GROUP,
    MARKET_PAGE,
    MARKET_PARTS,
    MARKET_PERK_CHECKBOXES,
    MARKET_SALE,
    MARKET_SEARCH,
    MARKET_SOLD
} from "../../constants/localstorageConstants";
import ApiService from "../../service/api/ApiService";
import {isEmptyGroups} from "../../helper";

const market = {
    selectedCheckboxes: LocalStorageService.get(MARKET_CHECKBOXES, {
        type: [],
        gender: [],
        skin: [],
    }),
    selectedPerk: LocalStorageService.get(MARKET_PERK_CHECKBOXES, []),
    group: LocalStorageService.get(MARKET_GROUP, 'HUMAN'),
    search: LocalStorageService.get(MARKET_SEARCH, ''),
    selectedParts: LocalStorageService.get(MARKET_PARTS, []),
    dropDown: {
        sale: LocalStorageService.get(MARKET_SALE, {name: 'For Sale', filter: 'market', selected: true}),
        sort: LocalStorageService.get(MARKET_SOLD, {
            name: 'Lowest price',
            filter: 'sell_price',
            order: 'asc',
            selected: true
        }),
    },
    isEmpty: {
        HUMAN: false,
        NIGHT_ELVES: false,
        MYTHIC: false,
        ORCS: false,
    }
}

const inventory = {
    selectedCheckboxes: LocalStorageService.get(INVENTORY_CHECKBOXES, {
        type: [],
        gender: [],
        skin: [],
    }),
    selectedPerk: LocalStorageService.get(INVENTORY_PERK_CHECKBOXES, []),
    group: LocalStorageService.get(INVENTORY_GROUP, 'HUMAN'),
    search: LocalStorageService.get(INVENTORY_SEARCH, ''),
    selectedParts: LocalStorageService.get(INVENTORY_PARTS, []),
    dropDown: {
        sale: LocalStorageService.get(INVENTORY_SALE, {name: 'All Chibis', filter: 'all', selected: true}),
        sort: LocalStorageService.get(INVENTORY_SOLD, {
            name: 'Lowest ID',
            filter: 'nft_id',
            order: 'asc',
            selected: true
        }),
    },
    isEmpty: {
        HUMAN: false,
        NIGHT_ELVES: false,
        MYTHIC: false,
        ORCS: false,
    }
}

const game = {
    selectedCheckboxes: LocalStorageService.get(GAME_INVENTORY_CHECKBOXES, {
        type: [],
        gender: [],
        skin: [],
    }),
    selectedPerk: LocalStorageService.get(GAME_INVENTORY_PERK_CHECKBOXES, []),
    group: LocalStorageService.get(GAME_INVENTORY_GROUP, 'HUMAN'),
    search: LocalStorageService.get(GAME_INVENTORY_SEARCH, ''),
    selectedParts: LocalStorageService.get(GAME_INVENTORY_PARTS, []),
    dropDown: {
        sale: LocalStorageService.get(GAME_INVENTORY_SALE, {name: 'All Chibis', filter: 'all', selected: true}),
        sort: LocalStorageService.get(GAME_INVENTORY_SOLD, {
            name: 'Lowest ID',
            filter: 'nft_id',
            order: 'asc',
            selected: true
        }),
    },
    isEmpty: {
        HUMAN: false,
        NIGHT_ELVES: false,
        MYTHIC: false,
        ORCS: false,
    }
}

const getDefaultState = () => ({
    type: 'MARKET',
    market,
    inventory,
    game,
    checkboxes: checkboxes,
    perkCheckboxes: perkCheckboxes,
    filterParts: {
        attributes: attributesRaces,
        items: itemsRaces,
    },
})

const getters = {
    getType: state => state.type,
    getCheckboxes: state => state.checkboxes,
    getPerkCheckboxes: state => state.perkCheckboxes,
    getFilterParts: state => state.filterParts,

    getMarketGroup: state => state.market.group,
    getInventoryGroup: state => state.inventory.group,
    getGameInventoryGroup: state => state.game.group,
    getMarketSelectedParts: state => state.market.selectedParts,
    getInventorySelectedParts: state => state.inventory.selectedParts,
    getGameInventorySelectedParts: state => state.game.selectedParts,
    getMarketSearch: state => state.market.search,
    getInventorySearch: state => state.inventory.search,
    getGameInventorySearch: state => state.game.search,
    getMarketSelectedCheckboxes: state => state.market.selectedCheckboxes,
    getMarketSelectedPerkCheckboxes: state => state.market.selectedPerk,
    getInventorySelectedCheckboxes: state => state.inventory.selectedCheckboxes,
    getGameInventorySelectedCheckboxes: state => state.game.selectedCheckboxes,
    getInventorySelectedPerkCheckboxes: state => state.inventory.selectedPerk,
    getGameInventorySelectedPerkCheckboxes: state => state.game.selectedPerk,
    getMarketDropDown: state => state.market.dropDown,
    getInventoryDropDown: state => state.inventory.dropDown,
}

const mutations = {
    setCheckbox(state, {type, data}) {
        state[type].selectedCheckboxes = data
    },
    setPerkCheckbox(state, {type, data}) {
        state[type].selectedPerk = data
    },
    reset(state, type) {
        state[type].selectedCheckboxes = {
            type: [],
            gender: [],
            skin: [],
        }

        state[type].selectedPerk = []
    },
    setGroupFilter(state, {type, data}) {
        state[type].group = data
    },
    updateFilterParts(state, data) {
        if (!Array.isArray(data.items)) {
            state.filterParts.items = {...itemsRaces, ...data.items}
        } else {
            state.filterParts.items = itemsRaces
        }

        if (!Array.isArray(data.attributes)) {
            state.filterParts.attributes = {...attributesRaces, ...data.attributes}
        } else {
            state.filterParts.attributes = attributesRaces
        }
    },
    setFilterPart(state, {type, part}) {
        state[type].selectedParts.push(part)

        state.filterParts[part.type + 's'][part.filter_group][part.slot][part.rarity][part.gender_specific] = [
            ...state.filterParts[part.type + 's'][part.filter_group][part.slot][part.rarity][part.gender_specific].filter(element => element.id !== part.id)
        ]
    },
    unsetFilterPart(state, {type, part}) {
        state.filterParts[part.type + 's'][part.filter_group][part.slot][part.rarity][part.gender_specific].push(part)
        state[type].selectedParts = state[type].selectedParts.filter(element => element.id !== part.id)
    },
    setSearch(state, {type, search}) {
        state[type].search = search
    },
    setDropdown(state, {pageType, type, data}) {
        state[pageType].dropDown[type] = data
    },
    updateEmpty(state, empty) {
        state.market.isEmpty = empty
    },
    clearSelectedPart(state, type) {
        state[type].selectedParts = []
    }
}

const actions = {
    updateCheckbox({commit, getters}, {type, checkboxes}) {
        let key

        if (type === 'market') key = MARKET_CHECKBOXES
        if (type === 'inventory') key = INVENTORY_CHECKBOXES
        if (type === 'game') key = GAME_INVENTORY_CHECKBOXES

        let rawObject = toRaw(checkboxes)
        LocalStorageService.set(key, rawObject)
        commit('setCheckbox', {type, data: rawObject})
    },
    updatePerkCheckbox({commit, getters}, {type, checkboxes}) {
        let key
        if (type === 'market') key = MARKET_PERK_CHECKBOXES
        if (type === 'inventory') key = INVENTORY_PERK_CHECKBOXES
        if (type === 'game') key = GAME_INVENTORY_PERK_CHECKBOXES

        let rawObject = toRaw(checkboxes)

        LocalStorageService.set(key, rawObject)
        commit('setPerkCheckbox', {type, data: rawObject})
    },
    clearFilters({commit, dispatch, getters}, type) {
        let keyCheckboxes, keyGroup, keySearch, keyParts, keyPage, keyPerks

        if (type === 'market') {
            keyCheckboxes = MARKET_CHECKBOXES
            keyGroup = MARKET_GROUP
            keySearch = MARKET_SEARCH
            keyParts = MARKET_PARTS
            keyPage = MARKET_PAGE
            keyPerks = MARKET_PERK_CHECKBOXES
        }

        if (type === 'inventory') {
            keyCheckboxes = INVENTORY_CHECKBOXES
            keyGroup = INVENTORY_GROUP
            keySearch = INVENTORY_SEARCH
            keyParts = INVENTORY_PARTS
            keyPage = INVENTORY_PAGE
            keyPerks = INVENTORY_PERK_CHECKBOXES
        }

        if (type === 'game') {
            keyCheckboxes = GAME_INVENTORY_CHECKBOXES
            keyGroup = GAME_INVENTORY_GROUP
            keySearch = GAME_INVENTORY_SEARCH
            keyParts = GAME_INVENTORY_PARTS
            keyPage = GAME_INVENTORY_PAGE
            keyPerks = GAME_INVENTORY_PERK_CHECKBOXES
        }

        LocalStorageService.remove(keyCheckboxes)
        LocalStorageService.remove(keyGroup)
        LocalStorageService.remove(keySearch)
        LocalStorageService.remove(keyParts)
        LocalStorageService.remove(keyPage)
        LocalStorageService.remove(keyPerks)

        commit('reset', type)
        commit('clearSelectedPart', type)
        dispatch('fetchFilters')
    },
    fetchFilters({commit, getters}, type) {
        let url = 'filters'
        let search = ''
        if (type === 'market') search = getters.getMarketSearch
        if (type === 'inventory') search = getters.getInventorySearch
        if (type === 'game') search = getters.getGameInventorySearch

        if (search !== '') {
            url += `?search=${search}`
        }

        ApiService.get(url).then(data => {
            commit('updateEmpty', isEmptyGroups(data))
            commit('updateFilterParts', data)
        })
    },
    updateGroupFilter({commit, getters}, {type, group}) {
        let key

        if (type === 'market') key = MARKET_GROUP
        if (type === 'inventory') key = INVENTORY_GROUP
        if (type === 'game') key = GAME_INVENTORY_GROUP

        LocalStorageService.set(key, group)
        commit('setGroupFilter', {type, data: group})
    },
    addFilterParts({commit, getters}, {type, part}) {
        let key

        if (type === 'market') key = MARKET_PARTS
        if (type === 'inventory') key = INVENTORY_PARTS
        if (type === 'game') key = GAME_INVENTORY_PARTS

        let data = LocalStorageService.get(key, [])

        data.push(toRaw(part))
        LocalStorageService.set(key, data)

        commit('setFilterPart', {type, part})
    },
    removeFilterParts({commit, getters}, {type, part}) {
        let key, parts

        if (type === 'market') {
            key = MARKET_PARTS
            parts = getters.getMarketSelectedParts
        }

        if (type === 'inventory') {
            key = INVENTORY_PARTS
            parts = getters.getInventorySelectedParts
        }

        if (type === 'game') {
            key = GAME_INVENTORY_PARTS
            parts = getters.getGameInventorySelectedParts
        }

        let data = parts.filter(element => element.id !== part.id)

        LocalStorageService.set(key, data)
        commit('unsetFilterPart', {type, part})
    },
    searchGroupFilter({commit, dispatch, getters}, {type, search}) {
        let key

        if (type === 'market') key = MARKET_SEARCH
        if (type === 'inventory') key = INVENTORY_SEARCH
        if (type === 'game') key = GAME_INVENTORY_SEARCH

        LocalStorageService.set(key, search)

        commit('setSearch', {type, search})
        dispatch('fetchFilters', type)
    },
    dropDown({commit}, {pageType, type, data}) {
        let key
        if (pageType === 'market') {
            if (type === 'sale') {
                key = MARKET_SALE
            } else {
                key = MARKET_SOLD
            }
        }

        if (pageType === 'inventory') {
            if (type === 'sale') {
                key = INVENTORY_SALE
            } else {
                key = INVENTORY_SOLD
            }
        }

        if (pageType === 'itemInventory') {
            if (type === 'sale') {
                key = INVENTORY_SALE
            } else {
                key = INVENTORY_SOLD
            }
        }

        LocalStorageService.set(key, data)
        commit('setDropdown', {pageType, type, data})
    }
}

export default {
    namespaced: true,
    state: () => getDefaultState(),
    getters,
    mutations,
    actions,
}
