<template>
    <div class="filter-section --filter-checkbox" v-for="(checkbox, i) in checkboxes" :key="i">
        <div class="-label">{{ checkbox.label }}</div>

        <div class="flex flex-wrap">
            <template v-for="(item, j) in checkbox.items" :key="j">
                <div class="w-1/2 mb-2">
                    <input :id="`type-${item.key}`" class="ui-checkbox" type="checkbox" :value="item.key"
                           v-on:change="filtersSelect" v-model="modelCheckbox[checkbox.key]" />
                    <label :for="`type-${item.key}`" class="!flex gap-2 items-center">
                        <span class="flex items-center gap-1">
                            <Icon class="w-4" component="png-resize-icon" :icon="item.key" folder="nft-type" v-if="checkbox.key === 'type'"/>
                            <span>{{ item.label }}</span>
                        </span>
                    </label>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {checkboxes} from "@/entities/Filters";
import {useFilterStore} from "@/store/useFilterStore";
import {computed, watchEffect} from "vue";
import Icon from "@/components/v2/icons/Icon.vue";

const props = defineProps({
    name: {
        type: String,
        required: true
    }
})

const emmit = defineEmits<{
    (e: 'is-selected', value: boolean )
}>();

const filterStore = useFilterStore(props.name)
const modelCheckbox = computed(() => filterStore.getCheckboxFilters)
const filtersSelect = () => filterStore.setCheckbox(modelCheckbox.value)

watchEffect(() => {
    let isSelectCheckBox = false
    Object.keys(modelCheckbox.value).forEach(checkbox => {
        if (!isSelectCheckBox && modelCheckbox.value[checkbox].length > 0) {
            isSelectCheckBox = true
        }
    })

    emmit('is-selected', isSelectCheckBox)
})

</script>

<style lang="scss" scoped>
//TODO: where is styles
</style>
