<template>
    <ModalHeader>Partner NFTs</ModalHeader>
    <ModalBody>
        <div class="flex flex-wrap gap-5 w-full">
            <ElementWrapper class="w-[160px]" v-for="(project, i) in stakingProjects" :key="i">
                <div class="w-full flex flex-col items-center gap-2 p-5">
                    <div class="w-[75px] h-[75px] rounded-xl bg-cover bg-center overflow-hidden bg-no-repeat" :style="`background-image: url('${project.image}')`"></div>
                    <div class="text-center text-font-14 font-semibold">
                        {{project.supply}} {{project.name}}
                    </div>
                </div>
            </ElementWrapper>
        </div>
    </ModalBody>
</template>

<script lang="ts" setup>
import {onBeforeMount, ref} from "vue";
import ModalHeader from "@/components/v2/modals/components/ModalHeader.vue";
import ModalBody from "@/components/v2/modals/components/ModalBody.vue";
import ElementWrapper from "@/views/profile/components/ElementWrapper.vue";
import ApiService from "@/service/ApiService";

const emmit = defineEmits<{
    (e: 'modalWidth', value: string )
}>();

const stakingProjects = ref([])

const getStakingProject = (() => {
	ApiService.get('staking/policies_data').then(response => {
		if (response.data) {
			stakingProjects.value = response.data
		}
	})
})

onBeforeMount(() => {
	getStakingProject()
    emmit("modalWidth", '570px')
})
</script>

<style lang="scss" scoped>

</style>
