import ApiService from "../../../service/api/ApiService";
import LocalStorageService from "../../../service/LocalStorageService";
import {concatUrl} from "../../../helper";
import {toRaw} from "vue";
import {
    INVENTORY_ATTRIBUTE_FILTER,
    INVENTORY_ITEMS_FILTER,
    INVENTORY_LIMIT,
    INVENTORY_PAGE,
    INVENTORY_PER_PAGE, INVENTORY_PERK_FILTER, INVENTORY_RACE_FILTER, INVENTORY_RARITY_FILTER,
    INVENTORY_SHOW_FILTERS,
    INVENTORY_VIEW, MARKET_ATTRIBUTE_FILTER,
    MARKET_ITEMS_FILTER,
    MARKET_PERK_FILTER,
    MARKET_RACE_FILTER,
    MARKET_RARITY_FILTER
} from "../../../constants/localstorageConstants";

export default {
    namespaced: true,
    state: () => ({
        data: [],
        total: 0,
        page: LocalStorageService.get(INVENTORY_PAGE, 1),
        showFilter: LocalStorageService.get(INVENTORY_SHOW_FILTERS, false),
        perPageSelected: LocalStorageService.get(INVENTORY_PER_PAGE, 20),
        view: LocalStorageService.get(INVENTORY_VIEW, 'card'),
        raceFilter: LocalStorageService.get(INVENTORY_RACE_FILTER, []),
        rarityFilter: LocalStorageService.get(INVENTORY_RARITY_FILTER, []),
        itemsFilter: LocalStorageService.get(INVENTORY_ITEMS_FILTER, []),
        perkFilter: LocalStorageService.get(INVENTORY_PERK_FILTER, []),
        attributeFilter: LocalStorageService.get(INVENTORY_ATTRIBUTE_FILTER, []),
    }),
    getters: {
        getPage: state => state.page,
        getInventory: state => ({ data: state.data, total: state.total}),
        getShowFilters: state => state.showFilter,
        getPerPage: state => state.perPageSelected,
        getView: state => state.view,
        getSelectedItemsFilter: state => state.itemsFilter,
        getSelectedPerkFilter: state => state.perkFilter,
        getSelectedAttributeFilter: state => state.attributeFilter,
        getSelectedRaceFilter: state => state.raceFilter,
        getSelectedRarityFilter: state => state.rarityFilter,
    },
    mutations: {
        setView(state, view) {
          if (typeof view === "undefined") {
            state.view = 'card'
          } else {
            state.view = view
          }
        },
        setPerPage(state, perPage) {
            state.perPageSelected = perPage
        },
        setPage(state, page) {
            state.page = page
        },
        setInventory(state, inventory) {
            state.data = inventory.data
            state.total = inventory.total
        },
        setLimit(state, limit) {
            state.limit = limit
        },
        setShowFilters(state) {
            state.showFilter = !state.showFilter
        },
        setRaceFilter(state, filter) {
            let index = state.raceFilter.indexOf(filter)
            if (index === -1) {
                state.raceFilter.push(filter)
            } else {
                state.raceFilter = state.raceFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setRarityFilter(state, filter) {
            let index = state.rarityFilter.indexOf(filter)
            if (index === -1) {
                state.rarityFilter.push(filter)
            } else {
                state.rarityFilter = state.rarityFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setItemsFilter(state, filter) {
            let index = state.itemsFilter.indexOf(filter)
            if (index === -1) {
                state.itemsFilter.push(filter)
            } else {
                state.itemsFilter = state.itemsFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setPerkFilter(state, filter) {
            let index = state.perkFilter.indexOf(filter)
            if (index === -1) {
                state.perkFilter.push(filter)
            } else {
                state.perkFilter = state.perkFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setAttributeFilter(state, filter) {
            let index = state.attributeFilter.indexOf(filter)
            if (index === -1) {
                state.attributeFilter.push(filter)
            } else {
                state.attributeFilter = state.attributeFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setEmptyFilter(state) {
            state.attributeFilter = []
            state.perkFilter = []
            state.itemsFilter = []
            state.rarityFilter = []
            state.raceFilter = []
        }
    },
    actions: {
        updateAttributeFilter({commit, getters}, filter) {
            commit('setAttributeFilter', filter)
            LocalStorageService.set(INVENTORY_ATTRIBUTE_FILTER, getters['getSelectedAttributeFilter'])
        },
        updateRarityFilter({commit, getters}, filter) {
            commit('setRarityFilter', filter)
            LocalStorageService.set(INVENTORY_RARITY_FILTER, getters['getSelectedRarityFilter'])
        },
        updateRaceFilter({commit, getters}, filter) {
            commit('setRaceFilter', filter)
            LocalStorageService.set(INVENTORY_RACE_FILTER, getters['getSelectedRaceFilter'])
        },
        updatePerkFilter({commit, getters}, filter) {
            commit('setPerkFilter', filter)
            LocalStorageService.set(INVENTORY_PERK_FILTER, getters['getSelectedPerkFilter'])
        },
        updateView({commit, getters}, view) {
            commit('setView', view)
            LocalStorageService.set(INVENTORY_VIEW, getters.getView)
        },
        updatePerPage({commit, getters}, perPage) {
            commit('setPerPage', perPage)
            LocalStorageService.set(INVENTORY_PER_PAGE, getters.getPerPage)
        },
        updateShowFilters({commit, getters}) {
            commit('setShowFilters')
            LocalStorageService.set(INVENTORY_SHOW_FILTERS, getters.getShowFilters)
        },
        updatePage({commit, dispatch}, page) {
            LocalStorageService.set(INVENTORY_PAGE, page)
            commit('setPage', page)
        },
        updateLimit({commit}, limit) {
            commit('setLimit', limit)
        },
        updateItemsFilter({commit, getters}, filter) {
            commit('setItemsFilter', filter)
            LocalStorageService.set(INVENTORY_ITEMS_FILTER, getters['getSelectedItemsFilter'])
        },
        clearFilters({commit}) {
            commit('setEmptyFilter')
            LocalStorageService.set(INVENTORY_ITEMS_FILTER, [])
            LocalStorageService.set(INVENTORY_PERK_FILTER, [])
            LocalStorageService.set(INVENTORY_RACE_FILTER, [])
            LocalStorageService.set(INVENTORY_RARITY_FILTER, [])
            LocalStorageService.set(INVENTORY_ATTRIBUTE_FILTER, [])
        },
        fetchInventory({ commit, rootGetters, getters }, emitter) {
            emitter.emit('isLoadInventory', true)
            commit('setInventory', {data: [], total: 0})
            let checkboxes = rootGetters['filters/getInventorySelectedCheckboxes']
            let checkboxesPerk = rootGetters['filters/getInventorySelectedPerkCheckboxes']
            let parts = rootGetters['filters/getInventorySelectedParts']
            let dropdown = toRaw(rootGetters['filters/getInventoryDropDown'])
            let limit = getters['getPerPage']
            let raceFilters = getters['getSelectedRaceFilter']
            let rarityFilters = getters['getSelectedRarityFilter']
            let itemsFilters = getters['getSelectedItemsFilter']
            let perkFilters = getters['getSelectedPerkFilter']
            let attributeFilters = getters['getSelectedAttributeFilter']

            let sendParts = {
                attribute: [],
                item: [],
            }

            let filters = {
                race: [],
                rarity: [],
                slot: [],
                perk: [],
                attribute: [],
            }

            raceFilters.map(item => {
                filters.race.push(item.filter)
            })

            rarityFilters.map(item => {
                filters.rarity.push(item.filter)
            })

            itemsFilters.map(item => {
                filters.slot.push(item.filter)
            })

            attributeFilters.map(item => {
                filters.attribute.push(item.filter)
            })

            perkFilters.map(item => {
                filters.perk.push(item.filter)
            })

            parts.map(item => {
                sendParts[item.type].push(item.id)
            })

            let url = 'inventory'

            url = concatUrl(url, 'rarity', filters.rarity)
            url = concatUrl(url, 'race', filters.race)
            url = concatUrl(url, 'slot', filters.slot)
            url = concatUrl(url, 'attribute_slot', filters.attribute)
            url = concatUrl(url, 'perk', filters.perk)

            url = concatUrl(url, 'gender', toRaw(checkboxes.gender))
            url = concatUrl(url, 'skin', toRaw(checkboxes.skin))
            url = concatUrl(url, 'type', toRaw(checkboxes.type))
            url = concatUrl(url, 'attribute', sendParts.attribute)
            url = concatUrl(url, 'checkboxes_perk', checkboxesPerk)
            url = concatUrl(url, 'item', sendParts.item)
            url = concatUrl(url, 'page', getters.getPage)
            url = concatUrl(url, 'limit', limit)

            if (Object.values(dropdown.sale).length > 0) {
                url = concatUrl(url, 'sortType', dropdown.sale)
            }

            if (Object.values(dropdown.sort).length > 0) {
                url = concatUrl(url, 'order', dropdown.sort)
            }

            ApiService.get(url).then(market => {
                commit('setInventory', market)
                emitter.emit('isLoadInventory', false)
            })
        }
    },
}
