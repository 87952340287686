import * as S from "@emurgo/cardano-serialization-lib-browser"
import axios from "axios"

export const initParams = async () => {
    let latest_block = await axios.get('https://cardano-mainnet.blockfrost.io/api/v0/blocks/latest', {
        headers: {
            'project_id': 'mainnet6fCtAlvhyhdnkLgW1Yzs6LQoVu3xC18S'
        }
    })

    let p = await axios.get(`https://cardano-mainnet.blockfrost.io/api/v0/epochs/${latest_block.data.epoch}/parameters`, {
        headers: {
            'project_id': 'mainnet6fCtAlvhyhdnkLgW1Yzs6LQoVu3xC18S'
        }
    })

    return {
        linearFee: {
            minFeeA: p.data.min_fee_a.toString(),
            minFeeB: p.data.min_fee_b.toString(),
        },
        minUtxo: p.min_utxo, //p.min_utxo, minUTxOValue protocol paramter has been removed since Alonzo HF. Calulation of minADA works differently now, but 1 minADA still sufficient for now
        poolDeposit: p.data.pool_deposit,
        keyDeposit: p.data.key_deposit,
        coinsPerUtxoWord: p.data.coins_per_utxo_word,
        coinsPerUtxoSize: p.data.coins_per_utxo_size,
        maxValSize: p.data.max_val_size,
        priceMem: p.data.price_mem,
        priceStep: p.data.price_step,
        maxTxSize: parseInt(p.data.max_tx_size),
        slot: parseInt(latest_block.data.slot),
    }
}

export const getUtxosAssets = (rawUtxos) => {
  let assetsUtxos = []

  try {
    for (const rawUtxo of rawUtxos) {
      let utxo = S.TransactionUnspentOutput.from_bytes(Buffer.from(rawUtxo, "hex"));
      let output = utxo.output();
      let multiasset = output.amount().multiasset();

      if (multiasset) {
        let keys = multiasset.keys() // policy Ids of thee multiasset
        let N = keys.len();

        for (let i = 0; i < N; i++) {
          let policyId = keys.get(i);
          let policyIdHex = Buffer.from(policyId.to_bytes(), "utf8").toString("hex");
          let assets = multiasset.get(policyId)
          let assetNames = assets.keys();
          let K = assetNames.len()

          for (let j = 0; j < K; j++) {
            let assetName = assetNames.get(j);
            let assetNameHex = Buffer.from(assetName.name(),"utf8").toString("hex")
            let asset = policyIdHex + assetNameHex

            assetsUtxos.push(asset)
          }
        }
      }
    }

    return assetsUtxos
  } catch (err) {
    console.log(err)
  }
}

export const getUtxos = async (rawUtxos) => {

    let Utxos = [];

    try {
        for (const rawUtxo of rawUtxos) {
            const utxo = S.TransactionUnspentOutput.from_bytes(Buffer.from(rawUtxo, "hex"));
            const input = utxo.input();
            const txid = Buffer.from(input.transaction_id().to_bytes(), "utf8").toString("hex");
            const txindx = input.index();
            const output = utxo.output();
            const amount = output.amount().coin().to_str(); // ADA amount in lovelace
            const multiasset = output.amount().multiasset();
            let multiAssetStr = "";
            let preparedAssets = [];

            if (multiasset) {
                const keys = multiasset.keys() // policy Ids of thee multiasset
                const N = keys.len();
                // console.log(`${N} Multiassets in the UTXO`)


                for (let i = 0; i < N; i++){
                    const policyId = keys.get(i);
                    const policyIdHex = Buffer.from(policyId.to_bytes(), "utf8").toString("hex");
                    // console.log(`policyId: ${policyIdHex}`)
                    const assets = multiasset.get(policyId)
                    const assetNames = assets.keys();
                    const K = assetNames.len()
                    // console.log(`${K} Assets in the Multiasset`)

                    for (let j = 0; j < K; j++) {
                        const assetName = assetNames.get(j);
                        const assetNameString = Buffer.from(assetName.name(),"utf8").toString();
                        const assetNameHex = Buffer.from(assetName.name(),"utf8").toString("hex")
                        const multiassetAmt = multiasset.get_asset(policyId, assetName)
                        multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`
                        preparedAssets.push({
                            unit: policyIdHex+assetNameHex,
                            policyId: policyIdHex,
                            name: assetNameString,
                            amount: multiassetAmt.to_str()
                        })

                        // console.log(assetNameString)
                        // console.log(`Asset Name: ${assetNameHex}`)
                    }
                }
            }


            const obj = {
                txid: txid,
                txindx: txindx,
                amount: amount,
                str: `${txid} #${txindx} = ${amount}`,
                multiAssetStr: multiAssetStr,
                assets: preparedAssets,
                TransactionUnspentOutput: utxo
            }
            Utxos.push(obj);
            // console.log(`utxo: ${str}`)
        }

        return Utxos
    } catch (err) {
        console.log(err)
    }
}

export const initTransactionBuilder = async (params) => {
    return S.TransactionBuilder.new(
        S.TransactionBuilderConfigBuilder.new()
            .fee_algo(
                S.LinearFee.new(
                    S.BigNum.from_str(params.linearFee.minFeeA),
                    S.BigNum.from_str(params.linearFee.minFeeB)
                )
            )
            .pool_deposit(S.BigNum.from_str(params.poolDeposit))
            .key_deposit(S.BigNum.from_str(params.keyDeposit))
            .coins_per_utxo_byte(S.BigNum.from_str(params.coinsPerUtxoSize))
            .max_value_size(params.maxValSize)
            .max_tx_size(params.maxTxSize)
            .prefer_pure_change(true)
            .build()
    )
}

export const getTxUnspentOutputs = async (rawUtxos) => {
    let txOutputs = S.TransactionUnspentOutputs.new()
    let utxos = await getUtxos(rawUtxos)

    for (const utxo of utxos) {
        txOutputs.add(utxo.TransactionUnspentOutput)
    }

    return txOutputs
}
