<template>
    <div class="item-box" :data-rarity="item.rarity !== '' ? item.rarity : ''" :style="cssVars">
        <div class="item-image ui-loading">
            <img v-if="item.image_name !== ''" class="objectImg no-event" :alt="item.image_name" :src="`/images/slots/${item.slot.toLocaleLowerCase()}/${item.image_name}.png`" onload="this.parentElement.classList.remove('ui-loading')"/>
            <img v-else-if="item.slot !== ''" class="objectImg no-event" alt="" :src="`/images/slots/${item.slot.toLocaleLowerCase()}/g-default.png`" onload="this.parentElement.classList.remove('ui-loading')"/>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {computed, PropType} from "vue";
import {Item} from "@/entities/Item";

const props = defineProps({
    item: {
        type: Object as PropType<Item>,
        required: true,
        default() {
            return {}
        }
    },
    style: {
        type: Object,
        required: true,
        default() {
            return {
                size: '',
                margin: '',
            }
        }
    },
})

const cssVars = computed(function setupStyle() {
    return {
        '--size': props.style.size + 'px',
        '--margin': props.style.margin + 'px',
        '--border': parseInt(props.style.size) * 0.02 + 'px',
        '--radius': parseInt(props.style.size) * 0.166 + 'px',
    }
})
</script>

<style lang="scss" scoped>

.item-box {
    position: relative;
    min-height: var(--size);
    min-width: var(--size);
    max-height: var(--size);
    max-width: var(--size);

    margin: var(--margin);

    border: var(--border) solid transparent;
    border-radius: var(--radius);
    overflow: hidden;

    &[data-rarity=''], &[data-rarity='NONE'] {
        background: var(--rarity-gradient-none);
        border-color: var(--rarity-color-none);
    }
    &[data-rarity='COMMON'] {
        background: var(--rarity-gradient-common);
        border-color: var(--rarity-color-common);
    }
    &[data-rarity='RARE'] {
        background: var(--rarity-gradient-rare);
        border-color: var(--rarity-color-rare);
    }
    &[data-rarity='EPIC'] {
        background: var(--rarity-gradient-epic);
        border-color: var(--rarity-color-epic);
    }
    &[data-rarity='LEGENDARY'] {
        background: var(--rarity-gradient-legendary);
        border-color: var(--rarity-color-legendary);
    }
    &[data-rarity='IMMORTAL'] {
        background: var(--rarity-gradient-immortal);
        border-color: var(--rarity-color-immortal);
    }

    .item-image.ui-loading {
        img {
            opacity: 0;
        }
    }

    .ui-loading:after {
        height: calc(var(--size) / 3);
        width: calc(var(--size) / 3);
        margin-top: calc(var(--size) / 3 * -0.5) !important;
        margin-left: calc(var(--size) / 3 * -0.5) !important;
    }
}
</style>
