<template>
    <div class="left-screen-overlay" :class="isOpen ? 'opened' : ''" ref="overlay">
        <div class="left-screen-container" v-click-outside="handleClickOutside">
            <div class="top-content">
                <div class="left-screen-close" v-on:click="handleClose"><i class="fal fa-long-arrow-left"></i></div>
            </div>
            <div class="mid-content">
                <component v-if="component !== ''" v-bind:is="component"></component>
            </div>
        </div>
    </div>
</template>

<script>
//TODO: refactor on TS
import {computed, ref, watchEffect} from "vue";
import {useStore} from "vuex";
import {LEFT_WIDGET} from "../../../constants/baseConstants";
import vClickOutside from "click-outside-vue3";
import mobileMarketMenu from "@/components/v2/layout/header/components/mobileMarketMenu.vue";

export default {
    name: "LeftWidget",
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: { mobileMarketMenu },
    setup() {
        const store = useStore()
        const overlay = ref(null)
        const handleClose = () => store.dispatch('base/openWidget', {type: LEFT_WIDGET, component: '', isOpen: false})
        const handleClickOutside = (event) => {
            if (event.target === overlay.value) {
                store.dispatch('base/openWidget', {type: LEFT_WIDGET, component: '', isOpen: false})
            }
        }

        watchEffect(() => {
          if (store.state.base.isOpenLeftWidget) {
            document.body.classList.add('no-scroll')
          } else {
            document.body.classList.remove('no-scroll')
          }
        })

        return {
            isOpen: computed(() => store.state.base.isOpenLeftWidget),
            component: computed(() => store.state.base.leftWidgetComponent),
            handleClickOutside,
            handleClose,
            overlay,
        }
    }
}
</script>

<style lang="scss" scoped>

.left-screen-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease 0s, transform 0s ease 0.3s;
    transform: translate3d(-100%, 0px, 0px);
    background: rgba(35, 35, 39, 0.8);

    &.opened {
        opacity: 1;
        transition: opacity 0.3s ease 0s;
        transform: none;

        .left-screen-container {
            transform: none;
        }
    }

    .left-screen-container {
        z-index: 1100;
        position: relative;
        transition: all 0.5s ease 0s;
        transform: translate3d(-100%, 0px, 0px);
        background: var(--mm-color-dark2);
        border-right: 1px solid var(--mm-color-out1);
        width: 100%;
        max-width: 300px;
        height: 100%;
    }
}

.top-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    text-align: right;
    background: var(--mm-color-dark2);
    border-bottom: 1px solid var(--mm-color-out1);
}

.mid-content {
    padding-top: 60px;
    min-height: calc(100vh - 60px);
    overflow-y: auto;
    height: 100%;
}

.left-screen-close {
    //position: absolute;
    top: 0px;
    right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;
    font-size: 22px;
    color: #7E8AC0;
    cursor: pointer;
}

//////////////////////////
//.left-screen-container {
//    display: flex;
//    flex-direction: column;
//    justify-content: space-between;
//}
</style>
