<template>
<div class="px-3 py-3 border-b border-mm-color-dark2 flex items-center justify-between">
    <div class="text-font-18 px-3">
        <slot/>
    </div>
    <div class="w-10 h-10 flex-center cursor-pointer" v-on:click="closeModal">
        <i class="fal fa-times text-font-18"></i>
    </div>
</div>
</template>

<script setup lang="ts">
import {useEmitter} from "@/useEmitter";

const emitter = useEmitter()
const closeModal = () => {
    emitter.emit('openModal', {isOpen: false, componentModal: ''})
}
</script>

<style scoped lang="scss">

</style>