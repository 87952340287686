<template>
  <transition name="modal">
    <div class="ui-modal-overlay" v-if="openModal.isOpen">
        <div class="ui-modal-holder">
            <div class="ui-modal-box" :style="`max-width: ${modalWidth}`">
                <component :is="modal" :data="openModal.data" @modal-width="(v) => modalWidth = v"/>
            </div>
        </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import {markRaw, onMounted, ref} from "vue";
import {useEmitter} from "@/useEmitter";
import SellModal from "@/components/v2/modals/content/SellModal.vue";
import UpdatePriceModal from "@/components/v2/modals/content/UpdatePriceModal.vue";
import InfoDgemsModal from "@/components/v2/modals/content/profile/infoDgemsModal.vue";
import InfoHalloweenModal from "@/components/v2/modals/content/profile/infoHalloweenModal.vue";
import InfoAdaRewardsModal from "@/components/v2/modals/content/profile/infoAdaRewardsModal.vue";
import InfoStakingRewardsModal from "@/components/v2/modals/content/profile/infoStakingRewardsModal.vue";
import infoStakingPolicies from "@/components/v2/modals/content/profile/infoStakingPolicies.vue";

const lookup = {
    //market
    SellModal,
    UpdatePriceModal,

    //info
    InfoDgemsModal,
    InfoHalloweenModal,
    InfoAdaRewardsModal,
    InfoStakingRewardsModal,
    infoStakingPolicies
}

const modal = ref(null)
const modalWidth = ref(null)
const emitter = useEmitter()

const openModal = ref({
    isOpen: false,
    componentModal: '',
    data: {}
})

onMounted(() => {
    emitter.on("openModal", data => {

        console.log(data)

        openModal.value = data
//TODO: fix modal data

        if (data.componentModal === '') {
            modal.value = null
        } else {
            modal.value = markRaw(lookup[data.componentModal])
        }
    })
})
</script>

<style lang="scss" scoped>
.modal-enter-active, .modal-leave-active {opacity: 0;}
.modal-enter .ui-modal-container, .modal-leave-active .ui-modal-container {-webkit-transform: scale(1.1);transform: scale(1.1);}

.ui-modal-overlay {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(41, 45, 63, 0.5);
    transition: all 0.1s;

    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.ui-modal-holder {
    width: 100%;
    height: 100%;
    padding: 8px;

    display: flex;
    margin-top: 15vh;
}

.ui-modal-box {
    position: relative;
    width: 100%;
    height: min-content;
    margin: 0 auto;

    background: var(--mm-color-dark1);
    border: 1px solid var(--mm-color-out1);
    border-radius: 6px;
}
</style>
