<template>
    <div class="-item" :rarity="item.rarity">
        <MItemSingleIcon :style="resizeSettings.items" :item="item"/>
        <div class="--info">
            <div class="--name">{{ item.name }}</div>
            <div class="--rarity">{{ item.rarity }}</div>
        </div>
        <div class="-del" v-if="isDelete" v-on:click="deleteItem(item)"><i class="fas fa-times"></i></div>
    </div>
</template>

<script setup lang="ts">
import {ref} from "vue";
import {useFilterStore} from "../../../../../store/useFilterStore";
import MItemSingleIcon from "@/components/market/chibidango/mItemSingleIcon.vue";

const resizeSettings = ref({
    items: {
        size: '32',
            margin: '0'
    }
})

const props = defineProps({
    item: {
        type: Object,
        default: () => ({})
    },
    isDelete: {
        type: Boolean,
        default: () => false
    },
    type: {
        type: String,
        // required: true
    }
})

const filterStore = useFilterStore(props.type)

const deleteItem = (item) => {
    filterStore.removeFilterParts(item)
}

// export default {
//     name: "Item",
//     components: {MItemSingleIcon},
//     data: () => ({
//         timer: null,
//         resizeSettings: {
//             items: {
//                 size: '32',
//                 margin: '0'
//             }
//         },
//     }),
//     props: {
//         item: {
//             type: Object,
//             default: () => ({})
//         },
//         isDelete: {
//             type: Boolean,
//             default: () => false
//         },
//         type: {
//             type: String,
//             default: () => ('market')
//         }
//     },
//     methods: {
//         deleteItem(item) {
//             this.$store.dispatch('filters/removeFilterParts', {type: this.type, part: item})
//
//             if (this.timer) {
//                 clearTimeout(this.timer);
//                 this.timer = null;
//             }
//
//             this.timer = setTimeout(() => {
//                 if (this.type === 'market') {
//                     this.$store.dispatch('market/fetchMarket', this.emitter)
//                 }
//
//                 if (this.type === 'inventory') {
//                     this.$store.dispatch('inventory/fetchInventory', this.emitter)
//                 }
//
//                 if (this.type === 'game') {
//                     this.$store.dispatch('game/fetchInventory')
//                 }
//             }, 600);
//         },
//     }
// }
</script>

<style lang="scss" scoped>
.-item {
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
        background: #292D3F;
    }

    .--rarity {
        font-size: 8px;
        line-height: 9px;
        text-transform: uppercase;
        color: #AAAAAA;
    }

    &[rarity='COMMON'] {
        .--rarity {color: #BCEFB5;}
    }
    &[rarity='RARE'] {
        .--rarity {color: #69B2FA;}
    }
    &[rarity='EPIC'] {
        .--rarity {color: #C27FF0;}
    }
    &[rarity='LEGENDARY'] {
        .--rarity {color: #F6AC61;}
    }
    &[rarity='IMMORTAL'] {
        .--rarity {color: #FF8989;}
    }


    .--info {
        flex: 1 1;
        margin-left: 8px;
    }

    .--name {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #ffffff;
        padding-bottom: 2px;
        text-transform: capitalize;
    }

    .-del {
        position: absolute;
        height: 100%;
        width: 20px;
        margin-top: 1px;

        display: flex;
        justify-content: right;
        align-items: inherit;
        color: #ED7070;
        top: 0px;
        right: 2px;
    }
}
</style>
