<template>
    <div class="flex flex-wrap gap-2 pt-3 pb-1">
        <div v-for="(item, i) in filterRace" :key="i" :class="`filter-item-tag ${isSelectedFilter(item) ? '-selected' : ''}`" v-on:click="selectFilter(item)">
            <Icon class="w-[14px]" component="png-resize-icon" :icon="item.icon" folder="nft-race/normal"/>
            <span>{{item.name}}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {filterRace, FiltersType} from "../../../../entities/Filters";
import {useFilterStore} from "../../../../store/useFilterStore";
import {computed, watch} from "vue";
import Icon from "@/components/v2/icons/Icon.vue";

const props = defineProps({
    name: {
        type: String,
        required: true
    }
})

const emmit = defineEmits<{
    (e: 'is-selected', value: boolean )
}>();

const filterStore = useFilterStore(props.name)
const raceFilters = computed(() => filterStore.getRaceFilters)
const isSelectedFilter = (filter: FiltersType) => filterStore.getRaceFilters.find(item => item.filter === filter.filter) !== undefined
const selectFilter = (item: FiltersType) => filterStore.setRaceFilter(item)

watch(raceFilters,(check) => {
    emmit('is-selected', check.length > 0)
}, {deep: true})
</script>

<style lang="scss" scoped>
.filter-item-tag {
    height: 24px;
    padding: 0 12px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    background: var(--mm-color-dark3);
    font-size: 12px;
    cursor: pointer;

    &:hover { background: var(--mm-color-secondary); }
    &.-selected { background: var(--mm-color-primary);}
}
</style>
