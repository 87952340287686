import ApiService from "../../../service/api/ApiService";
import {toRaw} from "vue";
import {concatUrl, errorToast} from "../../../helper";
import LocalStorageService from "../../../service/LocalStorageService";
import {
    ITEM_INVENTORY_ITEMS_FILTER,
    ITEM_INVENTORY_RACE_FILTER, ITEM_INVENTORY_RARITY_FILTER, MARKET_ATTRIBUTE_FILTER, MARKET_ITEMS_FILTER,
    MARKET_PAGE,
    MARKET_PER_PAGE, MARKET_PERK_FILTER, MARKET_RACE_FILTER, MARKET_RARITY_FILTER, MARKET_SHOW_FILTERS,
    MARKET_VIEW
} from "../../../constants/localstorageConstants";

const getDefaultState = () => {
    return {
        data: [],
        total: 0,
        page: LocalStorageService.get(MARKET_PAGE, 1),
        perPageSelected: LocalStorageService.get(MARKET_PER_PAGE, 20),
        view: LocalStorageService.get(MARKET_VIEW, 'card'),
        showFilter: LocalStorageService.get(MARKET_SHOW_FILTERS, false),
        raceFilter: LocalStorageService.get(MARKET_RACE_FILTER, []),
        rarityFilter: LocalStorageService.get(MARKET_RARITY_FILTER, []),
        itemsFilter: LocalStorageService.get(MARKET_ITEMS_FILTER, []),
        perkFilter: LocalStorageService.get(MARKET_PERK_FILTER, []),
        attributeFilter: LocalStorageService.get(MARKET_ATTRIBUTE_FILTER, []),
        search: ''
    }
}

export default {
    namespaced: true,
    state: () => getDefaultState(),
    getters: {
        getMarket: state => ({data: state.data, total: state.total}),
        getPage: state => state.page,
        getPerPage: state => state.perPageSelected,
        getView: state => state.view,
        getShowFilters: state => state.showFilter,
        getSelectedItemsFilter: state => state.itemsFilter,
        getSelectedPerkFilter: state => state.perkFilter,
        getSelectedAttributeFilter: state => state.attributeFilter,
        getSelectedRaceFilter: state => state.raceFilter,
        getSelectedRarityFilter: state => state.rarityFilter,
        getSearch: state => state.search,
    },
    mutations: {
        setShowFilters(state) {
            state.showFilter = !state.showFilter
        },
        setMarket(state, market) {
            if (typeof market.data === "undefined") {
                errorToast('Api response error', "Can`t get data from resource, please try again")

                state.data = []
                state.total = 0
                return
            }

            state.data = market.data
            state.total = market.total
        },
        setPage(state, page) {
            state.page = page
        },
        setView(state, view) {
            if (typeof view === "undefined") {
                state.view = 'card'
            } else {
                state.view = view
            }
        },
        setPerPage(state, perPage) {
            state.perPageSelected = perPage
        },
        setRaceFilter(state, filter) {
            let index = state.raceFilter.indexOf(filter)
            if (index === -1) {
                state.raceFilter.push(filter)
            } else {
                state.raceFilter = state.raceFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setRarityFilter(state, filter) {
            let index = state.rarityFilter.indexOf(filter)
            if (index === -1) {
                state.rarityFilter.push(filter)
            } else {
                state.rarityFilter = state.rarityFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setItemsFilter(state, filter) {
            let index = state.itemsFilter.indexOf(filter)
            if (index === -1) {
                state.itemsFilter.push(filter)
            } else {
                state.itemsFilter = state.itemsFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setPerkFilter(state, filter) {
            let index = state.perkFilter.indexOf(filter)
            if (index === -1) {
                state.perkFilter.push(filter)
            } else {
                state.perkFilter = state.perkFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setAttributeFilter(state, filter) {
            let index = state.attributeFilter.indexOf(filter)
            if (index === -1) {
                state.attributeFilter.push(filter)
            } else {
                state.attributeFilter = state.attributeFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setEmptyFilter(state) {
            state.attributeFilter = []
            state.perkFilter = []
            state.itemsFilter = []
            state.rarityFilter = []
            state.raceFilter = []
            state.search = ''
        },
        setSearch(state, search) {
            state.search = search
        }
    },
    actions: {
        updateAttributeFilter({commit, getters}, filter) {
            commit('setAttributeFilter', filter)
            LocalStorageService.set(MARKET_ATTRIBUTE_FILTER, getters['getSelectedAttributeFilter'])
        },
        updateRarityFilter({commit, getters}, filter) {
            commit('setRarityFilter', filter)
            LocalStorageService.set(MARKET_RARITY_FILTER, getters['getSelectedRarityFilter'])
        },
        updateRaceFilter({commit, getters}, filter) {
            commit('setRaceFilter', filter)
            LocalStorageService.set(MARKET_RACE_FILTER, getters['getSelectedRaceFilter'])
        },
        updatePerkFilter({commit, getters}, filter) {
            commit('setPerkFilter', filter)
            LocalStorageService.set(MARKET_PERK_FILTER, getters['getSelectedPerkFilter'])
        },
        updateShowFilters({commit, getters}) {
            commit('setShowFilters')
            LocalStorageService.set(MARKET_SHOW_FILTERS, getters.getShowFilters)
        },
        updateView({commit, getters}, view) {
            commit('setView', view)
            LocalStorageService.set(MARKET_VIEW, getters.getView)
        },
        updatePerPage({commit, getters}, perPage) {
            commit('setPerPage', perPage)
            LocalStorageService.set(MARKET_PER_PAGE, getters.getPerPage)
        },
        updatePage({commit}, page) {
            LocalStorageService.set(MARKET_PAGE, page)
            commit('setPage', page)
        },
        updateSearch({commit}, search) {
            commit('setSearch', search)
        },
        updateItemsFilter({commit, getters}, filter) {
            commit('setItemsFilter', filter)
            LocalStorageService.set(MARKET_ITEMS_FILTER, getters['getSelectedItemsFilter'])
        },
        clearFilters({commit}) {
            commit('setEmptyFilter')
            LocalStorageService.set(MARKET_ITEMS_FILTER, [])
            LocalStorageService.set(MARKET_PERK_FILTER, [])
            LocalStorageService.set(MARKET_RACE_FILTER, [])
            LocalStorageService.set(MARKET_RARITY_FILTER, [])
            LocalStorageService.set(MARKET_ATTRIBUTE_FILTER, [])
        },
        fetchMarket({commit, rootGetters, getters}, emitter) {
            emitter.emit('isLoad', true)
            commit('setMarket', {data: [], total: 0})
            let checkboxes = toRaw(rootGetters['filters/getMarketSelectedCheckboxes'])
            let parts = toRaw(rootGetters['filters/getMarketSelectedParts'])
            let dropdown = toRaw(rootGetters['filters/getMarketDropDown'])
            let limit = getters['getPerPage']
            let search = getters['getSearch']
            let raceFilters = getters['getSelectedRaceFilter']
            let rarityFilters = getters['getSelectedRarityFilter']
            let itemsFilters = getters['getSelectedItemsFilter']
            let perkFilters = getters['getSelectedPerkFilter']
            let attributeFilters = getters['getSelectedAttributeFilter']

            let sendParts = {
                attribute: [],
                item: [],
            }

            let filters = {
                race: [],
                rarity: [],
                slot: [],
                perk: [],
                attribute: [],
            }

            raceFilters.map(item => {
                filters.race.push(item.filter)
            })

            rarityFilters.map(item => {
                filters.rarity.push(item.filter)
            })

            itemsFilters.map(item => {
                filters.slot.push(item.filter)
            })

            attributeFilters.map(item => {
                filters.attribute.push(item.filter)
            })

            perkFilters.map(item => {
                filters.perk.push(item.filter)
            })

            parts.map(item => {
                sendParts[item.type].push(item.id)
            })

            let url = 'market'

            url = concatUrl(url, 'rarity', filters.rarity)
            url = concatUrl(url, 'race', filters.race)
            url = concatUrl(url, 'slot', filters.slot)
            url = concatUrl(url, 'attribute_slot', filters.attribute)
            url = concatUrl(url, 'perk', filters.perk)

            url = concatUrl(url, 'gender', toRaw(checkboxes.gender))
            url = concatUrl(url, 'skin', toRaw(checkboxes.skin))
            url = concatUrl(url, 'type', toRaw(checkboxes.type))
            url = concatUrl(url, 'attribute', sendParts.attribute)
            url = concatUrl(url, 'item', sendParts.item)
            url = concatUrl(url, 'page', typeof getters.getPage === "undefined" ? 1 : getters.getPage)
            url = concatUrl(url, 'limit', limit)

            if (search !== '') {
                url = concatUrl(url, 'search', search)
            }

            if (Object.values(dropdown.sale).length > 0) {
                url = concatUrl(url, 'sortType', dropdown.sale)
            }

            if (Object.values(dropdown.sort).length > 0) {
                url = concatUrl(url, 'order', dropdown.sort)
            }

            ApiService.get(url).then(market => {
                commit('setMarket', market)
                emitter.emit('isLoad', false)
            })
        },
    }
}
