<template>
    <div class="flex flex-wrap gap-2 pt-3 pb-1">
        <div :class="`filter-item-tag ${item.tag} ${isSelectedFilter(item) ? '-selected' : ''}`" v-for="(item, i) in filterRarity" :key="i" v-on:click="selectFilter(item)">
            <span>{{item.name}}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {filterRarity, FiltersType} from "../../../../entities/Filters";
import {useFilterStore} from "../../../../store/useFilterStore";
import {watch, computed} from "vue";

const props = defineProps({
    name: {
        type: String,
        required: true
    }
})

const filterStore = useFilterStore(props.name)
const raceFilters = computed(() => filterStore.getRarityFilters)

const emmit = defineEmits<{
    (e: 'is-selected', value: boolean )
}>();

watch(raceFilters,(check) => {
    emmit('is-selected', check.length > 0)
}, {deep: true})

const isSelectedFilter = (filter: FiltersType) => filterStore.getRarityFilters.find(item => item.filter === filter.filter) !== undefined
const selectFilter = (item: FiltersType) => filterStore.setRarityFilter(item)
</script>

<style lang="scss" scoped>
.filter-item-tag {
    height: 24px;
    padding: 0 12px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--mm-color-dark3);
    font-size: 12px;
    cursor: pointer;

    &.--common {
        &:hover { background: #2C5B26; }
        &.-selected { background: #54B847;}
    }
    &.--rare {
        &:hover { background: #4472A0; }
        &.-selected { background: #69B2FA;}
    }
    &.--epic {
        &:hover { background: #81559E; }
        &.-selected { background: #C27FF0;}
    }
    &.--legendary {
        &:hover { background: #966534; }
        &.-selected { background: #F6AC61;}
    }
    &.--immortal {
        &:hover { background: #8D2323; }
        &.-selected { background: #DC4040;}
    }
}
</style>
