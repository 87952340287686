<template>
    <div class="ui-type-box">
        <div class="icon" :type="type" :style="cssVars"></div>
        <div class="label" v-if="label">{{ $t(`${type}`) }}</div>
    </div>

</template>

<script>
    export default {
        name: "TypeIcon",
        props:{
            type: {
                type: String,
                required: true,
                default: () => ("")
            },
            size: {
                size: String,
                required: true,
                default: () => (""),
            },
            marginLeft: {
                marginLeft: String,
                default: () => ("0"),
            },
            marginRight: {
                marginRight: String,
                default: () => ("0"),
            },
            label: {
                label: Boolean,
                default: () => false,
            }
        },
        computed: {
            cssVars() {
                return {
                    '--size': this.size + 'px',
                    '--marginLeft': this.marginLeft + 'px',
                    '--marginRight': this.marginRight + 'px',
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .ui-type-box {
        display: flex;
        align-items: center;

        .icon {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            min-width: var(--size);
            max-width: var(--size);
            min-height: var(--size);
            max-height: var(--size);

            margin-left: var(--marginLeft);
            margin-right: var(--marginRight);

            &[margin='true'] {
                margin-left: calc(var(--size) / 4);
            }

            &[type='season1'] {
                background-image: url("/images/chibidango/type/season1.png");
            }

            &[type='season2'] {
                background-image: url("/images/chibidango/type/season2.png");
            }

            &[type='season3'] {
                background-image: url("/images/chibidango/type/season3.png");
            }

            &[type='mythic'] {
                background-image: url("/images/chibidango/type/mythic.png");
            }

            &[type='tribute'] {
                background-image: url("/images/chibidango/type/tribute.png");
            }

            &[type='summon'] {
                background-image: url("/images/chibidango/type/summon.png");
            }
        }

        .label {
            margin-left: 4px;
            color: #FFFFFF;
            font-weight: 300;
            font-size: 14px;
            white-space: nowrap;
        }
    }

</style>
