import {Component, PropType} from "vue";
import FilterByRace from "@/components/v2/filter/components/FilterByRace.vue";
import FilterByRarity from "@/components/v2/filter/components/FilterByRarity.vue";
import FilterByItemSlot from "@/components/v2/filter/components/FilterByItemSlot.vue";
import FilterByAttributes from "@/components/v2/filter/components/FilterByAttributes.vue";
import FilterCheckboxes from "@/components/v2/filter/components/FilterCheckboxes.vue";
import PerksFilter from "@/components/v2/filter/components/FilterByPerks.vue";
import SearchFilter from "@/components/v2/filter/components/search/SearchFilter.vue";

export const IS_SHOW = 'IS_SHOW'
export const RACE_FILTER = 'RACE_FILTER'
export const ATTRIBUTE_FILTER = 'ATTRIBUTE_FILTER'
export const RARITY_FILTER = 'RARITY_FILTER'
export const ITEM_SLOT_FILTER = 'ITEM_SLOT_FILTER'
export const ATTRIBUTE_SLOT_FILTER = 'ATTRIBUTE_SLOT_FILTER'
export const SEARCH_NFT_FILTER = 'SEARCH_NFT_FILTER'
export const SEARCH_FILTER = 'SEARCH_FILTER'
export const GROUP_FILTER = 'GROUP_FILTER'
export const HIDE_FILTER = 'HIDE_FILTER'
export const SELECTED_PART_FILTER = 'SELECTED_PART_FILTER'
export const CHECKBOX_FILTER = 'CHECKBOX_FILTER'
export const SEARCH_PARTS_FILTER = 'SEARCH_PARTS_FILTER'
export const PERK_FILTER = 'PERK_FILTER'
export const VIEW_FILTER = 'VIEW_FILTER'
export const DROPDOWN_FILTER = 'DROPDOWN_FILTER'
export const ITEM_INVENTORY = 'itemInventory'
export const CHIBIE_INVENTORY = 'chibieInventory'
export const CHIBIE_MARKET = 'chibieMarket'
export interface FilterDropDownType {
    name: string,
    filter: string,
}

export interface FiltersType {
    name: string,
    filter: string,
    icon: string,
    exclude: Array<string>|undefined
}
export interface PropsType {
    dataView: object,
    dataPosition: object,
    name: object,
    filterTitle: object,
    configFilter: ConfigType,
}

export interface FilterAccordionType {
    state: string,
    title: string,
    component: Component,
}

export interface FilterHide {
    field: string,
    value: string,
}

export interface ConfigType {
    filterAccordion: Array<FilterAccordionType>,
    filterHide: Array<FilterHide>,
}

export const filterProps: PropsType = {
    dataView: {
        type: String,
        required: true,
    },
    dataPosition: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    filterTitle: {
        type: String,
        required: true,
    },
    configFilter: {
        type: Object as PropType<ConfigType>,
        required: true,
    }
}

export const itemInventoryConfig: ConfigType = {
    filterAccordion: [
        {
            state: "item-race",
            title: "Race",
            component: FilterByRace
        },
        {
            state: "item-rarity",
            title: "Rarity",
            component: FilterByRarity
        },
        {
            state: "item-slot",
            title: "Items",
            component: FilterByItemSlot
        }
    ],
    filterHide: []
}

export const chibieInventoryConfig: ConfigType = {
    filterAccordion: [
        {
            state: "chibi-inventory-general",
            title: "General",
            component: FilterCheckboxes
        },
        {
            state: "chibi-inventory-search",
            title: "Parts and Items",
            component: SearchFilter
        },
        {
            state: "chibi-inventory-race",
            title: "Race",
            component: FilterByRace
        },
        {
            state: "chibi-inventory-rarity",
            title: "Rarity",
            component: FilterByRarity
        },
        {
            state: "chibi-inventory-attributes",
            title: "Attributes",
            component: FilterByAttributes
        },
        {
            state: "chibi-inventory-slot",
            title: "Items",
            component: FilterByItemSlot
        },
        {
            state: "chibi-inventory-perks",
            title: "Perks",
            component: PerksFilter
        }
    ],
    filterHide: [
        {
            field: 'byUser',
            value: '',
        }
    ]
}

export const chibieMarketConfig: ConfigType = {
    filterAccordion: [
        {
            state: "chibi-market-general",
            title: "General",
            component: FilterCheckboxes
        },
        {
            state: "chibi-market-search",
            title: "Parts and Items",
            component: SearchFilter
        },
        {
            state: "chibi-market-race",
            title: "Race",
            component: FilterByRace
        },
        {
            state: "chibi-market-rarity",
            title: "Rarity",
            component: FilterByRarity
        },
        {
            state: "chibi-market-attributes",
            title: "Attributes",
            component: FilterByAttributes
        },
        {
            state: "chibi-market-slot",
            title: "Items",
            component: FilterByItemSlot
        },
        {
            state: "chibi-market-perks",
            title: "Perks",
            component: PerksFilter
        }
    ],
    filterHide: [

    ]
}

export const filterRace:Array<FiltersType> = [
    {name: 'Human', filter: 'HUMAN', icon: 'human-min', exclude: []},
    {name: 'Elf', filter: 'NIGHT_ELVES', icon: 'night-elves-min', exclude: []},
    {name: 'Sun Elf', filter: 'SUN_ELVES', icon: 'sun-elves-min', exclude: []},
    {name: 'Orc', filter: 'ORCS', icon: 'orcs-min', exclude: []},
]

export const filterRarity:Array<FiltersType> = [
    {name: 'Common', filter: 'COMMON', tag: '--common', exclude: []},
    {name: 'Rare', filter: 'RARE', tag: '--rare', exclude: []},
    {name: 'Epic', filter: 'EPIC', tag: '--epic', exclude: []},
    {name: 'Legendary', filter: 'LEGENDARY', tag: '--legendary', exclude: []},
    {name: 'Immortal', filter: 'IMMORTAL', tag: '--immortal', exclude: []},
]

export const filterSlot:Array<FiltersType> = [
    {name: 'Hat', filter: 'HAT', tag: '--hat', exclude: []},
    {name: 'Suit', filter: 'BODYSUIT', tag: '--bodysuit', exclude: []},
    {name: 'Trinket', filter: 'LEFTHAND', tag: '--lefthand', exclude: []},
    {name: 'Mask', filter: 'FACEMASK', tag: '--facemask', exclude: []},
    {name: 'Weapon', filter: 'RIGHTHAND', tag: '--righthand', exclude: []},
    {name: 'Pet', filter: 'PET', tag: '--pet', exclude: []},
    {name: 'Background', filter: 'BACKGROUND', tag: '--background', exclude: []},
    {name: 'Resource', filter: 'RESOURCE', tag: '--resource', exclude: [CHIBIE_MARKET]},
    {name: 'Chest', filter: 'CHEST', tag: '--chest', exclude: [CHIBIE_MARKET]},
]

export const filterAttribute: Array<FiltersType> = [
    {name: 'Mouth', filter: 'MOUTH', tag: '--mouth', exclude: []},
    {name: 'Eyes', filter: 'EYES', tag: '--eyes', exclude: []},
    {name: 'Hairstyle', filter: 'HAIRSTYLE', tag: '--hairstyle', exclude: []}
]

export const itemInventoryDropdown: Array<FilterDropDownType> = [
    {name: 'All Items', filter: 'all'},
    {name: 'Equipped', filter: 'equipped'},
    {name: 'Unequipped', filter: 'unequipped'},
]
export const chibieInventoryDropdown: Array<FilterDropDownType> = [
    {name: 'All Chibis', filter: 'all'},
    {name: 'For Sale', filter: 'market'},
    {name: 'Not for Sale', filter: 'bag'},
]
export const filterPerk:Array<FiltersType> = [
    {name: 'Adventurer', filter: 'adventurer', tag: '--adventurer', exclude: []},
    {name: 'Armorsmith', filter: 'armorsmith', tag: '--armorsmith', exclude: []},
    {name: 'Gladiator', filter: 'gladiator', tag: '--gladiator', exclude: []},
    {name: 'Navigator', filter: 'navigator', tag: '--navigator', exclude: []},
    {name: 'Weaponsmith', filter: 'weaponsmith', tag: '--weaponsmith', exclude: []},
    {name: 'Alchemist', filter: 'alchemist', tag: '--alchemist', exclude: []},
    {name: 'Caretaker', filter: 'caretaker', tag: '--caretaker', exclude: []},
    {name: 'Monster Slayer', filter: 'monsterslayer', tag: '--monsterslayer', exclude: []},
    {name: 'Tanner', filter: 'tanner', tag: '--tanner', exclude: []},
    {name: 'All Perks', filter: 'all', tag: '--all', exclude: []},
]

export const chibieMarketDropdown: Array<FilterDropDownType> = [
    {name: 'All Chibis', filter: 'all'},
    {name: 'For Sale', filter: 'market'},
    {name: 'Not for Sale', filter: 'bag'},
    // {name: 'Other Markets', filter: 'externalMarket'},
]

export const chibieMarketOrderDropdown = {
    externalMarket: [
        {name: 'Lowest ID', filter: 'nft_id', order: 'asc'},
        {name: 'Highest ID', filter: 'nft_id', order: 'desc'},
        {name: 'Lowest price', filter: 'sell_price', order: 'asc', selected: true},
        {name: 'Highest price', filter: 'sell_price', order: 'desc'},
        {name: 'Latest', filter: 'latest', order: 'desc'},
    ],
    market: [
        {name: 'Lowest ID', filter: 'nft_id', order: 'asc'},
        {name: 'Highest ID', filter: 'nft_id', order: 'desc'},
        {name: 'Lowest price', filter: 'sell_price', order: 'asc', selected: true},
        {name: 'Highest price', filter: 'sell_price', order: 'desc'},
        {name: 'Latest', filter: 'latest', order: 'desc'},
    ],
    bag: [
        {name: 'Lowest ID', filter: 'nft_id', order: 'asc', selected: true},
        {name: 'Highest ID', filter: 'nft_id', order: 'desc'},
    ],
    all: [
        {name: 'Lowest ID', filter: 'nft_id', order: 'asc', selected: true},
        {name: 'Highest ID', filter: 'nft_id', order: 'desc'},
    ],
}

export const chibieOrderDropdown = {
    market: [
        {name: 'Lowest ID', filter: 'nft_id', order: 'asc'},
        {name: 'Highest ID', filter: 'nft_id', order: 'desc'},
        {name: 'Lowest price', filter: 'sell_price', order: 'asc', selected: true},
        {name: 'Highest price', filter: 'sell_price', order: 'desc'},
        {name: 'Latest', filter: 'latest', order: 'desc'},
    ],
    bag: [
        {name: 'Lowest ID', filter: 'nft_id', order: 'asc', selected: true},
        {name: 'Highest ID', filter: 'nft_id', order: 'desc'},
    ],
    all: [
        {name: 'Lowest ID', filter: 'nft_id', order: 'asc', selected: true},
        {name: 'Highest ID', filter: 'nft_id', order: 'desc'},
    ],
}

export const checkboxes = {
    type: {
        label: 'TYPE',
        key: 'type',
        items: [
            {key: 'season1', label: 'Season 1'},
            {key: 'mythic', label: 'Mythic'},
            {key: 'season2', label: 'Season 2'},
            {key: 'tribute', label: 'Tribute'},
            {key: 'season3', label: 'Season 3'},
            {key: 'summon', label: 'Summon'}
        ]
    },
    gender: {
        label: 'GENDER',
        key: 'gender',
        items: [
            {key: 'male', label: 'Male'},
            {key: 'female', label: 'Female'},
        ]
    },
    skin: {
        label: 'SKIN COLOR',
        key: 'skin',
        items: [
            {key: 'light', label: 'Light'},
            {key: 'dark', label: 'Dark'},
        ]
    },
}

export const groups = [
    {
        group: 'HUMAN',
        icon: 'human-min'
    },
    {
        group: 'NIGHT_ELVES',
        icon: 'night-elves-min'
    },
    {
        group: 'SUN_ELVES',
        icon: 'sun-elves-min'
    },
    {
        group: 'ORCS',
        icon: 'orcs-min'
    },
    {
        group: 'MYTHIC',
        icon: 'mythic-min'
    },
]

export const attributesParts = {
    MOUTH: [],
    EYES: [],
    HAIRSTYLE: [],
}

export const itemsParts = {
    BODYSUIT: [],
    FACEMASK: [],
    BACKGROUND: [],
    LEFTHAND: [],
    HAT: [],
    RIGHTHAND: [],
    PET: [],
}

export const attributesRaces = {
    HUMAN: attributesParts,
    MYTHIC: attributesParts,
    NIGHT_ELVES: attributesParts,
    SUN_ELVES: attributesParts,
    ORCS: attributesParts,
}

export const itemsRaces = {
    HUMAN: itemsParts,
    MYTHIC: itemsParts,
    NIGHT_ELVES: itemsParts,
    SUN_ELVES: itemsParts,
    ORCS: itemsParts,
}