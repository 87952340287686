import {useUserStore} from "../store/useUserStore";
import {createPinia, setActivePinia} from "pinia";
const pinia = createPinia();
setActivePinia(pinia);

const userStore = useUserStore()
export default function auth({next}) {
    if (!userStore.getAuth) {
        return next({name: 'connect'})
    }

    return next()
}