<template>
<div class="wallet-balance">
    <template v-if="balanceType === 'ada'">
        <span v-if="isLoad">Loading...</span>
        <span v-else><NumberFormat :number="getAdaBalance()"/>&nbsp;ADA</span>
    </template>

    <template v-if="balanceType === 'usd'">
        <span v-if="isLoad">Loading...</span>
        <span v-else><NumberFormat :number="getPrice().toFixed(2)"/>&nbsp;USD</span>
    </template>

    <template v-if="balanceType === 'dgems'">
        <span v-if="isLoad">Load...</span>
        <span v-else><NumberFormat :number="dgemBalance"/></span>
    </template>

    <template v-if="balanceType === 'chibi'">
        <span v-if="isLoad">Load...</span>
        <span v-else><NumberFormat :number="totalChibies"/> Chibis</span>
    </template>

    <template v-if="balanceType === 'items'">
        <span v-if="isLoad">Load...</span>
        <span v-else><NumberFormat :number="totalItems"/></span>
    </template>
</div>
</template>

<script setup lang="ts">
import NumberFormat from "../../NumberFormat.vue";
import {computed} from "vue";
import {useWalletStore} from "@/store/useWalletStore";
import {useStore} from "vuex";
import {useUserStore} from "@/store/useUserStore";

defineProps({
    balanceType: {
        type: String,
        required: true,
        default: () => ('') //ada, usd, dgems, chibi
    }
})

const store = useStore()
const walletStore = useWalletStore()
const userStore = useUserStore()

const isLoad = computed(() => walletStore.getLoadWallet)
const adaBalance = computed(() => walletStore.getAdaBalance)
const dgemBalance = computed(() => walletStore.getDgemBalance)
const usdPrice = computed(() => walletStore.getUsdPrice)
const totalChibies = computed(() => userStore.getTotalNft)
const totalItems = computed(() => userStore.getTotalItems)

const getAdaBalance = () => {
    return parseInt(adaBalance.value)
}

const getPrice = () => (parseInt(adaBalance.value) * parseFloat(usdPrice.value))
</script>

<style lang="scss" scoped>

</style>
