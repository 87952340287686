class LocalStorageService {
    set(key: string, data: any) {
        try {
            if (typeof data === 'string') {
                localStorage.setItem(key, data)
                return
            }

            localStorage.setItem(key, JSON.stringify(data))
        } catch (e) {
            console.log(e)
        }
    }

    get(key: string, defaultValue: any = null) {
        try {
            let data = localStorage.getItem(key)
            if (data !== null) {
                try {
                    return JSON.parse(data)
                } catch {
                    if (data !== '') {
                        return data
                    }

                    if (defaultValue !== null) {
                        return defaultValue
                    }
                }

            }

            if (defaultValue !== null) {
                return defaultValue
            }

            return data
        } catch (e) {
            console.log(e)
            if (typeof defaultValue !== null) {
                return defaultValue
            }

            return null
        }
    }

    remove(key: string) {
        localStorage.removeItem(key)
    }
    //TODO: add remove by key and all
}

export default new LocalStorageService()
