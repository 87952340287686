<template>
    <div class="right-screen-overlay" ref="overlay" :class="isOpen ? 'opened' : ''">
        <div class="right-screen-container" v-click-outside="handleClickOutside">
          <div class="top-content">
            <div class="right-screen-close" v-on:click="handleClose"><i class="fal fa-long-arrow-right"></i></div>
          </div>
          <div class="mid-content">
            <component v-if="component !== ''" v-bind:is="component"></component>
          </div>
        </div>
    </div>
</template>

<script>
//TODO: refactor on TS
import vClickOutside from "click-outside-vue3";
import {useStore} from "vuex";
import {computed, ref, watchEffect} from "vue";
import {RIGHT_WIDGET} from "../../../constants/baseConstants";

export default {
    name: "RightWidget",
    components: {  },
    directives: {
        clickOutside: vClickOutside.directive
    },
    setup() {
        const store = useStore()
        const overlay = ref(null)
        const handleClose = () => store.dispatch('base/openWidget', {type: RIGHT_WIDGET, component: '', isOpen: false})
        const handleClickOutside = (event) => {
            if (event.target === overlay.value) {
                store.dispatch('base/openWidget', {type: RIGHT_WIDGET, component: '', isOpen: false})
            }
        }

        watchEffect(() => {
          if (store.state.base.isOpenRightWidget) {
            document.body.classList.add('no-scroll')
          } else {
            document.body.classList.remove('no-scroll')
          }
        })

        return {
            isOpen: computed(() => store.state.base.isOpenRightWidget),
            component: computed(() => store.state.base.rightWidgetComponent),
            handleClickOutside,
            handleClose,
            overlay,
        }
    }
}
</script>

<style lang="scss" scoped>

.right-screen-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease 0s, transform 0s ease 0.3s;
    transform: translate3d(100%, 0px, 0px);
    background: rgba(35, 35, 39, 0.8);

    &.opened {
        opacity: 1;
        transition: opacity 0.3s ease 0s;
        transform: none;

        .right-screen-container {
            transform: none;
        }
    }

    .right-screen-container {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1100;
        width: 100%;
        max-width: 300px;
        height: 100%;
        transition: all 0.5s ease 0s;
        transform: translate3d(100%, 0px, 0px);
        background: #292D3F;
        border-left: 1px solid #3D4152;
    }
}

.top-content {
  width: 100%;
  border-bottom: 1px solid #3D4152;
}

.mid-content {
  min-height: calc(100vh - 60px);
  overflow-y: auto;
    height: 100%;
}

.right-screen-close {
    //position: absolute;
    top: 0px;
    margin-left: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;
    font-size: 22px;
    color: #7E8AC0;

    cursor: pointer;
}

////////////////////////
.right-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .menu-container {
        flex: 1 1;
    }
}
</style>
