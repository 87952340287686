<template>
    <div class="profile-menu-holder">

        <template v-if="isConnected">
            <div class="flex flex-col items-center gap-3">
                <div class="w-21 h-21 rounded-full">
                    <img class="objectImg" alt="avatar" src="/images/logos/profile_picture.png"/>
                </div>
                <div class="user-name">Dangonian</div>
            </div>

            <div class="flex flex-col gap-3 w-full py-6">
                <div class="balance-item -wallet">
                    <div class="flex justify-center items-center h-9 w-9 shrink-0 rounded-full bg-mm-color-out1">
                        <WalletIcon :wallet-type="walletType" :class="`h-6 w-6`"/>
                    </div>
                    <div>
                        <WalletBalance balance-type="ada" :class="`font-bold text-font-14`"/>
                        <div class="text-font-12 leading-none font-light">{{ formatWallet() }}</div>
                    </div>
                </div>
                <div class="balance-item -dgems">
                    <div class="flex justify-center items-center h-9 w-9 shrink-0 rounded-full bg-mm-color-out1">
                        <WalletIcon wallet-type="dgems" :class="`h-6 w-6`"/>
                    </div>
                    <div class="flex items-center gap-1 font-bold text-font-14">
                        <WalletBalance balance-type="dgems"/>
                        <span>DGEMS</span>
                    </div>
                    <a class="musli-link" :href="DGEMS_MUESLI_URL" target="_blank">
                        <i class="fa-solid fa-plus"></i>
                    </a>
                </div>
            </div>
        </template>

        <template v-if="!isConnected">
            <div class="pb-10">
                <div v-on:click="moveTo('landing')">
                    <img class="m-auto" alt="Chibidango Heroes" src="/images/brand/brand-logo.png" width="164"/>
                </div>
            </div>
        </template>

        <div class="flex flex-col w-full gap-6">
            <div class="flex flex-col w-full gap-1">
                <div :class="`menu-item ${route.name === 'playWrap.entryScene' ? '-active':''}`" v-on:click="moveTo('playWrap.entryScene')"><i class="fa-solid fa-gamepad-modern"></i><span>Play</span></div>
                <div :class="`menu-item ${route.name === 'dashboard' ? '-active':''}`" v-on:click="moveTo('dashboard')"><i class="fa-solid fa-chart-simple"></i><span>Dashboard</span></div>
                <div :class="`menu-item ${route.name === 'marketplace' ? '-active':''}`" v-on:click="moveTo('marketplace')"><i class="fa-solid fa-store"></i><span>Marketplace</span></div>
                <div :class="`menu-item ${route.name === 'library' ? '-active':''}`" v-on:click="moveTo('library')"><i class="fa-solid fa-books"></i><span>Library</span></div>

                <template v-if="isConnected">
                    <div :class="`menu-item ${route.name === 'profile' ? '-active':''}`" v-on:click="moveTo('profile')"><i class="fa-solid fa-user"></i><span>My Account</span></div>
                    <div :class="`menu-item ${route.name === 'inventory' ? '-active':''}`" v-on:click="moveTo('inventory')"><i class="fa-solid fa-treasure-chest"></i><span>Inventory</span></div>
<!--                    <div :class="`menu-item ${route.name === 'leaderboard' ? '-active':''}`" v-on:click="moveTo('leaderboard')"><i class="fa-solid fa-jack-o-lantern"></i><span>Leaderboard</span></div>-->
                    <div class="menu-item -disabled"><i class="fa-solid fa-wave-pulse"></i><span>Activity</span></div>
                    <div class="menu-item -disabled"><i class="fa-solid fa-gear"></i><span>Settings</span></div>
                    <div class="menu-item -logout" v-on:click="logout"><i class="fa-solid fa-right-from-bracket"></i><span>Logout</span></div>
                </template>
            </div>

            <template v-if="!isConnected">
                <div class="connect-button" v-on:click="moveTo('connect')">
                    <span>Connect Wallet</span>
                </div>
            </template>

            <div class="extra-menu">
                <div class="extra-item" v-on:click="moveTo('landing')">
                    <span class="-icon"><i class="fa-solid fa-gem"></i></span>
                    <span class="-label">Website</span>
                </div>

                <a class="extra-item" :href="WHITEPAPER_URL" target="_blank">
                    <span class="-icon"><i class="fa-solid fa-scroll-old"></i></span>
                    <span class="-label">Whitepaper</span>
                </a>

                <a class="extra-item" :href="TWITTER_URL" target="_blank">
                    <span class="-icon"><i class="fa-brands fa-twitter"></i></span>
                    <span class="-label">Twitter</span>
                </a>

                <a class="extra-item" :href="DISCORD_URL" target="_blank">
                    <span class="-icon"><i class="fa-brands fa-discord"></i></span>
                    <span class="-label">Discord</span>
                </a>
            </div>
        </div>

    </div>
</template>

<script lang="ts" setup>
import {useStore} from "vuex";
import {limitStr} from "@/helper";
import {LEFT_WIDGET} from "@/constants/baseConstants";
import {computed} from "vue";
import {useWalletStore} from "@/store/useWalletStore";
import {useUserStore} from "@/store/useUserStore";
import {useRoute, useRouter} from "vue-router";
import {defaultToast, INFO} from "@/service/ToastService";
import {useEmitter} from "@/useEmitter";
import {useI18n} from "vue-i18n";
import WalletIcon from "@/components/v2/wallet/WalletIcon.vue";
import WalletBalance from "@/components/v2/wallet/WalletBalance.vue";

const DISCORD_URL = import.meta.env.VITE_DISCORD_SERVER_URL
const TWITTER_URL = import.meta.env.VITE_TWITTER_URL
const WHITEPAPER_URL = import.meta.env.VITE_WHITEPAPER_URL
const DGEMS_MUESLI_URL = import.meta.env.VITE_DGEMS_MUESLI_URL

const {t} = useI18n()
const store = useStore()
const emitter = useEmitter()
const router = useRouter()
const walletStore = useWalletStore()
const userStore = useUserStore()

const wallet = computed(() => walletStore.getWallet)
const walletType = computed(() => walletStore.getWalletType)
const isConnected = computed(() => userStore.getAuth)

const route = useRoute()

console.log(route)
const logout = async () => {
    await store.dispatch('base/openWidget', {type: LEFT_WIDGET, component: '', isOpen: false})
    await store.dispatch('wallet/logout')

    userStore.clear()
    walletStore.clear()
    await router.push({name: 'connect'})

    defaultToast(INFO, t(`toastify.messages.USER_SUCCESSFULLY_LOGOUT`))
}
const formatWallet = () => (limitStr(wallet.value, 5))

const moveTo = async (name: string) => {
    await store.dispatch('base/openWidget', {type: LEFT_WIDGET, component: '', isOpen: false})
    await router.push({name})
}
</script>

<style lang="scss" scoped>

.profile-menu-holder {
    padding: 30px 20px;
}

.user-avatar {
    width: 85px;
    height: 85px;
    border-radius: 100%;
}

.user-name {
    font-size: 20px;
    line-height: 24px;
}

.menu-item {
    position: relative;
    width: 100%;
    height: 52px;
    border-radius: 16px;
    transition: all 0.2s ease-in;
    color: var(--mm-color-base);

    font-size: 18px;
    line-height: 21px;

    display: flex;
    align-items: center;

    padding-left: 80px;

    &:hover {
        background: rgba(61, 65, 82, 0.35);
        cursor: pointer;
    }

    &.router-link-active, &.-active {
        background: #3D4152;
    }

    &.-disabled {
        color: rgba(255, 255, 255, 0.5);

        &:hover {
            cursor: not-allowed;
            background: none;
        }
    }

    &.-logout {
        color: #ED7070;

        &:hover {
            background: none;
        }
    }

    i {
        position: absolute;
        top: 16px;
        left: 29px;

        font-size: 22px;
    }
}

.connect-button {
    height: 52px;
    border-radius: 16px;

    font-size: 18px;
    line-height: 21px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--mm-color-primary);
}

.balance-item {
    position: relative;
    width: 100%;
    height: 52px;
    border-radius: 16px;
    transition: all 0.2s ease-in;
    color: #FFFFFF;

    font-size: 18px;
    line-height: 21px;

    display: flex;
    align-items: center;
    gap: 24px;

    padding: 0px 24px;

    &.-wallet {
        background: var(--mm-color-primary);
    }

    &.-dgems {
        background: var(--mm-dgems-color);
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
    }
}

////

.musli-link {
    position: absolute;
    top: 17px;
    right: 24px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 100%;
    font-size: 10px;
}

///
.extra-menu {
    background: var(--mm-color-dark1);

    border: 1px solid var(--mm-color-out1);
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    gap: 12px;

    padding: 16px 30px;

    font-size: 18px;
    line-height: 21px;

    .extra-item {
        display: flex;
        align-items: center;

        gap: 26px;
    }

    .-icon {
        width: 26px;
        height: 26px;

        border-radius: 6px;
        background: var(--mm-color-primary);
        color: var(--mm-color-base);

        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 16px;
        }
    }
}
</style>
