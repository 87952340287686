<template>
<div class="relative bg-mm-color-dark2 border border-mm-color-out1 rounded-md">
    <slot/>
</div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>