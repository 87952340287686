<template>
    <ModalHeader>Monthly ADA Rewards</ModalHeader>
    <ModalBody>
        <div class="flex flex-col gap-2">
            <div class="flex gap-3 md:items-center" v-for="(item, i) in rewards" :key="i">
                <Icon component="png-resize-icon" class="h-6 w-6" folder="nft-type" :icon="item.icon"/>
                <p>{{ item.name }} Chibi generates {{ item.amount }} ADA per month</p>
            </div>
        </div>

        <div class="pt-6">
            <p>Minimal amount to claim is 1 ADA.</p>
        </div>
    </ModalBody>
</template>

<script lang="ts" setup>
import {onBeforeMount, ref} from "vue";
import Icon from "@/components/v2/icons/Icon.vue";
import ModalHeader from "@/components/v2/modals/components/ModalHeader.vue";
import ModalBody from "@/components/v2/modals/components/ModalBody.vue";

const rewards = ref([
    {
        'icon': 'season1',
        'name': 'Season 1',
        'amount': '0.05',
    },
    {
        'icon': 'season2',
        'name': 'Season 2',
        'amount': '0.05',
    },
    {
        'icon': 'season3',
        'name': 'Season 3',
        'amount': '0.05',
    },
    {
        'icon': 'tribute',
        'name': 'Tribute',
        'amount': '0.5',
    },
    {
        'icon': 'mythic',
        'name': 'Mythic',
        'amount': '1',
    }
])

const emmit = defineEmits<{
    (e: 'modalWidth', value: string )
}>();

onBeforeMount(() => {
    emmit("modalWidth", '580px')
})
</script>

<style lang="scss" scoped>

</style>
