<template>
    <ModalHeader>Sell Chibidango</ModalHeader>
    <ModalBody class="flex items-center">
        <Card :nft="data"/>
        <div class="sell-box">
            <p class="-info">Your chibi will be listed on the Marketplace. In order to get it back, you will have to cancel the sale.</p>
            <div class="-label">sell price</div>
            <div class="-data">
                <div class="-input-holder">
                    <input ref="inputRef" class="ui-input" :class="error ? '-input-error' : ''" type="text" v-model="input" v-on:keyup="changeInput"/>
                </div>
                <button class="ui-btn btn-purple btn-ic ic-sell" :class="error ? 'disabled' : ''" :disabled="error" v-on:click="sellAsset(data)">
                    <span>Sell Chibi</span>
                </button>
            </div>
            <div :class="`${error ? '-show': ''} -error`">{{ errorMsg }}</div>
            <div class="-fee">Fee: <span>2.5%</span> or <span>1 ADA</span> minimum</div>
            <div class="-fee">Treasury Fee: <span>2.5%</span> or <span>1 ADA</span> minimum</div>
            <div class="-fee">You will receive &asymp; <span>{{ reciveAda.toFixed(2) }} ADA</span>${{ getPrice().toFixed(2) }}</div>
        </div>
    </ModalBody>
</template>

<script lang="ts" setup>
import {CurrencyDisplay, useCurrencyInput} from "vue-currency-input";
import Card from "@/components/nft/view/Card.vue";
import {computed, PropType, ref, onBeforeMount, onBeforeUnmount} from "vue";
import {Nft} from "@/entities/Nft";
import {useWalletStore} from "@/store/useWalletStore";
import {defaultToast, ERROR, INFO, SUCCESS} from "@/service/ToastService";
import {buildTx} from "@/service/WalletService";
import {USER_DECLINE_TRANSACTION} from "@/entities/Wallet";
import {useI18n} from "vue-i18n";
import {useEmitter} from "@/useEmitter";
import ApiService from "@/service/ApiService";
import ModalHeader from "@/components/v2/modals/components/ModalHeader.vue";
import ModalBody from "@/components/v2/modals/components/ModalBody.vue";

const props = defineProps({
    data: {
        type: Object as PropType<Nft>,
        default: () => ({})
    }
})

const input = ref(0)
const errorMsg = ref('Minimum sell amount: 8 ADA')
const reciveAda = ref(0)
const error = ref(false)

const emitter = useEmitter()
const walletStore = useWalletStore()
const {t} = useI18n()

const price = computed(() => (walletStore.getUsdPrice))

const {inputRef} = useCurrencyInput({
    locale: undefined,
    currency: 'EUR',
    currencyDisplay: CurrencyDisplay.hidden,
    valueRange: undefined,
    precision: undefined,
    hideCurrencySymbolOnFocus: true,
    hideGroupingSeparatorOnFocus: true,
    hideNegligibleDecimalDigitsOnFocus: true,
    autoDecimalDigits: false,
    valueScaling: undefined,
    autoSign: true,
    useGrouping: false
})


const changeInput = () => {
    error.value = false
    if (input.value === '') {
        reciveAda.value = 0
        input.value = 0
        error.value = true
        return
    }
    let price = parseFloat(input.value)
    let fee = price * 2.5 / 100
    let treasuryFee = price * 2.5 / 100
    if (price < 8) {
        error.value = true
    }
    reciveAda.value = price - (fee < 1 ? 1 : fee) - (treasuryFee < 1 ? 1 : treasuryFee)
    if (reciveAda.value < 0) {
        reciveAda.value = 0
    }
    if (typeof input.value === 'string') {
        input.value = input.value.replace(/,/g, "")
    }
}

const getPrice = () => {
    return parseFloat(reciveAda.value) * price.value
}
//TODO add change pending button event
const sellAsset = async (asset) => {
    emitter.emit('isLoad')
    try {
        let response = await ApiService.get(`transaction/check/sell/${asset.id}`)
        if (response.status === 'error') {
            defaultToast(ERROR, 'Can`t send transaction, this chibi start canceled or some one start buy: ' + response.message + ' id ' + asset.id)
            return
        }
        const responseTx = await buildTx('addr1vya2f4xjww6aww8y4ey4ln9umae6nw9zpqkzgfa0yssy7uq7fujcn', [
            {unit: 'lovelace', quantity: '2100000'},
            {unit: asset.unit, quantity: '1'}
        ])
        if (typeof responseTx === "string") {
            if (responseTx === USER_DECLINE_TRANSACTION) {
                defaultToast(INFO, t(`toastify.messages.USER_DECLINE_TRANSACTION`))
            } else {
                defaultToast(ERROR, t(`toastify.messages.${responseTx}`))
            }
        } else {
            ApiService.post('transaction/sell', {
                asset: asset,
                trxHash: responseTx.txHash,
                price: input.value
            }).then(response => {
                if (response.error) {
                    defaultToast(ERROR, 'get error from server ' + response.error)
                } else {
                    defaultToast(SUCCESS, t(`toastify.messages.TRANSACTION_SUCCESSFULLY_SENT`))
                }
            }).catch(e => {
                console.log('wtf', e)
                defaultToast(ERROR, 'get error from server ' + e.message)
            })
        }

        emitter.emit('openModal', {isOpen: false, componentModal: ''})
        emitter.emit('getChibiStatus')
    } catch (e) {
        let msg;
        if (typeof e === 'string' || e instanceof String) {
            msg = e
        } else {
            msg = e.message
        }
        if (typeof e.info !== "undefined") {
            msg = e.info
        }
        defaultToast(ERROR, msg)
        emitter.emit('getChibiStatus')
    }
}

const emmit = defineEmits<{
    (e: 'modalWidth', value: string )
}>();

onBeforeMount(() => {
    emmit("modalWidth", '600px')
})
</script>

<style lang="scss" scoped>
.sell-box {
    padding-left: 30px;

    .-input-holder {
        position: relative;

        &:before {
            content: "₳";
            width: 24px;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ui-input {
            font-size: 15px;
            line-height: 18px;
            font-weight: 300;
            padding-left: 24px;
        }
    }


    .-label {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #7E8AC0;
        padding-bottom: 8px;
    }

    .-info {
        font-weight: 300;
        font-size: 12px;
        margin-bottom: 24px;
    }

    .-data {
        display: flex;
        align-items: center;
        padding-bottom: 12px;
    }

    .-error {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #EC8484;
        padding-bottom: 10px;
        opacity: 0;

        &.-show {
            transition: all 0.1s ease;
            opacity: 1;
        }
    }

    .-input-holder {
        flex: 1 1;
        padding-right: 12px;
    }

    .-input-error {
        border-color: #ED7070;
    }

    .-fee {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #AAAAAA;
        padding: 1px 0;

        span {
            font-weight: 500;
            color: #ffffff;
        }
    }
}

.nft-box {
    margin: 0;

    &:hover {
        border-color: #3D4152;
        box-shadow: none;
    }
}

</style>
