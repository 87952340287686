import ApiService from "../../../service/api/ApiService";
import LocalStorageService from "../../../service/LocalStorageService";
import {concatUrl} from "../../../helper";
import {
    GAME_ITEM_INVENTORY_FILTER, GAME_ITEM_INVENTORY_ITEMS_FILTER,
    GAME_ITEM_INVENTORY_PAGE,
    GAME_ITEM_INVENTORY_PER_PAGE,
    GAME_ITEM_INVENTORY_RACE_FILTER, GAME_ITEM_INVENTORY_RARITY_FILTER,
    GAME_ITEM_INVENTORY_SHOW_FILTERS,
} from "../../../constants/localstorageConstants";
import {itemInventoryDropdown} from "../../../constants/filters";

export default {
    namespaced: true,
    state: () => ({
        data: [],
        total: 0,
        page: LocalStorageService.get(GAME_ITEM_INVENTORY_PAGE, 1),
        showFilter: LocalStorageService.get(GAME_ITEM_INVENTORY_SHOW_FILTERS, false),
        perPageSelected: LocalStorageService.get(GAME_ITEM_INVENTORY_PER_PAGE, 36),
        isLoad: false,
        dropdownFilter: LocalStorageService.get(GAME_ITEM_INVENTORY_FILTER, itemInventoryDropdown[0]),
        raceFilter: LocalStorageService.get(GAME_ITEM_INVENTORY_RACE_FILTER, []),
        rarityFilter: LocalStorageService.get(GAME_ITEM_INVENTORY_RARITY_FILTER, []),
        itemsFilter: LocalStorageService.get(GAME_ITEM_INVENTORY_ITEMS_FILTER, []),
        disableFilter: false,
    }),
    getters: {
        getPage: state => state.page,
        getInventory: state => ({ data: state.data, total: state.total}),
        getShowFilters: state => state.showFilter,
        getPerPage: state => state.perPageSelected,
        isLoad: state => state.isLoad,
        getDropdownFilter: state => state.dropdownFilter,
        getSelectedRaceFilter: state => state.raceFilter,
        getSelectedRarityFilter: state => state.rarityFilter,
        getSelectedItemsFilter: state => state.itemsFilter,
        getDisableFilter: state => state.disableFilter,
    },
    mutations: {
        setIsLoad(state, isLoad) {
            state.isLoad = isLoad
        },
        setPerPage(state, perPage) {
            state.perPageSelected = perPage
        },
        setPage(state, page) {
            state.page = page
        },
        setInventory(state, inventory) {
            state.data = inventory.data
            state.total = inventory.total
        },
        setLimit(state, limit) {
            state.limit = limit
        },
        setShowFilters(state) {
            state.showFilter = !state.showFilter
        },
        setDropDown(state, data) {
            state.dropdownFilter = data
        },
        setEmptyFilters(state) {
            state.raceFilter = []
            state.rarityFilter = []
            state.itemsFilter = []
        },
        setRaceFilter(state, filter) {
            let index = state.raceFilter.indexOf(filter)
            if (index === -1) {
                state.raceFilter.push(filter)
            } else {
                state.raceFilter = state.raceFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setRarityFilter(state, filter) {
            let index = state.rarityFilter.indexOf(filter)
            if (index === -1) {
                state.rarityFilter.push(filter)
            } else {
                state.rarityFilter = state.rarityFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setItemsFilter(state, filter) {
            let index = state.itemsFilter.indexOf(filter)
            if (index === -1) {
                state.itemsFilter.push(filter)
            } else {
                state.itemsFilter = state.itemsFilter.filter(item => item.filter !== filter.filter)
            }
        },
        setDisableFilters(state, isDisable) {
            state.disableFilter = isDisable
        }
    },
    actions: {
        dropDown({commit}, data) {
            commit('setDropDown', data)
            LocalStorageService.set(GAME_ITEM_INVENTORY_FILTER, data)
        },
        updatePerPage({commit, getters}, perPage) {
            commit('setPerPage', perPage)
            LocalStorageService.set(GAME_ITEM_INVENTORY_PER_PAGE, getters.getPerPage)
        },
        updateShowFilters({commit, getters}) {
            commit('setShowFilters')
            LocalStorageService.set(GAME_ITEM_INVENTORY_SHOW_FILTERS, getters.getShowFilters)
        },
        updatePage({commit, dispatch}, page) {
            LocalStorageService.set(GAME_ITEM_INVENTORY_PAGE, page)
            commit('setPage', page)
        },
        disableFilters({commit}, isDisable) {
            commit('setDisableFilters', isDisable)
        },
        updateLimit({commit}, limit) {
            commit('setLimit', limit)
        },
        updateIsLoad({commit}, isLoad) {
            commit('setIsLoad', isLoad)
        },
        clearInventory({commit}) {
            commit('setInventory', {data: [], total: 0})
        },
        clearFilters({commit}) {
            commit('setEmptyFilters')
            LocalStorageService.set(GAME_ITEM_INVENTORY_RACE_FILTER, [])
            LocalStorageService.set(GAME_ITEM_INVENTORY_RARITY_FILTER, [])
            LocalStorageService.set(GAME_ITEM_INVENTORY_ITEMS_FILTER, [])
        },
        updateRaceFilter({commit, getters}, filter) {
            commit('setRaceFilter', filter)
            LocalStorageService.set(GAME_ITEM_INVENTORY_RACE_FILTER, getters['getSelectedRaceFilter'])
        },
        updateRarityFilter({commit, getters}, filter) {
            commit('setRarityFilter', filter)
            LocalStorageService.set(GAME_ITEM_INVENTORY_RARITY_FILTER, getters['getSelectedRarityFilter'])
        },
        updateItemsFilter({commit, getters}, filter) {
            commit('setItemsFilter', filter)
            LocalStorageService.set(GAME_ITEM_INVENTORY_ITEMS_FILTER, getters['getSelectedItemsFilter'])
        },
        fetchInventory({ commit, rootGetters, getters }, emitter) {
            // emitter.emit('isLoadInventory', true)
            commit('setInventory', {data: [], total: 0})
            commit('setIsLoad', true)
            // let checkboxes = rootGetters['filters/getInventorySelectedCheckboxes']
            // let checkboxesPerk = rootGetters['filters/getInventorySelectedPerkCheckboxes']
            // let parts = rootGetters['filters/getInventorySelectedParts']
            let dropdown = getters['getDropdownFilter']
            let raceFilters = getters['getSelectedRaceFilter']
            let rarityFilters = getters['getSelectedRarityFilter']
            let itemsFilters = getters['getSelectedItemsFilter']
            let disableFilter = getters['getDisableFilter']
            let limit = getters['getPerPage']

            let sendParts = {
                race: [],
                rarity: [],
                slot: [],
            }

            raceFilters.map(item => {
                sendParts.race.push(item.filter)
            })

            rarityFilters.map(item => {
                sendParts.rarity.push(item.filter)
            })

            itemsFilters.map(item => {
                sendParts.slot.push(item.filter)
            })

            let url = 'inventory/game_items'

            url = concatUrl(url, 'race', sendParts.race)
            url = concatUrl(url, 'rarity', sendParts.rarity)
            url = concatUrl(url, 'slot', sendParts.slot)
            url = concatUrl(url, 'page', getters.getPage)
            url = concatUrl(url, 'limit', limit)
            url = concatUrl(url, 'sort_filter', dropdown.filter)

            if (disableFilter) {
                let asset = rootGetters['dressing/getAsset']

                if (asset.gender_specific) {
                    url = concatUrl(url, 'gender', asset.gender_specific)
                }
            }

            ApiService.get(url).then(response => {
                if (response) {
                    commit('setInventory', response)
                    commit('setIsLoad', false)
                }
            })
        }
    },
}
