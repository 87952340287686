
export default {
    namespaced: true,
    state: () => ({
        firstSelect: {},
        secondSelect: {},
        selectPosition: '',
    }),
    getters: {
        getFirst: state => state.firstSelect,
        getSecond: state => state.secondSelect,
        getPosition: state =>state.selectPosition
    },
    actions: {
        setClear({commit}) {
            commit('updateClear')
        },
        setPosition({commit}, position) {
            commit('updatePosition', position)
        },
        setFirst({commit}, chibie) {
            commit('updateFirst', chibie)
        },
        setSecond({commit}, chibie) {
            commit('updateSecond', chibie)
        }
    },
    mutations: {
        updatePosition(state, position) {
            state.selectPosition = position
        },
        updateFirst(state, chibie) {
            state.firstSelect = chibie
        },
        updateSecond(state, chibie) {
            state.secondSelect = chibie
        },
        updateClear(state) {
            state.firstSelect = {}
            state.secondSelect = {}
            state.selectPosition = ''
        }
    },
}
