import mitt from 'mitt'

const emitter = mitt()

export { emitter }

export default {
    install(app) {
        app.config.globalProperties.emitter = emitter
        app.provide('emitter', emitter)
    }
}
