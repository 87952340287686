const state = {
    asset: {
        name: "--",
        types: [],
        image: '',
        race: 'NONE'
    },
    parts: {},
    values: {},
    valuesIndex: {},
    isChange: false,
}
const getters = {
    getParts: state => state.parts,
    getValues: state => state.values,
    getValueIndex: state => state.valuesIndex,
    getChange: state => state.isChange,
    getAsset: state => state.asset,
}

const actions = {
    setAsset({commit}, asset) {
        commit('updateAsset', asset)
    },
    setChange({commit}, value) {
        commit('updateChange', value)
    },
    clear({commit}) {
        commit('updateClear')
    },
    setPart({commit}, {part, value}) {
        commit('updatePart', {part, value})
    },
    setPartValue({commit}, {part, value}) {
        commit('updatePartValues', {part, value})
    },
    setValueIndex({commit}, {part, index}) {
        commit('updateValueIndex', {part, index})
    },
    removePart({commit}, part) {
        commit('clearPart', part)
    },
    clearAsset({commit}) {
        commit('clearAsset')
    }
}

const mutations = {
    updateAsset(state, asset) {
        state.asset = asset
    },
    updateChange(state, value) {
        state.isChange = value
    },
    clearPart(state, value) {
        let parts = state.parts
        parts[value.part] = {
            rarity: '',
            image_name: '',
            slot: value.part.toUpperCase()
        }

        console.log(parts)
        state.parts = parts
    },
    updatePart(state, {part, value}) {
        state.parts[part] = value
    },
    updatePartValues(state, {part, value}) {
        state.values[part] = value
    },
    updateValueIndex(state, {part, index}) {
        state.valuesIndex[part] = index
    },
    updateClear(state) {
        state.parts = {}
        state.values = {}
        state.valuesIndex = {}
    },
    clearAsset(state) {
        state.asset = {
            name: "--",
            types: [],
            image: '',
            race: 'NONE'
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
