import {createLogger, createStore} from "vuex";
import base from "./modules/base.js";
import inventory from "./modules/inventories/inventory.js";
import market from "./modules/inventories/market.js";
import filters from "./modules/filters.js";
import wallet from "./modules/wallet.js";
import itemInventory from "./modules/inventories/itemInventory.js";
import game from "./modules/inventories/game.js";
import gameItemInventory from "./modules/inventories/gameItemInventory.js";
import dressing from "./modules/dressing.js";
import summon from "./modules/summon.js";

const debug = false//process.env.NODE_ENV !== 'production'

const store = createStore({
    modules: {base, inventory, market, filters, wallet, itemInventory, game, gameItemInventory, dressing, summon},
    strict: debug,
    plugins: debug ? [createLogger()] : []
})

export default store
