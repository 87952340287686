<template>
  <div class="timer-box">
    <vue-countdown :time="time" v-slot="{ days, hours, minutes, seconds }" @end="onCountdownEnd">
      <div class="-time">
        <div class="-value">{{ days }}</div>
        <div class="-label">days</div>
      </div>
      <div class="-time">
        <div class="-value">{{ hours }}</div>
        <div class="-label">hours</div>
      </div>
      <div class="-time">
        <div class="-value">{{ minutes }}</div>
        <div class="-label">minutes</div>
      </div>
      <div class="-time">
        <div class="-value">{{ seconds }}</div>
        <div class="-label">seconds</div>
      </div>
    </vue-countdown>
  </div>
</template>

<script>

import moment from "moment/moment";

export default {
    name: "UpdatePriceModal",
    data: () => {
    const now = new Date();
    const startCount = moment().add(10, 'minute')
    const newDate = new Date(startCount)
    const nowUtc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
    const newUtc = Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), newDate.getUTCHours(), newDate.getUTCMinutes(), newDate.getUTCSeconds());

    return {
      time: newUtc - nowUtc,
    }
  },
  methods: {
    sendEndEvent() {
      this.emitter.emit('updatePriceEndTime', true)
    },
    onCountdownEnd: function () {
      this.sendEndEvent()
    },
  }
}
</script>

<style lang="scss" scoped>

.timer-box {
    display: none;
    font-family: "PoetsenOne";
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);

    span {
        display: flex;
        justify-content: center;
        text-align: center;
        position: relative;

      .-time {
        width: 25%;
      }

      .-value {
        //font-family: "SofiaBold";
        font-size: 32px;
        line-height: 39px;
      }

      .-label {
        font-style: normal;
        text-transform: uppercase;
        font-size: 12px;
          line-height: 14px;
      }

    }
}
</style>
