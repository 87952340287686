<template>
    <div class="flex flex-wrap gap-2 pt-3 pb-1">
        <div :class="`filter-item-tag ${perk.tag} ${isSelectedFilter(perk) ? '-selected' : ''}`" v-for="(perk, i) in filterPerk" :key="i" v-on:click="selectFilter(perk)">
            <span>{{ perk.name }}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {filterPerk, FiltersType} from "../../../../entities/Filters";
import {useFilterStore} from "../../../../store/useFilterStore";
import {computed, watch} from "vue";

const props = defineProps({
    name: {
        type: String,
        required: true
    }
})

const emmit = defineEmits<{
    (e: 'is-selected', value: boolean )
}>();

const filterStore = useFilterStore(props.name)
const perkFilters = computed(() => filterStore.getPerkFilters)
const isSelectedFilter = (filter: FiltersType) => filterStore.getPerkFilters.find(item => item.filter === filter.filter) !== undefined
const selectFilter = (item: FiltersType) => filterStore.setPerkFilter(item)

watch(perkFilters,(check) => {
    emmit('is-selected', check.length > 0)
}, {deep: true})
</script>

<style lang="scss" scoped>
.filter-item-tag {
    height: 24px;
    padding: 0 12px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--mm-color-dark3);
    font-size: 12px;
    cursor: pointer;

    &:hover { background: var(--mm-color-secondary); }
    &.-selected { background: var(--mm-color-primary);}

    &:before {
        content: "";
        width: 14px;
        height: 14px;
        display: block;
        margin-right: 8px;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &.--adventurer {
        &:before {background-image: url("/images/icons/filters/perks/ic-adventurer.png");}
    }
    &.--armorsmith {
        &:before {background-image: url("/images/icons/filters/perks/ic-armorsmith.png");}
    }
    &.--gladiator {
        &:before {background-image: url("/images/icons/filters/perks/ic-gladiator.png");}
    }
    &.--navigator {
        &:before {background-image: url("/images/icons/filters/perks/ic-navigator.png");}
    }
    &.--weaponsmith {
        &:before {background-image: url("/images/icons/filters/perks/ic-weaponsmith.png");}
    }
    &.--alchemist {
        &:before {background-image: url("/images/icons/filters/perks/ic-alchemist.png");}
    }
    &.--caretaker {
        &:before {background-image: url("/images/icons/filters/perks/ic-caretaker.png");}
    }
    &.--monsterslayer {
        &:before {background-image: url("/images/icons/filters/perks/ic-monsterslayer.png");}
    }
    &.--tanner {
        &:before {background-image: url("/images/icons/filters/perks/ic-tanner.png");}
    }
    &.--all {
        &:before { display: none }
    }
}
</style>
