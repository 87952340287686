export const MARKET_CHECKBOXES = 'market_checkboxes'
export const MARKET_PERK_CHECKBOXES = 'market_perk_checkboxes'
export const MARKET_GROUP = 'market_group'
export const MARKET_PARTS = 'market_parts'
export const MARKET_SEARCH = 'market_search'
export const MARKET_PAGE = 'market_page'
export const MARKET_SALE = 'market_sale'
export const MARKET_SOLD = 'market_sort'
export const MARKET_LIMIT = 'market_limit'
export const MARKET_PER_PAGE = 'market_per_page'
export const MARKET_VIEW = 'market_view'
export const MARKET_SHOW_FILTERS = 'market_show_filters'
export const MARKET_RACE_FILTER = 'market_race_filter'
export const MARKET_RARITY_FILTER = 'market_rarity_filter'
export const MARKET_ITEMS_FILTER = 'market_items_filter'
export const MARKET_PERK_FILTER = 'market_perk_filter'
export const MARKET_ATTRIBUTE_FILTER = 'market_attribute_filter'

export const INVENTORY_CHECKBOXES = 'inventory_checkboxes'
export const INVENTORY_PERK_CHECKBOXES = 'inventory_perk_checkboxes'
export const INVENTORY_GROUP = 'inventory_group'
export const INVENTORY_PARTS = 'inventory_parts'
export const INVENTORY_SEARCH = 'inventory_search'
export const INVENTORY_PAGE = 'inventory_page'
export const INVENTORY_SALE = 'inventory_sale'
export const INVENTORY_SOLD = 'inventory_sort'
export const INVENTORY_LIMIT = 'inventory_limit'
export const INVENTORY_SHOW_FILTERS = 'inventory_show_filters'
export const INVENTORY_PER_PAGE = 'inventory_per_page'
export const INVENTORY_VIEW = 'inventory_view'
export const INVENTORY_RACE_FILTER = 'inventory_race_filter'
export const INVENTORY_RARITY_FILTER = 'inventory_rarity_filter'
export const INVENTORY_ITEMS_FILTER = 'inventory_items_filter'
export const INVENTORY_PERK_FILTER = 'inventory_perk_filter'
export const INVENTORY_ATTRIBUTE_FILTER = 'inventory_attribute_filter'


export const ITEM_INVENTORY_PAGE = 'item_inventory_page'
export const ITEM_INVENTORY_PER_PAGE = 'item_inventory_per_page'
export const ITEM_INVENTORY_SHOW_FILTERS = 'item_inventory_show_filters'
export const ITEM_INVENTORY_FILTER = 'item_inventory_filter'
export const ITEM_INVENTORY_RACE_FILTER = 'item_inventory_race_filter'
export const ITEM_INVENTORY_RARITY_FILTER = 'item_inventory_rarity_filter'
export const ITEM_INVENTORY_ITEMS_FILTER = 'item_inventory_items_filter'

export const GAME_ITEM_INVENTORY_PAGE = 'game_item_inventory_page'
export const GAME_ITEM_INVENTORY_PER_PAGE = 'game_item_inventory_per_page'
export const GAME_ITEM_INVENTORY_SHOW_FILTERS = 'game_item_inventory_show_filters'
export const GAME_ITEM_INVENTORY_FILTER = 'game_item_inventory_filter'
export const GAME_ITEM_INVENTORY_RACE_FILTER = 'game_item_inventory_race_filter'
export const GAME_ITEM_INVENTORY_RARITY_FILTER = 'game_item_inventory_rarity_filter'
export const GAME_ITEM_INVENTORY_ITEMS_FILTER = 'game_item_inventory_items_filter'

export const GAME_INVENTORY_CHECKBOXES = 'game_inventory_checkboxes'
export const GAME_INVENTORY_PERK_CHECKBOXES = 'game_inventory_perk_checkboxes'
export const GAME_INVENTORY_GROUP = 'game_inventory_group'
export const GAME_INVENTORY_PARTS = 'game_inventory_parts'
export const GAME_INVENTORY_SEARCH = 'game_inventory_search'
export const GAME_INVENTORY_PAGE = 'game_inventory_page'
export const GAME_INVENTORY_SALE = 'game_inventory_sale'
export const GAME_INVENTORY_SOLD = 'game_inventory_sort'
export const GAME_INVENTORY_LIMIT = 'game_inventory_limit'
export const GAME_INVENTORY_SHOW_FILTERS = 'game_inventory_show_filters'
export const GAME_INVENTORY_PER_PAGE = 'game_inventory_per_page'
export const GAME_INVENTORY_VIEW = 'game_inventory_view'
export const GAME_INVENTORY_RACE_FILTER = 'game_inventory_race_filter'
export const GAME_INVENTORY_RARITY_FILTER = 'game_inventory_rarity_filter'
export const GAME_INVENTORY_ITEMS_FILTER = 'game_inventory_items_filter'
export const GAME_INVENTORY_PERK_FILTER = 'game_inventory_perk_filter'
export const GAME_INVENTORY_ATTRIBUTE_FILTER = 'game_inventory_attribute_filter'
