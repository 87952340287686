<template>
    <div class="summon-shards-list" :style="cssVars">
        <div class="summon-item" v-for="i in 5" :key="i" :class="summonCount >= i ? '-active' : ''"></div>
    </div>
</template>

<script>
export default {
    name: "uSummonShards",
    props: {
        eventEnable: {
            type: Boolean,
            default: () => false
        },
        summonCount: {
            type: Number,
            required: true,
            default: () => 0,
        },
        style: {
            type: Object,
            required: true,
            default: () => {},
        }
    },
    computed: {
        cssVars() {
            return {
                '--size': this.style.size + 'px',
                '--margin': this.style.margin + 'px',
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.summon-shards-list {
    display: flex;
    align-items: center;

    .summon-item {
        height: var(--size);
        width: var(--size);
        margin-right: var(--margin);

        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url("/images/chibidango/summon/summon-energy-none.png");

        &.-active {
            background-image: url("/images/chibidango/summon/summon-energy-active.png");
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
</style>
