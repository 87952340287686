<template>
<div class="p-6">
    <slot/>
</div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>