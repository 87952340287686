//@ts-ignore
import {Address, AssetExtended, BrowserWallet, Transaction, UTxO} from "@meshsdk/core";
import {
    COIN_PER_BYTE,
    ERROR_ACCOUNT_BALANCE,
    ERROR_CANCEL_CONNECTION,
    ERROR_CHANGE_WALLET,
    ERROR_NETWORK_TEST, ERROR_WALLET_SELECT_API,
    MIN_ADA_AMOUNT_TO_COVER_MINT,
    NOT_ENOUGH_BALANCE,
    type OutputAmount,
    type SelectedUTxO,
    type SendUnit,
    UNHANDLED_ERROR,
    UNHANDLED_WALLET_ERROR,
    USER_DECLINE_TRANSACTION,
    type Utxo,
    type Wallet, WalletBalances
} from "@/entities/Wallet";

import * as csl from "@emurgo/cardano-serialization-lib-browser";
import {MultiAsset} from "@emurgo/cardano-serialization-lib-browser";
import {Buffer} from "buffer";
import {useWalletStore} from "@/store/useWalletStore";
import {createPinia, setActivePinia} from "pinia";
const pinia = createPinia();

setActivePinia(pinia);

const walletStore = useWalletStore()

const walletMap:any = {
    'Typhon Wallet': 'typhon',
    'eternl': 'eternl',
    'Flint Wallet': 'flint',
    'GeroWallet': 'gerowallet',
    'Nami': 'nami',
    'VESPR': 'VESPR',
    'lace': 'lace',
}

const wallets: Wallet[] = [
    {
        type: "eternl",
        label: "Eternl",
        allowed: true,
        installed: false,
        isHD: false,
        downloadLink: "https://eternl.io/app/mainnet/welcome",
        apiName: "",
        source: "browser"
    },
    {
        type: "nami",
        label: "Nami",
        allowed: true,
        installed: false,
        isHD: false,
        downloadLink: "https://namiwallet.io/",
        apiName: "",
        source: "browser"
    },
    {
        type: "gerowallet",
        label: "Gero",
        allowed: true,
        installed: false,
        isHD: false,
        downloadLink: "https://www.gerowallet.io/",
        apiName: "",
        source: "browser"
    },
    {
        type: "typhon",
        label: "Typhon",
        allowed: true,
        installed: false,
        isHD: false,
        downloadLink: "https://typhonwallet.io/#/",
        apiName: "",
        source: "browser"
    },
    {
        type: "lace",
        label: "Lace",
        allowed: true,
        installed: false,
        isHD: false,
        downloadLink: "https://www.lace.io/",
        apiName: "",
        source: "browser"
    },
    {
        type: "flint",
        label: "Flint",
        allowed: true,
        installed: false,
        isHD: false,
        downloadLink: "https://flint-wallet.com/",
        apiName: "",
        source: "browser"
    },
    {
        type: "VESPR",
        label: "Vespr",
        allowed: true,
        installed: false,
        isHD: false,
        downloadLink: "https://vespr.xyz/",
        apiName: "",
        source: "mobile"
    }
]

export async function signTx(walletApiName: string, unsignedTx: string, isPartialSig: boolean = true): Promise<object | string> {
    const api = await enableWallet(walletApiName)

    if (typeof api === "string") return api

    try {
        return {
            data: await api.signTx(unsignedTx, isPartialSig)
        }
    } catch (e: any) {
        if (e.message && e.message.indexOf('[BrowserWallet] An error occurred during signTx') !== -1) {
            return 'USER_WALLET_CANCEL_TRANSACTION'
        } else if (e.message) {
            return e.message
        }

        console.log(e)

        return UNHANDLED_WALLET_ERROR
    }
}

export function prepareWallets(): Wallet[] {
    BrowserWallet.getInstalledWallets().forEach(item => {
        wallets.forEach(function (wallet) {
            if (wallet.type === walletMap[item.name]) {
                wallet.installed = true
                wallet.apiName = item.name
            }
        })
    })

    return wallets
}

export async function getUtxosAddress(walletApiName: string): Promise<string | { address: string, utxos: UTxO[], collateral: UTxO[]}> {
    const api = await enableWallet(walletApiName)

    if (typeof api === "string") return api
    if (api.error) return api

    let addresses = await api.getUsedAddresses()
    if (addresses.length === 0) {
        addresses = await api.getUnusedAddresses()
    }

    if (addresses.length === 0) {
        return 'CANT_GET_ADDRESS_FROM_WALLET'
    }

    let collateral: UTxO[] = await api.getCollateral();

    return {address: addresses[0], utxos: await api.getUtxos(), collateral}
}

export async function getUnitsByPolicy(walletApiName: string, policies: Array<string>): Promise<Array<string>|string> {
    const api = await enableWallet(walletApiName)

    if (typeof api === "string") return api

    let filteredAssets: string[] = []

    for (const policy of policies) {
        const assets: AssetExtended[] = await api.getPolicyIdAssets(policy)

        filteredAssets = [...filteredAssets, ...assets.map(asset => asset.unit)]
    }

    return filteredAssets
}

export async function getWalletBalance(walletApiName: string): Promise<string|WalletBalances>
{
    const api = await enableWallet(walletApiName)

    if (typeof api === "string") return api

    const adaBalance: string = await api.getLovelace()
    const assets: AssetExtended[] = await api.getPolicyIdAssets('539aeefaf917fe42b65018bf76fa7933c1737f1af92d8ca192ed38c5')
    const dgemAsset:AssetExtended|undefined = assets.find(item => item.unit === "539aeefaf917fe42b65018bf76fa7933c1737f1af92d8ca192ed38c54447454d53")

    let dgemBalance: string = '0'

    if (dgemAsset) {
        dgemBalance = dgemAsset.quantity
    }

    return {adaBalance, dgemBalance}
}

export async function getWalletAssets(walletApiName: string): Promise<AssetExtended[]|string>
{
    const api = await enableWallet(walletApiName)

    if (typeof api === "string") return api

    return await api.getAssets()
}

export async function connectWallet(walletApiName: string): Promise<string | string[]> {
    const api = await enableWallet(walletApiName)

    if (typeof api === "string") return api

    let address = await api.getUsedAddresses()
    if (address.length === 0) {
        address = await api.getUnusedAddresses()
    }

    return address
}

async function enableWallet(walletApiName: string): Promise<BrowserWallet | string> {
    if (walletApiName === '') {
        return ERROR_WALLET_SELECT_API
    }

    try {
        const api = await BrowserWallet.enable(walletApiName);
        const networkId = await api.getNetworkId();

        if (networkId !== 1) {
            return ERROR_NETWORK_TEST
        }

        return api
    } catch (e) {
        console.log(e)

        return ERROR_CANCEL_CONNECTION
    }
}

export async function buildTx(sendAddress: string, sendUnits: Array<SendUnit>, metadata:object|null = null):Promise<string | { txHash:string }>{
    const walletType = walletStore.getWalletType

    const address = csl.Address.from_bech32(sendAddress)
    const api = await enableWallet(walletStore.walletApiName)

    if (typeof api === "string") return api

    const userStoreWallet = walletStore.getWallet
    let usedAddresses:string[] = await api.getUsedAddresses();

    if (usedAddresses.length === 0) {
        usedAddresses = await api.getUnusedAddresses();
    }

    if (userStoreWallet !== usedAddresses[0]) return ERROR_CHANGE_WALLET

    const UTxO = await api.getUtxos()

    if (!UTxO.length) return ERROR_ACCOUNT_BALANCE

    const outputUTxO = {
        output: {
            amount: sendUnits
        }
    }

    let collateral: UTxO[] = await api.getCollateral();

    const multiAsset = csl.MultiAsset.new()
    const inputUtxos = selectInputUtxos(UTxO, sendUnits, multiAsset, address, 2000000, collateral);

    if (typeof inputUtxos === "string") {
        return inputUtxos
    }

    try {
        let tx = createTx(api, inputUtxos, outputUTxO as Utxo, sendAddress, inputUtxos[0].output.address, metadata)

        if (typeof tx === "string") return tx
        // @ts-ignore
        if (window?.cardano[walletType]?.isBridge) tx = addTxFeeOutputs(tx, sendUnits, walletType)

        const unsignedTx = await tx.build()
        const signedTx = await api.signTx(unsignedTx)
        const txHash = await api.submitTx(signedTx)

        return {txHash}
    } catch (e: any) {
        if (e.message && e.message.indexOf('[BrowserWallet] An error occurred during signTx') !== -1) {
            return USER_DECLINE_TRANSACTION
        }

        console.log(e)

        return UNHANDLED_WALLET_ERROR
    }
}

export function addTxFeeOutputs(transaction: Transaction, sendUnits: Array<SendUnit>, walletType: string): Transaction {
    let sendTotalLovelace = 0

    sendUnits.forEach(item => {
        if (item.unit === 'lovelace') {
            sendTotalLovelace = sendTotalLovelace + parseInt(item.quantity)
        }
    })

    sendTotalLovelace = sendTotalLovelace / 1000000

    if (sendTotalLovelace < 100) return transaction

    // @ts-ignore
    const feeAddress = window?.cardano[walletType]?.experimental?.feeAddress

    if (!feeAddress) return transaction

    let fee: number
    fee = Math.floor(sendTotalLovelace * 0.01 * 10000) / 10000

    if (fee < 1) fee = 1
    fee = fee * 1000000

    transaction = transaction.sendValue(feeAddress, {
        output: {
            amount: [
                {
                    unit: 'lovelace',
                    quantity: (fee).toString()
                }
            ]
        }
    } as Utxo)

    return transaction
}

export function selectInputUtxos(
    utxos: Utxo[],
    sendUnits: Array<SendUnit>,
    multiAsset: MultiAsset,
    address: Address,
    minAdaPayment: number = MIN_ADA_AMOUNT_TO_COVER_MINT,
    collateral: UTxO[] = [],
): Utxo[]|string {
    try {
        const result: Array<SelectedUTxO> = []
        let adaInUtxos = 0
        let minAdaForOutput = 0
        let prepareUtxos: Utxo[] = []

        sendUnits.forEach(item => {
            const sortedByUnit = sortByUnit(utxos, item.unit)
            const tokenResult = getTokenResult(sortedByUnit, parseInt(item.quantity), item.unit)

            if (tokenResult.total < parseInt(item.quantity)) {
                throw {code: 'NOT_ENOUGH_BALANCE', token: 'bb'}
            }

            tokenResult.utxos.forEach(utxo => {
                if (!prepareUtxos.find(it => utxo.input.txHash === it.input.txHash && utxo.input.outputIndex === it.input.outputIndex)) {
                    prepareUtxos.push(utxo)

                    updateAssets([utxo], multiAsset)

                    adaInUtxos = adaInUtxos + calculateTotalAda([utxo])
                }
            })

            if (item.unit === 'lovelace') {
                minAdaForOutput = minAdaForOutput + parseInt(item.quantity)
            }

            minAdaForOutput = minAdaForOutput + getMinAdaForAssets(multiAsset, address)
        })

        const totalAdaRequired = minAdaForOutput + minAdaPayment

        if (adaInUtxos >= totalAdaRequired) {
            return prepareUtxos
        }

        const sortedByAdaUnique = filterSelectedUtxos(utxos, result).sort(
            (a, b) => {
                const aAda = findByUnit(a, 'lovelace')
                const bAda = findByUnit(b, 'lovelace')

                return Number(bAda?.quantity ?? 0) - Number(aAda?.quantity ?? 0)
            }
        )

        const remainingUtxos = collectRemainingUtxos(sortedByAdaUnique, totalAdaRequired, adaInUtxos, multiAsset, minAdaPayment, address, prepareUtxos, collateral)

        return [...prepareUtxos, ...remainingUtxos]
    } catch (e: any) {
        console.log(e)

        if (e.code) {
            return e.code
        }

        return UNHANDLED_ERROR
    }
}

function collectRemainingUtxos(
    remainingUtxos: Utxo[],
    updTotalAdaRequired: number,
    collectedAda: number,
    multiAsset: MultiAsset,
    minAdaPayment: number,
    address: Address,
    selectedUtxos: Utxo[],
    collateral: Utxo[] = []
): Utxo[] {
    if (remainingUtxos.length === 0 && collateral.length > 0) {
        remainingUtxos = collateral
    }

    if (remainingUtxos.length === 0) {
        if (collectedAda <= updTotalAdaRequired) {
            throw {code: 'NOT_ENOUGH_BALANCE', token: 'lovelace'}
        }

        return []
    }

    const [firstUtxo, ...restUtxos] = remainingUtxos

    let isFind = false
    selectedUtxos.forEach(item => {
        if (item.input.txHash === firstUtxo.input.txHash && item.input.outputIndex === firstUtxo.input.outputIndex) {
            isFind = true
        }
    })

    if (isFind) {
        return collectRemainingUtxos(
            restUtxos,
            updTotalAdaRequired,
            collectedAda,
            multiAsset,
            minAdaPayment,
            address,
            selectedUtxos,
            collateral
        )
    }

    const updAdaCollected = collectedAda + Number(findByUnit(firstUtxo, 'lovelace')?.quantity) ?? 0

    if (firstUtxo.output.amount.length === 1) {
        if (updAdaCollected >= updTotalAdaRequired) {
            return [firstUtxo]
        }

        return [
            firstUtxo,
            ...collectRemainingUtxos(
                restUtxos,
                updTotalAdaRequired,
                updAdaCollected,
                multiAsset,
                minAdaPayment,
                address,
                selectedUtxos,
                collateral
            )
        ]
    }

    updateAssets([firstUtxo], multiAsset)

    const value = csl.BigNum.from_str(minAdaPayment.toString())
    const txOutput = csl.TransactionOutput.new(address, csl.Value.new_with_assets(value, multiAsset))
    const minAdaForOutput = Number(
        csl.min_ada_for_output(txOutput, csl.DataCost.new_coins_per_byte(csl.BigNum.from_str(COIN_PER_BYTE))).to_str()
    )

    const totalAdaRequired = minAdaForOutput + updTotalAdaRequired

    if (updAdaCollected >= updTotalAdaRequired) {
        return [firstUtxo]
    }

    return [
        firstUtxo,
        ...collectRemainingUtxos(
            restUtxos,
            totalAdaRequired,
            updAdaCollected,
            multiAsset,
            minAdaPayment,
            address,
            selectedUtxos,
            collateral
        )
    ]
}

function filterSelectedUtxos(utxos: Utxo[], selectedUtxos: Array<SelectedUTxO>): Utxo[] {
    let result = [...selectedUtxos]
    utxos.forEach(item => {
        let isFind = false
        result.forEach(utxo => {
            if (item.input.txHash === utxo.input.txHash && item.input.outputIndex === utxo.input.outputIndex) {
                // console.log('is find', utxo)
                isFind = true
            }
        })

        if (!isFind) {
            result.push(item)
        }
    })
    return result
}

function sortByUnit(utxos: Utxo[], unit: string): Utxo[] {
    return utxos.sort((a, b) => {
        const aAmount = a.output.amount.find((amount: OutputAmount) => amount.unit === unit)
        const bAmount = b.output.amount.find((amount: OutputAmount) => amount.unit === unit)

        return Number(bAmount?.quantity ?? 0) - Number(aAmount?.quantity ?? 0)
    })
}

function getTokenResult(sortedByToken: Utxo[], quantity: number, unit: string): SelectedUTxO {
    const initValue = {
        total: 0,
        utxos: [] as Utxo[],
        unit: unit
    }

    return sortedByToken.reduce((selectedUtxo, utxo) => {
        const total = selectedUtxo.total + Number(findByUnit(utxo, unit)?.quantity ?? 0)

        if (selectedUtxo.total < quantity) {
            return {
                total: total,
                utxos: [...selectedUtxo.utxos, utxo],
                unit: unit
            }
        }

        return selectedUtxo
    }, initValue)
}

function findByUnit(firstUtxo: Utxo, unit: string): OutputAmount | undefined {
    return firstUtxo.output.amount.find((utxo: OutputAmount) => utxo.unit === unit)
}

function calculateTotalAda(selectedUtxos: Utxo[]): number {
    return selectedUtxos.reduce((acc, utxo) => {
        const amount = findByUnit(utxo, 'lovelace')
        return acc + Number(amount?.quantity ?? 0)
    }, 0)
}

function updateAssets(utxos: Utxo[], multiAsset: MultiAsset): void {
    for (const utxoItem of utxos) {
        for (const amount of utxoItem.output.amount) {
            if (amount.unit !== 'lovelace') {
                const assetName = csl.AssetName.new(
                    Buffer.from(amount.unit.substring(56), 'hex')
                )
                const policyName = csl.ScriptHash.from_bytes(
                    Buffer.from(amount.unit.substring(0, 56), 'hex')
                )

                const assetIfExist = multiAsset.get_asset(policyName, assetName)
                if (assetIfExist) {
                    multiAsset.set_asset(
                        policyName,
                        assetName,
                        assetIfExist.checked_add(csl.BigNum.from_str(amount.quantity))
                    )
                } else {
                    multiAsset.set_asset(
                        policyName,
                        assetName,
                        csl.BigNum.from_str(amount.quantity)
                    )
                }
            }
        }
    }
}

function getMinAdaForAssets(multiAsset: MultiAsset, address: Address, value: number = MIN_ADA_AMOUNT_TO_COVER_MINT): number {
    const txOutput = csl.TransactionOutput.new(
        address,
        csl.Value.new_with_assets(csl.BigNum.from_str(value.toString()), multiAsset)
    )

    const number = csl.min_ada_for_output(
        txOutput,
        csl.DataCost.new_coins_per_byte(csl.BigNum.from_str(COIN_PER_BYTE))
    )

    return Number(number.to_str())
}

export function createTx(
    wallet: BrowserWallet,
    inputUtxos: Utxo[],
    createdUtxo: Utxo,
    recipientAddress: Address,
    changeAddress: string,
    metadata: object|null = null
): string|Transaction {
    let transaction = new Transaction({ initiator: wallet })

    transaction = transaction.setTxInputs(inputUtxos)

    const selectedLovelace = createdUtxo.output.amount.filter((amount) => amount.unit === 'lovelace')

    let notEnough = false
    selectedLovelace.forEach(item => {
        if (item?.quantity === '0') {
            notEnough = true
        }

        transaction = transaction.sendValue(recipientAddress, createdUtxo)
    })

    if (notEnough) {
        return NOT_ENOUGH_BALANCE
    }

    console.log(createdUtxo)


    transaction = transaction.setChangeAddress(changeAddress)

    if (metadata !== null) {
        transaction = transaction.setMetadata(674, metadata)
    }

    return transaction
    // const ctx = {
    //     build: () => from(transaction.build()),
    //     withInputs: (inputUtxos: Utxo[]) => {
    //         transaction.setTxInputs(inputUtxos)
    //         return ctx
    //     },
    //     withValue: (createdUtxo: Utxo) => {
    //         let selectedLovelace = createdUtxo.output.amount.find((amount) => amount.unit === 'lovelace')
    //
    //         if (selectedLovelace?.quantity === '0') {
    //             throw {code: NOT_ENOUGH_BALANCE, token: 'lovelace'}
    //         }
    //
    //         return {
    //             withRecipient: (recipientAddress: string) => {
    //                 transaction.sendValue(recipientAddress, createdUtxo)
    //                 return ctx
    //             }
    //         }
    //     },
    //     withChangeAddress: (changeAddress: string) => {
    //         transaction.setChangeAddress(changeAddress)
    //         return ctx
    //     },
    //     withMetadata: <T>(metadata: T) => {
    //         transaction.setMetadata(674, metadata)
    //         return ctx
    //     }
    // }
    //
    // return ctx
}

